import React from 'react';
import BaseSolarReport from '../../../components/solar-report/solar-report';

export default function SolarReport(): JSX.Element {
  return (
    <BaseSolarReport
      titleElement={(
        <h1>
          Achieve Energy Independence With Solar
        </h1>
      )}
      subtitleElement={null}
      content={(
        <h3>
          Fill out our quick questionnaire, it takes just 2 minutes, and get a free, fully
          customized solar report.
        </h3>
      )}
    />
  );
}
