import React from 'react';
import './header.scss';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import LogoImage from '../../images/logo_2_1.svg';
import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';

export interface Props {
  simple?: boolean;
}

function Header({ simple }: Props): JSX.Element {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate();
  const { dispatch } = useStoreon('solarData');
  const params = new URLSearchParams(window.location.search).toString();
  const location = useLocation();
  const hide = () => !['/get-started', '/get-started/', '/analysis', '/analysis/'].includes(location.pathname);
  const needConfirmation = () => !['/get-started', '/get-started/'].includes(location.pathname);
  const host = window.location.hostname;

  const handleClick = (navigateTo: string) => {
    navigate({ pathname: navigateTo, search: params });
  };

  return (
    <div className="header">
      {isMobile ? (
        <div className="mobile-header-container">
          <div className="logo-container">
            <div className="logo">
              <img className="img" src={LogoImage} alt="solar otter logo" />
            </div>
            <HamburgerMenu>
              <div className="mobile-menu">
                { needConfirmation()
                  ? (
                    <>
                      <Link to={{ pathname: '/', search: params }}> Home </Link>

                      {!simple && (
                        <>
                          <Link to={{ pathname: '/tools', search: params }}> Tools </Link>
                          <a href={`https://${host}/blog`}> Resources </a>
                        </>
                      )}
                    </>
                  )
                  : (
                    <>
                      <button
                        className="btn-link"
                        type="button"
                        onClick={() => ConfirmationModal(handleClick, '/')}
                      >
                        Home
                      </button>

                      {!simple && (
                        <>
                          <button
                            className="btn-link"
                            type="button"
                            onClick={() => ConfirmationModal(handleClick, '/tools')}
                          >
                            Tools
                          </button>
                          <button
                            className="btn-link"
                            type="button"
                            onClick={() => ConfirmationModal(handleClick, `https://${host}/blog`)}
                          >
                            Resources
                          </button>
                        </>
                      )}
                    </>
                  )}
              </div>
            </HamburgerMenu>
            {hide() && (
            <div className="menu-container">
              <button
                type="button"
                className="btn-primary"
                onClick={() => {
                  dispatch('clearSolarData');
                  navigate({ pathname: '/get-started', search: params });
                }}
              >
                Get Started
              </button>
            </div>
            )}
          </div>
        </div>
      ) : (
        <div className="header-container">
          <div className="logo">
            <img className="img" src={LogoImage} alt="solar otter logo" />
          </div>
          <div className="menu">
            { needConfirmation()
              ? (
                <>
                  <Link to={{ pathname: '/', search: params }}> Home </Link>

                  {!simple && (
                    <>
                      <Link to={{ pathname: '/tools', search: params }}> Tools </Link>
                      <a href={`https://${host}/blog`}> Resources </a>
                    </>
                  )}
                </>
              )
              : (
                <>
                  <button
                    className="btn-link"
                    type="button"
                    onClick={() => ConfirmationModal(handleClick, '/')}
                  >
                    Home
                  </button>

                  {!simple && (
                    <>
                      <button
                        className="btn-link"
                        type="button"
                        onClick={() => ConfirmationModal(handleClick, '/tools')}
                      >
                        Tools
                      </button>
                      <button
                        className="btn-link"
                        type="button"
                        onClick={() => ConfirmationModal(handleClick, `https://${host}/blog`)}
                      >
                        Resources
                      </button>
                    </>
                  )}
                </>
              )}
            {hide() && (
            <button
              type="button"
              className="btn-primary"
              onClick={() => {
                dispatch('clearSolarData');
                navigate({ pathname: '/get-started', search: params });
              }}
            >
              Get Started
            </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
