import * as Yup from 'yup';
import { roofingKindTypes, roofingProjectTypes } from '../pages/get-started-page/get-started-page.constants';

export const roofFormValidationSchemas = [
  Yup.object().shape({
    zipCode: Yup.number()
      .typeError('Zip code must be a number')
      .required('Zip code is required'),
  }),
  Yup.object().shape({
    own_rent: Yup.string().required('Please select an option'),
  }),
  Yup.object().shape({
    projectType: Yup.string()
      .required('Please select a project type')
      .notOneOf([roofingProjectTypes[0]], 'Please select a valid project type'),
  }),
  Yup.object().shape({
    roofingType: Yup.string()
      .required('Please select a roofing type')
      .notOneOf([roofingKindTypes[0]], 'Please select a valid roofing type'),
  }),
  Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'First name must be at least 2 characters')
      .required('First name is required'),
    lastName: Yup.string()
      .min(2, 'Last name must be at least 2 characters')
      .required('Last name is required'),
  }),
  Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  }),
  // Validation stop here
  // Other steps require direct validation
];
