import axios from 'axios';
import { GeneralResponseModel } from '../model/ResponseModel';

const API_HOST = process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:3333/api` : `https://${window.location.hostname}/api`;

export const getEligibleZipcodes = async (): Promise<GeneralResponseModel<string[]>> => {
  try {
    const response = await axios.get<GeneralResponseModel<string[]>>(`${API_HOST}/eligible/zip-code`, {
      headers: {
        'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
        Accept: 'application/json',
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    console.error(`Unexpected status code: ${response.status}`);
    throw response.status;
  } catch (error) {
    console.error('Error fetching eligible zip codes:', error);
    throw error;
  }
};

export const updateEligibleZipcode = async (arr: string[]):
Promise<GeneralResponseModel<string[]>> => {
  const body = arr;
  try {
    const response = await axios.put<GeneralResponseModel<string[]>>(
      `${API_HOST}/eligible/zip-code`,
      body,
      {
        headers: {
          'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
          Accept: 'application/json',
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    }
    console.error(`Unexpected status code: ${response.status}`);
    throw response.status;
  } catch (error) {
    console.error('Error fetching eligible zip codes:', error);
    throw error;
  }
};
