import React from 'react';
import { useNavigate } from 'react-router-dom';
import './check-your-roof-shade.scss';

interface TextProps {
  title?: string;
  zipCodePlaceholder?: string;
  buttonLabel?: string;
}
function CheckYourRoofShade({ title, buttonLabel, zipCodePlaceholder = '' }: TextProps): JSX.Element {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();

  return (
    <div className="check-your-roof-shade">
      <div className="container-elem">
        <h1>
          {title ?? 'Enter your Zip Code to check your solar report'}
        </h1>
        <div className="search">
          <input placeholder={zipCodePlaceholder || 'Enter a U.S. Zip Code'} />
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              navigate({ pathname: '/get-started', search: params });
            }}
          >
            {buttonLabel ?? 'Check my roof'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckYourRoofShade;
