export const ZIP_CODE_TOP_OFFER_ROOFING = {
  zip_code: [94040],
  price: 90.00,
};
export const ZIP_CODE_TOP_OFFER_BATH_REMODEL = {
  zip_code: [94040],
  price: 70.00,
};
export const ZIP_CODE_TOP_OFFER_SOLAR = {
  zip_code: [94040],
  price: 45.00,
};
export const ZIP_CODE_TOP_OFFER_WINDOW = {
  zip_code: [94040],
  price: 30.00,
};
export const ZIP_CODE_TOP_OFFER_GUTTERS = {
  zip_code: [94040],
  price: 30.00,
};
export const ZIP_CODE_TOP_OFFER_HOUSECLEANING = {
  zip_code: [94040],
  price: 21.00,
};
export const ZIP_CODE_TOP_OFFER_HANDYMAN = {
  zip_code: [94040],
  price: 12.00,
};
