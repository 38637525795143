import React from 'react';
import { classes } from '../../utils/styles';
import './index.scss';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  tagName: 'h1' | 'h2' | 'h3';
}

function Heading({ className, tagName: Tag, ...htmlProps }: Props): JSX.Element {
  return (
    <Tag className={classes('bid-header', className)} {...htmlProps} />
  );
}

type HeadingProps = Omit<Props, 'tagName'>;

export function H1(props: HeadingProps): JSX.Element {
  return <Heading tagName="h1" {...props} />;
}

export function H2(props: HeadingProps): JSX.Element {
  return <Heading tagName="h2" {...props} />;
}

export function H3(props: HeadingProps): JSX.Element {
  return <Heading tagName="h3" {...props} />;
}
