import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import HouseWithManImage from '../../images/img1.jpg';
import OtterImage from '../../images/otter charectors - 02-05.png';
import { classes } from '../../utils/styles';
import FrequentQuestions from '../frequent-questions/frequent-questions';
import './why-report.scss';

interface TextProps {
  whySouldYouGet?: string;
  questions?: boolean;
  children?: React.ReactNode;
  compact?: boolean;
}

function WhyReportComponent({
  children,
  whySouldYouGet = '',
  questions = false,
  compact = true,
}: TextProps): JSX.Element {
  const navigate = useNavigate();
  const { dispatch } = useStoreon('solarData');
  const params = new URLSearchParams(window.location.search).toString();

  return (
    <div className="why-report">
      <div className="why-report-container">
        <div className={classes('info', compact && 'compact')}>
          <h1>
            WHY SHOULD YOU GET A SOLAR REPORT?
          </h1>

          {children ?? (
            <>
              {questions && <FrequentQuestions />}

              <p>
                {whySouldYouGet || `Every house is different. It is in your best interest to use our resources
                  to figure out what makes the most sense for your individual needs.`}
              </p>

              <ul>
                <li>
                  Understand incentives and how to take advantage of them.
                </li>

                <li>
                  View estimates based on your roof shade, location, and more.
                </li>
              </ul>
            </>
          )}

          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              dispatch('clearSolarData');
              navigate({ pathname: '/get-started', search: params });
            }}
          >
            Get Started
          </button>

          <div className="otter-img-2">
            <img className="otter-icon-2" src={OtterImage} alt="otter solar" />
          </div>
        </div>

        <div className="image-container">
          <img className="image" src={HouseWithManImage} alt="house with solar panel" />
          <div className="footer" />
        </div>
      </div>
    </div>
  );
}

export default WhyReportComponent;
