import React from 'react';
import Colorize, { Color } from '../../../components/color';
import { H1, H3 } from '../../../components/header-tag';
import {
  BathroomTypeHeatedFloorsImage,
  BathroomTypeJacuzziTubImage,
  BathroomTypeMarbleCountertopsImage,
  BathroomTypeRainfallShowerImage, BathroomTypeToiletRoomImage,
} from '../images';
import './bathroom-types.scss';

interface CardProps {
  title: React.ReactNode;
  image: React.ReactNode;
}

function Card({ title, image }: CardProps): React.ReactElement {
  return (
    <div className="bathroom-type-card">
      <H3>
        <Colorize color={Color.Secondary}>{title}</Colorize>
      </H3>

      <div className="image">
        {image}
      </div>
    </div>
  );
}

export default function BathroomTypes(): React.ReactElement {
  return (
    <div id="types-of-bathrooms">
      <H1>
        TYPES OF
        <Colorize color={Color.Secondary}> BATHROOM UPGRADES</Colorize>
      </H1>

      <div className="types-container">
        <Card title="JACUZZI TUB" image={<BathroomTypeJacuzziTubImage />} />

        <Card title="RAINFALL SHOWER" image={<BathroomTypeRainfallShowerImage />} />

        <Card title="MARBLE COUNTERTOPS" image={<BathroomTypeMarbleCountertopsImage />} />

      </div>

      <div className="types-container">
        <Card title="TOILET ROOM" image={<BathroomTypeToiletRoomImage />} />

        <Card title="HEATED FLOORS" image={<BathroomTypeHeatedFloorsImage />} />
      </div>
    </div>
  );
}
