import React from 'react';
import './index.scss';

import { getEligibleZipcodes, updateEligibleZipcode } from '../../api/zipcode-api';
import { arrayToString, stringToArray } from '../../utils/arrayHelper';

function EligibleZipcode() {
  const [zipcodes, setZipcodes] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);

  async function getEligibleZipcode() {
    const response = await getEligibleZipcodes();
    if (response.result) {
      const toString = arrayToString(response.result);
      setZipcodes(toString);
    }
  }

  React.useEffect(() => {
    getEligibleZipcode();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setZipcodes(e.target.value);
    setError(null);
    setSuccess(null);
  };

  const validateZipcodes = (value: string): boolean => {
    const zipCodeArray = stringToArray(value);
    const invalidZip = zipCodeArray.find(zip => !/^\d{5}$/.test(zip));

    if (invalidZip) {
      setError(`Invalid zip code: ${invalidZip}`);
      return false;
    }
    setError(null);
    setSuccess('Successfully updated');
    return true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateZipcodes(zipcodes)) {
      const arr = stringToArray(zipcodes);
      updateEligibleZipcode(arr);
    }
  };

  return (
    <div className="eligible-zipcode">
      <form onSubmit={handleSubmit}>
        <label htmlFor="zipcodes">Eligible Zipcodes (separated by commas):</label>
        <br />
        <textarea
          id="zipcodes"
          value={zipcodes}
          onChange={handleInputChange}
          rows={10}
          cols={100}
          placeholder="Enter zip codes here..."
        />
        <br />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default EligibleZipcode;
