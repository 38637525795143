import React from 'react';
import GoogleMapReact from 'google-map-react';
import NumberFormat from 'react-number-format';
import AddressDetail from '../address-detail/address-detail';
import NatureImage from '../../images/nature.png';
import SolarEnergyImage from '../../images/solar-energy.png';
import WeatherImage from '../../images/weather.png';
import './map.scss';

export interface MapHeaderProps {
    hours: any;
    sq: any;
    savings: any;
    latitude: number;
    longitude: number;
}

type Coord = {
  x: number;
  y: number;
};

function MapComponent({
  hours, sq, savings, latitude, longitude,
}: MapHeaderProps): JSX.Element {
  const updateMapTile = (map: any, maps: any) => {
    /* eslint-disable no-new */
    new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: 'Your home!',
    });
    const moonMapType = new maps.ImageMapType({
      getTileUrl: (coord: Coord, zoom: number): string => (`https://storage.googleapis.com/solar_tiles_all_2018q2/tile_${coord.x}_${coord.y}_${zoom}.png`),
      tileSize: new maps.Size(256, 256),
      maxZoom: 20,
      minZoom: 1,
      name: 'sunroof-tile-layer',
    });
    map.mapTypes.set('sunroof-tile-layer', moonMapType);
    map.setMapTypeId('sunroof-tile-layer');
    map.setZoom(20);
  };

  return (
    <div className="map-component">
      <div className="map-container">
        <div className="address-details">
          <AddressDetail
            icon={WeatherImage}
            title={
              (
                <div>
                  <NumberFormat
                    value={hours}
                    thousandSeparator=","
                    displayType="text"
                  />
                  {' '}
                  Hours of Usable Sunlight Per Year
                </div>
              )
            }
            content="Based on Weather Patterns"
          />
          <AddressDetail
            icon={NatureImage}
            title={
              (
                <div>
                  <NumberFormat
                    value={sq}
                    thousandSeparator=","
                    displayType="text"
                  />
                  {' '}
                  sq feet Available for Solar Panels
                </div>
              )
            }
            content="Based on Your Roof and Nearby Vegetation"
          />
          <AddressDetail
            icon={SolarEnergyImage}
            title={
              (
                <div>
                  <NumberFormat
                    value={savings}
                    thousandSeparator=","
                    displayType="text"
                    prefix="$"
                  />
                  {' '}
                  Savings
                </div>
              )
            }
            content="Savings for Your Roof Over 20 Years"
          />
        </div>
        <div className="map">
          <div className="google-map">
            <GoogleMapReact
              key={longitude}
              bootstrapURLKeys={{ key: '' }}
              defaultCenter={{ lat: Number(latitude), lng: Number(longitude) }}
              defaultZoom={20}
              options={{ mapTypeId: 'satellite' }}
              onGoogleApiLoaded={({ map, maps }) => updateMapTile(map, maps)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapComponent;
