import React from 'react';
import SolarReportInfo from '../../../components/solar-report-info/solar-report-info';
import RowWrapper from '../../../components/what-youll-get/RowWrapper';
import BaseWhatYoullGet from '../../../components/what-youll-get/what-youll-get';
import SolarImage from '../../../images/solar-cell.png';
import BenefitImage from '../../../images/sustainable.png';
import CostImage from '../../../images/diagram.png';
import PowerImage from '../../../images/ecology-and-environment.png';

export default function WhatYoullGet(): JSX.Element {
  return (
    <BaseWhatYoullGet>
      <RowWrapper>
        <SolarReportInfo
          title="RECOMMENDED SOLAR INSTALLATION SIZE FOR YOUR UNIQUE ROOF"
          icon={SolarImage}
        >
          We use the data from Google Project Sunroof to determine how many solar panels will fit on
          your specific roof. This size recommendation will cover up to 99% of your electricity
          usage, dramatically reducing or even eliminating your dependence on utility companies, the
          grid and fossil fuels.
        </SolarReportInfo>

        <SolarReportInfo
          title="COST & BENEFIT OF ADDING SOLAR PANELS TO YOUR HOME"
          icon={BenefitImage}
        >
          You will be able to see a breakdown of all solar savings as well as the average cost for
          your recommended system size. Your report will also compare the cost and benefits of
          leasing, buying with cash, or financing your solar panels. Plus, our solar payback
          calculator will show you the number of years until payback would happen.
        </SolarReportInfo>
      </RowWrapper>

      <RowWrapper>
        <SolarReportInfo
          title="UPFRONT & OUT-OF-POCKET COSTS FOR YOUR SOLAR SYSTEM RECOMMENDATION"
          icon={CostImage}
        >
          Your report will include the initial cost after tax incentives and government-funded solar
          panel programs. This is the amount that must be paid during the first year. These numbers
          will be compared with out-of-pocket costs, which are calculated before tax incentives.
        </SolarReportInfo>

        <SolarReportInfo
          title="HOW MUCH POWER YOU CAN EXPECT TO PRODUCE FROM YOUR SOLAR SYSTEM"
          icon={PowerImage}
        >
          Solar panels are one of the most practical ways for homeowners to move towards energy
          independence. Our report breaks down the usable sunlight your roof gets each day, so you
          can compare your estimated output with your current energy use pattern. Whether your goal
          is to go completely off-grid or simply reduce your reliance on utility companies, we’ve
          got the information you need to make a smart decision.
        </SolarReportInfo>
      </RowWrapper>
    </BaseWhatYoullGet>
  );
}
