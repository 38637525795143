// noinspection DuplicatedCode

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import { validateRealPhoneNumber } from '../../api/solar-data';
import { getGooglePlace } from '../../api/location-api';
import Footer from '../../components/footer/footer';
import FormCard from '../../components/form-card/form-card';
import Header from '../../components/header/header';
import Option from '../../components/option/option';
import useScript from '../../hooks/useScript';
import { windowsProjectTypes } from './get-started-page.constants';
import './get-started-page.scss';
import { namesOnNext } from './utils';
import { sendGTMStageEvent } from '../../utils/gtm';

// NOTE For convenience (and because some API calls are broken on localhost), you can add
//  `debug_skip_to_step=<step>` to the GET parameters to skip directly to a (zero indexed)
//  step. /tyler

interface TextProps {
  pageURL?: string;
}

const SURVEY_LENGTH = 8;
const ANALYTICS_STEPS = [
  'Zip Code',
  'Own Rent',
  'Project Type',
  'Window Count',
  'Name Details',
  'Location Details',
  'Email Details',
  'Phone Number',
];

function GetStartedPageWindowsV1({ pageURL = '' }: TextProps): JSX.Element {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();

  // noinspection TypeScriptValidateTypes
  const { dispatch } = useStoreon('solarData');

  const [step, setStep] = useState(0);
  const [place, setPlace] = useState<string | undefined>(undefined);
  const [zipCode, setZipCode] = useState<string>('');
  const [address, setAddress] = useState<any>('');
  const [rent, setRent] = useState<string>('own');
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string>('');
  const [anuraFraud, setAnura] = useState('');
  const [leadIdCode, setLeadIdCode] = useState('');
  const [GCLID, setGCLID] = useState('');
  const [FBID, setFBID] = useState('');
  const [sourceID, setSourceID] = useState('');
  const [offerID, setOfferID] = useState('');
  const [token, setToken] = useState('');
  const [trustedFormCertURL, setTrustedFormCertURL] = useState('');
  const [userAgent, setUserAgent] = useState('');
  const [validateRealPhone, setValidateRealPhoneError] = useState<boolean | undefined>(false);
  const [nameError, setNameError] = useState('');

  const [projectType, setProjectType] = React.useState('');
  const onProjectTypeChange = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setProjectType(event.currentTarget.value);
  }, []);

  const [windowCount, setWindowCount] = React.useState('');
  const [windowCountError, setWindowCountError] = React.useState<string | null>(null);

  const onWindowCountChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setWindowCount(event.currentTarget.value);
  }, []);

  const [inputs] = useState<any>(
    <>
      <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
      <input id="offer_id" name="offer_id" type="hidden" value="" />
      <input id="oid" name="oid" type="hidden" value="" />
      <input id="source_id" name="source_id" type="hidden" value="" />
      <input id="sub4" name="sub4" type="hidden" value="" />
      <input id="authorization_token" name="authorization_token" type="hidden" value="" />
      <input id="anura_fraud" name="anura_fraud" type="hidden" value="" />
      <input type="hidden" id="gclid_field" name="gclid_field" value="" />
      <label hidden>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        {`By submitting this form, I am providing The Home Otter and one or more of its partners, 
          affiliates, or their agents (Matched Companies) my electronic signature giving prior 
          express written consent to be contacted and to receive telemarketing calls or texts 
          (including by prerecorded messages or using an autodialer or automated means) regarding 
          related products or services at the number and/or e-mail address provided, even 
          if the number provided is on a corporate, state, or national Do Not Call list. 
          I understand that my consent is not required to receive quotes or make a purchase, 
          I can revoke my consent at any time and I represent that I am at least 18 years 
          old and agree to be bound by Terms of Use and Privacy Policy.`}
      </label>
    </>,
  );

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const targetStepValue = searchParams.get('debug_skip_to_step');

    if (!targetStepValue) {
      return;
    }

    const targetStep = parseInt(targetStepValue, 10);

    if (Number.isNaN(targetStep) || targetStep <= 0 || targetStep >= SURVEY_LENGTH - 1) {
      return;
    }

    setStep(targetStep);
  }, []);

  function CollectHiddenInputs() {
    // @formatter:off
    setAnura((document.getElementById('anura_fraud') as HTMLInputElement).value);
    setToken((document.getElementById('authorization_token') as HTMLInputElement).value);
    setLeadIdCode((document.getElementById('leadid_token') as HTMLInputElement).value);
    setSourceID((document.getElementById('source_id') as HTMLInputElement).value);

    if (offerID === '') {
      setOfferID((document.getElementById('offer_id') as HTMLInputElement).value);
    }

    setTrustedFormCertURL((document.getElementById('xxTrustedFormCertUrl_0') as HTMLInputElement).value);
    setFBID((document.getElementById('facbook_field') as HTMLInputElement).value);
    setGCLID((document.getElementById('gclid_field') as HTMLInputElement).value);
    setUserAgent('');
    // @formatter:on
  }

  const onNext = useCallback(() => {
    if (step < SURVEY_LENGTH) {
      sendGTMStageEvent(ANALYTICS_STEPS[step]);
      setStep((prevStep) => prevStep + 1);
      CollectHiddenInputs();
    }
  }, [step, setStep]);

  const onBack = useCallback(() => {
    if (step > 0) setStep((prevStep) => prevStep - 1);
    setValidateRealPhoneError(false);
    setPhoneSubmitted(false);
  }, [step, setStep]);

  const onWindowCountSubmit = React.useCallback(() => {
    const numericValue = parseInt(windowCount, 10);

    if (Number.isNaN(numericValue) || numericValue <= 0) {
      setWindowCountError('Please enter a valid number.');
    } else {
      onNext();
    }
  }, [onNext, windowCount]);

  const [ip, setIP] = useState();
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = useCallback(() => {
    // noinspection TypeScriptValidateTypes
    dispatch('setUserData', {
      zip_code: zipCode,
      ip_address: ip,
      source_id: sourceID,
      offer_id: offerID,
      trusted_form_cert_url: trustedFormCertURL,
      user_agent: userAgent,
      landing_page_url: pageURL || 'https://homeotter.com/solarV3',
      own_rent: rent,
      project_type: projectType,
      num_windows: parseInt(windowCount, 10),
      address: `${address.street_number} ${address.route}`,
      city: address.locality,
      state: address.administrative_area,
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      anura: anuraFraud,
      lead_id_code: leadIdCode,
      authorization_token: token,
      gclid: GCLID,
      fbid: FBID,
      phone_number: phoneNumber,
    });

    setSubmitted(true);

    // Needs to be fired one final time, since the last form element doesn't call `onNext()`.
    sendGTMStageEvent(ANALYTICS_STEPS[step]);

    if (address.is_building) {
      navigate({ pathname: '/thank-you', search: params });
    } else {
      navigate({ pathname: '/confirm-address', search: params });
    }
  }, [phoneNumber, dispatch]);

  useScript();

  const handleSetOwnRent = (value: string) => {
    setRent(value);
    onNext();
  };

  return (
    <div className="get-started-page">
      <Header simple={true} />
      <div className="get-started-page-container">
        <form>
          {inputs}
          <FormCard
            canProgress={!!zipCode}
            onNext={onNext}
            onBack={onBack}
            step={step}
            index={0}
            progressBar
            allSteps={SURVEY_LENGTH - 1}
            title="Take our quick survey to get connected. Where are you located?"
            capitalize={false}
          >
            <div className="form-body-content">
              <div className="name-form">
                <input
                  type="text"
                  placeholder="Zip Code"
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
            </div>
          </FormCard>

          <FormCard
            canProgress={!!rent}
            onNext={onNext}
            onBack={onBack}
            step={step}
            index={1}
            allSteps={SURVEY_LENGTH - 1}
            title="Do you own or rent your home?"
          >
            <div className="form-body-content">
              <Option
                selected={rent === 'own'}
                onClick={() => handleSetOwnRent('own')}
                value="Own"
              />
              <Option
                selected={rent === 'rent'}
                onClick={() => handleSetOwnRent('rent')}
                value="Rent"
              />
            </div>
          </FormCard>

          <FormCard
            allSteps={SURVEY_LENGTH - 1}
            canProgress={!!projectType && projectType !== windowsProjectTypes[0]}
            index={2}
            onNext={onNext}
            onBack={onBack}
            step={step}
            title="What is your project type?"
          >
            <div className="providers-card">
              <div className="options">
                <select onChange={onProjectTypeChange} value={projectType}>
                  {windowsProjectTypes.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
          </FormCard>

          <FormCard
            allSteps={SURVEY_LENGTH - 1}
            canProgress={!!windowCount}
            index={3}
            onNext={onWindowCountSubmit}
            onBack={onBack}
            step={step}
            title="How many windows do you have?"
          >
            <div className="form-body-content">
              <div className="name-form">
                <div className="email-error">
                  {windowCountError}
                </div>
                <input
                  type="text"
                  placeholder="Enter Number"
                  onChange={onWindowCountChange}
                />
              </div>
            </div>
          </FormCard>

          <FormCard
            canProgress={!!firstName && !!lastName}
            onNext={() => namesOnNext(firstName, lastName, setNameError, onNext)}
            onBack={onBack}
            step={step}
            index={4}
            progressBar={false}
            allSteps={SURVEY_LENGTH - 1}
            title="Who are we preparing this quote for?"
          >
            <div className="form-body-content">
              <div className="email-error">
                {nameError}
              </div>
              <div className="name-form">
                <input
                  type="text"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </FormCard>

          <FormCard
            canProgress={!!place}
            onNext={async () => {
              if (place) {
                getGooglePlace(place, zipCode).then((resp: any) => {
                  setAddress({
                    street_number: resp.street_number,
                    route: resp.route,
                    administrative_area: resp.administrative_area_level_1,
                    zipCode: resp.postal_code,
                    country: resp.country,
                    locality: resp.locality,
                    is_building: resp.is_building,
                    original_address: resp.original_address,
                  });
                  onNext();
                });
              }
            }}
            onBack={onBack}
            step={step}
            index={5}
            allSteps={SURVEY_LENGTH - 1}
            title="Let's find you on the map."
          >
            <div className="form-body-content">
              <div className="name-form">
                <input
                  type="text"
                  placeholder="Street Address"
                  onChange={(e) => setPlace(e.target.value)}
                />
                <p className="text-ex">ex: 123 Main Street</p>
              </div>
            </div>
          </FormCard>

          <FormCard
            canProgress={!!email}
            onNext={async () => {
              if (email && (
                /\S+@\S+\.\S+/.test(email)
              )) {
                onNext();
              } else {
                setEmailError('Please enter a valid email address');
              }
            }}
            onBack={onBack}
            step={step}
            index={6}
            allSteps={SURVEY_LENGTH - 1}
            title="What's the best email to send your quote?"
          >
            <div className="email-input">
              <div className="email-error">
                {emailError}
              </div>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                placeholder="Email address"
              />
            </div>
          </FormCard>

          <FormCard
            lastCard
            loading={submitted && phoneSubmitted}
            progressBar={false}
            canProgress={!!phoneNumber}
            onNext={async () => {
              if (phoneNumber) {
                setPhoneSubmitted(true);
                validateRealPhoneNumber(phoneNumber).then((res: any) => {
                  if (res === true) {
                    setValidateRealPhoneError(false);
                    onSubmit();
                  } else {
                    setValidateRealPhoneError(true);
                    setPhoneSubmitted(false);
                  }
                });
              }
            }}
            onBack={onBack}
            step={step}
            index={7}
            allSteps={SURVEY_LENGTH - 1}
            title="What phone number can we reach you at?"
            nextButtonContent="Next"
          >
            <div className="form-body-content">
              <div className="name-form">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="[+]{1}[0-9]{11,14}"
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {validateRealPhone && (
                  <span className="error-code">Phone number is invalid!</span>
                )}
              </div>
            </div>
            <div className="form-disclaimer">
              {[
                'By submitting this form, I am providing The Home Otter and one or more of its ',
                'partners, affiliates, or their agents ',
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={{ pathname: '/matched-companies' }}
                >
                  (Matched Companies)
                </Link>,
                'my electronic signature giving prior express written consent to be contacted ',
                'and to receive telemarketing calls or texts (including by prerecorded messages ',
                'or using an autodialer or automated means) regarding related products or ',
                'services at the number and/or e-mail address provided, even if the number ',
                'provided is on a corporate, state, or national Do Not Call list. I understand ',
                'that my consent is not required to receive quotes or make a purchase, ',
                'I can revoke my consent at any time and I represent that I am at least 18 ',
                'years old and agree to be bound by ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/term' }}>
                  Terms of Use
                </Link>,
                ' and ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/privacy' }}>
                  Privacy Policy.
                </Link>,
              ]}
            </div>
          </FormCard>
        </form>
      </div>
      <Footer disclaimer={null} tel="+1 866-272-7538 " />
    </div>
  );
}

export default GetStartedPageWindowsV1;
