import React from 'react';
import BaseLandingPageMap from '../../../components/landing-page-map/landing-page-map';

export default function LandingPageMap(): JSX.Element {
  return (
    <BaseLandingPageMap title="How We Create Your Solar Report">
      <p>
        We have partnered with Google Project Sunroof and Google Earth to show you the
        exact amount of sun or shade your roof gets each day. This information is
        critical when deciding if investing in solar is worth it for your home and in
        determining how much money solar could save you.
      </p>
    </BaseLandingPageMap>
  );
}
