import React from 'react';
import { postBathroomFormData } from '../../../api/solar-data';
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import { MinimalOtterImage } from '../../../components/images';
import ThankYouContent, { useThankYouBehavior } from '../../../pages/thank-you';

export default function ThankYou(): React.ReactElement {
  useThankYouBehavior(postBathroomFormData);

  return (
    <div>
      <Header simple={true} />

      <ThankYouContent image={<MinimalOtterImage />} />

      <Footer disclaimer={null} />
    </div>
  );
}
