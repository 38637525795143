export const monthlyBills = ['100', '200', '300', '400'];
export const defaultMonthlyBill = '300';

export const sun = ['Full Sun', 'Partial Shade', 'Mostly Shade', 'Not Sure'];
export const disclaimer = 'By submitting this form, I am providing The Home Otter and one or more of its partners, affiliates, or their agents (Matched Companies) my electronic signature giving prior express written consent to be contacted and to receive telemarketing calls or texts (including by prerecorded messages or using an autodialer or automated means) regarding related products or services at the number and/or e-mail address provided, even if the number provided is on a corporate, state, or national Do Not Call list. I understand that my consent is not required to receive quotes or make a purchase, I can revoke my consent at any time and I represent that I am at least 18 years old and agree to be bound by Terms of Use and Privacy Policy.';

export const homeTypes = [
  'Select One',
  'Single Family',
  'Multi Family',
  'Apartment',
  'Condo',
  'Manufactured',
  'Townhome',
  'Duplex',
];

export const windowsProjectTypes = [
  'Select One',
  'Replacement Windows',
  'Repair Services Only',
  'Repair and New Windows',
];

export const roofingProjectTypes = [
  'Select One',
  'New Roof - New Home',
  'New Roof - Existing Home',
  'Repair',
  'Shingle Over Existing Roof',
];

export const roofingKindTypes = [
  'Select One',
  'Asphalt Shingle',
  'Cedar Shake',
  'Metal',
  'Tar',
  'Tile',
  'Natural Slate',
];

export const bathroomProjectTypes = [
  'Select One',
  'Complete Bathroom Remodel',
  'Vanity Replacement',
  'Bathtub Liner/Shower Enclosure',
  'Bathtub to Shower Conversion',
  'Shower to Bathtub Conversion',
  'Walk-In Tub Installation',
  'Bathtub/Shower Updates',
];
