const API_HOST = process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:3333/api` : `https://${window.location.hostname}/api`;

export const postRoofingFormData = async (formData: any) => {
  const resp = await fetch(`${API_HOST}/capture_roof_data`, {
    method: 'POST',
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    keepalive: true,
    body: JSON.stringify(formData),
  });
  return resp.json();
};
