import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import HouseImage from '../../images/landing_page_house.png';
import OtterImage from '../../images/otter charectors-08.png';
import './solar-report.scss';
import WithDefault from '../WithDefault';

export enum SeparatorPosition {
  AfterTitle,
  AfterSubtitle,
}

interface TextProps {
  separatorPosition?: SeparatorPosition;
  title?: string;
  titleElement?: React.ReactNode;
  subtitleElement?: React.ReactNode;
  content?: React.ReactNode;
}

function SolarReport({
  titleElement,
  subtitleElement,
  content,
  title = '',
  separatorPosition = SeparatorPosition.AfterSubtitle,
}: TextProps) {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();

  const titleFallback = useCallback(() => <h2>Personalized</h2>, []);
  const subtitleFallback = useCallback(() => <h1>{title || 'Solar Report'}</h1>, [title]);
  const contentFallback = useCallback(() => (
    <h3>
      Take our 4 minute questionnaire and receive a free, fully customized, solar report
    </h3>
  ), []);

  return (
    <div className="solar-report">
      <div className="solar-report-wrapper">
        <div className="report-container">
          <WithDefault element={titleElement} fallback={titleFallback} />

          {separatorPosition === SeparatorPosition.AfterTitle && (
            <div className="seperator" />
          )}

          <WithDefault element={subtitleElement} fallback={subtitleFallback} />

          {separatorPosition === SeparatorPosition.AfterSubtitle && (
            <div className="seperator" />
          )}

          <WithDefault element={content} fallback={contentFallback} />

          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              navigate({ pathname: '/get-started', search: params });
            }}
          >
            Get Started
          </button>
        </div>

        <div className="image-container">
          <img className="icon" src={HouseImage} alt="solar house" />
        </div>

        <div className="otter-img">
          <img className="otter-icon" src={OtterImage} alt="otter solar" />
        </div>
      </div>
    </div>
  );
}

export default SolarReport;
