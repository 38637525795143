import React from 'react';
import QuestionItem from './question-item';

export default function DefaultQuestions(): JSX.Element {
  return (
    <>
      <QuestionItem title="Is my house good for solar?">
        <p>
          You may be wondering how many solar panels to power a house and if your house qualifies
          for solar energy. Solar panels for home or residential areas are a great way to lower your
          electricity bill and help the environment at the same time. In order to see if your roof
          gets enough sun, fill out our free solar calculator form where we will calculate this
          information for you.
        </p>
      </QuestionItem>

      <QuestionItem title="How to calculate how many solar panels I need?">
        <p>
          You may be wondering how many solar panels to power a house and if your house qualifies
          for solar energy. Solar panels for home or residential areas are a great way to lower your
          electricity bill and help the environment at the same time. In order to see if your roof
          gets enough sun, fill out our free solar calculator form where we will calculate this
          information for you.
        </p>
      </QuestionItem>

      <QuestionItem title="How much are solar panels?">
        <p>
          The price for solar power ranges depending on how many panels are necessary for your roof.
          The price of solar panels have decreased over the years. Something to make note, when
          thinking about solar panels you have to consider the price of the panels themselves and
          the solar panel installation price.
        </p>
      </QuestionItem>
    </>
  );
}
