import React from 'react';

import ToolImage from '../../images/tool-header.png';
import OtterImage from '../../images/otter charectors-06.png';
import './tool-header.scss';

export default function ToolHeader() {
  return (
    <div className="tool-report">
      <div className="tool-report-wrapper">
        <div className="image-container">
          <img className="icon" src={ToolImage} alt="solar house" />
        </div>
        <div className="tool-container">
          <h1>
            TOOLS
          </h1>
          <img className="otter-icon" src={OtterImage} alt="otter solar house" />
        </div>
      </div>
    </div>
  );
}
