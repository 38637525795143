import { createStoreon, StoreonStore } from 'storeon';
import { getSolarData } from '../api/solar-data';

const solarData = (store: StoreonStore) => {
  store.on('@init', () => ({ solarData: undefined, userData: undefined }));

  // eslint-disable-next-line no-shadow, no-unused-vars, @typescript-eslint/no-shadow
  store.on('setSolarData', (_: any, data: any) => ({ solarData: data }));
  // eslint-disable-next-line no-shadow, no-unused-vars, @typescript-eslint/no-shadow
  store.on('setUserData', (_: any, data: any) => ({ userData: data }));

  store.on('getSolarData', async (_solarData: any, data: {address: any, lastname: any, firstname: any, bill: any, latitude: any, longitude: any, cb: ()=>void }) => {
    const response = await getSolarData(data.latitude, data.longitude, data.bill);
    store.dispatch(
      'setSolarData',
      {
        ...response,
        longitude: data.longitude,
        latitude: data.latitude,
        firstname: data.firstname,
        lastname: data.lastname,
        bill: data.bill,
        address: data.address,
      },
    );
    data.cb();
  });

  store.on('clearSolarData', () => ({ solarData: undefined }));
};

const store = createStoreon([solarData]);

export default store;
