import React from 'react';

interface Props {
  element: React.ReactNode;
  fallback: () => React.ReactNode;
}

export default function WithDefault({ element, fallback }: Props): JSX.Element {
  return (
    <>
      {element !== undefined ? element : fallback()}
    </>
  );
}
