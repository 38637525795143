import React from 'react';
import Intent from '../../intent';
import Divider from '../divider';
import './index.scss';

interface Props {
  title: string;
  image: React.ReactNode;
  children: React.ReactNode;
}

export default function Reasons({ title, image, children }: Props): JSX.Element {
  return (
    <div className="reasons-section">
      <div className="title">
        <h2 className="text">
          {title}
        </h2>

        <div className="image">
          {image}
        </div>
      </div>

      <div className="content">
        {children}
      </div>
    </div>
  );
}

interface ItemProps {
  title: React.ReactNode;
  image: React.ReactNode;
  children: React.ReactNode;
}

export function ReasonItem({ title, image, children }: ItemProps): JSX.Element {
  return (
    <div className="reason-item">
      <div className="image">
        {image}
      </div>

      <div className="content">
        <h3>{title}</h3>

        <Divider intent={Intent.Secondary} />

        {children}
      </div>
    </div>
  );
}

Reasons.Item = ReasonItem;
