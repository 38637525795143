import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './confirmation-modal.scss';

const ConfirmationModal = (handleClick: any, redirectTo: any) => confirmAlert({
  customUI: ({ onClose }) => (
    <div className="custom-ui" style={{ backgroundColor: '#ecf0f6', padding: 20, borderRadius: 20 }}>
      <h2>Are you sure you want to leave?</h2>
      <p>
        By leaving this page you will miss out on important information
        that could save you money!
      </p>
      <div className="btn-group">
        <button type="button" onClick={onClose} className="btn-no">No</button>
        { redirectTo.startsWith('http')
          ? (
            <button
              className="btn-yes"
              type="button"
              onClick={() => {
                window.location = redirectTo;
              }}
            >
              Yes
            </button>
          )
          : (
            <button
              className="btn-yes"
              type="button"
              onClick={() => {
                handleClick(redirectTo);
                onClose();
              }}
            >
              Yes
            </button>
          )}
      </div>
    </div>
  ),
});

export default ConfirmationModal;
