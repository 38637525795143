/* eslint-disable */

import e from 'express';
import React, {useState, useRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { registerOptOut } from '../../api/solar-data';
import './ccpa-page.scss';

function CCPAPage(): JSX.Element {

  const [submitted, setSubmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");

  const [code, setCode] = useState("");
  const [error, setError] = useState('');

  const handleOnSubmit = () => {
    if (email && (/\S+@\S+\.\S+/.test(email))) {
        if (phone && state) {
          registerOptOut(email, phone, state).then((res: any) => {
            if (res.msg === true) {
              setSubmitted(true);
            } else {
              setError('Someting wrong pls try again');
            }
        });
        }
        else {
          setError('All field is required');
        }
    } else {
        setError('Please enter a valid email address');
    }
  }

  const onChange = (value: any) => {
    if (value) {
        setCode(value);
        setCanSubmit(true);
    }
  }

  return (
    <div className='ccpa-page-container'>
      <Header />
      <div className="overlap">
        <div className="container">
          <div className="text-center" style={{textAlign: 'center'}}>
            <h1>Request to Remove and Not Sell Information</h1>
          </div>
          <div className="card">
            {submitted
             ? <h2 className="text">Thank you! Your request has been submitted!</h2>
             : (
              <>
                <h3 className="text">If you do not wish to be in our system or our partner agency's systems, please enter your details below and we will remove your info.</h3>
                <div className="email-error" style={{marginTop: -10, marginBottom: 10}}>
                    {error}
                </div>

                <div className="input-groups">
                    <div className="input-group">
                      <label htmlFor="email" className='input-text' style={{display: 'block'}}>Email *</label>
                      <input required type="email" className="input" name="email" placeholder="yourname@gmail.com" onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="phone" className='input-text' style={{display: 'block'}}>Phone Number *</label>
                        <input type="text" id="phone" className="input" placeholder="1234567891" onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="input-group">
                        <label htmlFor="state" className='input-text' style={{display: 'block'}}>What state do you live in? *</label>
                        <input type="text" name="state" className="input" placeholder="Kansas" onChange={(e) => setState(e.target.value)}/>
                    </div>
                    <div className="captcha">
                        <ReCAPTCHA
                            sitekey="6Lcwit4cAAAAACQGeh9Ip3c9-Zf6bMUxAdJhyezH"
                            onChange={onChange}
                        />,
                    </div>
                </div>
                <button disabled={!canSubmit} className='btn' onClick={() => handleOnSubmit()}>Submit Request</button>
              </>
             )
            }
         </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CCPAPage;
