/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
// export const initializeMetaPixel = (pixelId: string) => {
//   if (!window.fbq) {
//     (function (f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
//       if (f.fbq) return;

//       // Assign fbq to window.fbq with proper typing and fallback to 'any' where necessary.
//       n = f.fbq = function (...args: any[]) {
//         if (n.callMethod) {
//           n.callMethod(...args);
//         } else {
//           n.queue.push(args);
//         }
//       };

//       // Use 'fbqInternal' instead of '_fbq' to avoid linting error.
//       if (!f.fbqInternal) f.fbqInternal = n;

//       // Initialize internal properties.
//       n.push = n;
//       n.loaded = true;
//       n.version = '2.0';
//       n.queue = [];

//       // Dynamically create and insert the script tag for the Meta Pixel.
//       t = b.createElement(e);
//       t.async = true;
//       t.src = v;
//       s = b.getElementsByTagName(e)[0];
//       s.parentNode.insertBefore(t, s);
//     }(
//       window,
//       document,
//       'script',
//       'https://connect.facebook.net/en_US/fbevents.js',
//       undefined,
//       undefined,
//       undefined,
//     ));
//   }

//   // Check if fbq has been successfully initialized (wasn't blocked by ad blocker)
//   if (window.fbq) {
//     window.fbq('init', pixelId);
//   } else {
//     console.warn('Facebook Pixel blocked by an ad blocker.');
//   }
// };

export const pixelTrackCustomEvent = (eventName: string, params: Record<string, any>) => {
  window.fbq('trackCustom', eventName, params);
};

export const pixelTrackStandardEvent = (eventName: string, params: Record<string, any> = {}) => {
  window.fbq('track', eventName, params);
};
