// (window as any).dataLayer.push({     })
function gtmPush(event: string, eventCategory: string, eventAction: string) {
  (window as any).dataLayer.push({
    event,
    eventCategory,
    eventAction,
  });
}
// event: unique, setup from google tag manager
export function sendGTMEvent(event: string, category: string, action: string) {
  gtmPush(event, category, action);
}

export function sendGTMStageEvent(action: string, category: string = 'Form Submission') {
  gtmPush('stagePassed', category, action);
}

export default function pushDataLyer(step: number, form: string) {
  if (form === 'v1') {
    if (step === 0) {
      gtmPush('stagePassed', 'Form Submission', 'Bill Amount');
    }
    if (step === 1) {
      gtmPush('stagePassed', 'Form Submission', 'Own Rent');
    }
    if (step === 2) {
      gtmPush('stagePassed', 'Form Submission', 'Home Type');
    }
    if (step === 3) {
      gtmPush('stagePassed', 'Form Submission', 'Roof Shade');
    }
    if (step === 4) {
      gtmPush('stagePassed', 'Form Submission', 'Location Details');
    }
    if (step === 5) {
      gtmPush('stagePassed', 'Form Submission', 'Electric Provider');
    }
    if (step === 6) {
      gtmPush('stagePassed', 'Form Submission', 'Phone Number');
    }
    if (step === 7) {
      gtmPush('stagePassed', 'Form Submission', 'Verification Code');
    }
    if (step === 8) {
      gtmPush('stagePassed', 'Form Submission', 'Name Details');
    }
    if (step === 9) {
      gtmPush('stagePassed', 'Form Submission', 'Email Details');
    }
  }
  if (form === 'v2') {
    if (step === 0) {
      gtmPush('stagePassed', 'Form Submission', 'Bill Amount');
    }
    if (step === 1) {
      gtmPush('stagePassed', 'Form Submission', 'Own Rent');
    }
    if (step === 2) {
      gtmPush('stagePassed', 'Form Submission', 'Home Type');
    }
    if (step === 3) {
      gtmPush('stagePassed', 'Form Submission', 'Roof Shade');
    }
    if (step === 4) {
      gtmPush('stagePassed', 'Form Submission', 'Location Details');
    }
    if (step === 5) {
      gtmPush('stagePassed', 'Form Submission', 'Electric Provider');
    }
    if (step === 6) {
      gtmPush('stagePassed', 'Form Submission', 'Phone Number');
    }
    if (step === 7) {
      gtmPush('stagePassed', 'Form Submission', 'Name Details');
    }
    if (step === 8) {
      gtmPush('stagePassed', 'Form Submission', 'Email Details');
    }
  }
  if (form === 'v3' || form === 'v4' || form === 'v5') {
    if (step === 0) {
      gtmPush('stagePassed', 'Form Submission', 'Zip Code');
    }
    if (step === 1) {
      gtmPush('stagePassed', 'Form Submission', 'Bill Amount');
    }
    if (step === 2) {
      gtmPush('stagePassed', 'Form Submission', 'Own Rent');
    }
    if (step === 3) {
      gtmPush('stagePassed', 'Form Submission', 'Home Type');
    }
    if (step === 4) {
      gtmPush('stagePassed', 'Form Submission', 'Roof Shade');
    }
    if (step === 5) {
      gtmPush('stagePassed', 'Form Submission', 'Location Details');
    }
    if (step === 6) {
      gtmPush('stagePassed', 'Form Submission', 'Electric Provider');
    }
    if (step === 7) {
      gtmPush('stagePassed', 'Form Submission', 'Name Details');
    }
    if (step === 8) {
      gtmPush('stagePassed', 'Form Submission', 'Email Details');
    }
    if (step === 9) {
      gtmPush('stagePassed', 'Form Submission', 'Phone Number');
    }
  }
}
