import React from 'react';
import CheckYourRoofShade from '../../components/check-your-roof-shade/check-your-roof-shade';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import LandingPageMap from '../../components/landing-page-map/landing-page-map';
import ToolHeader from '../../components/tool-header/tool-header';
import PersonalizedReport from '../../components/personalized-report/personalized-report';
import './tool-page.scss';

interface TextProps {
  zipCodePlaceholder?: string;
  howMuchSunDescription?: string;
  personalizedSolarReportDescription?: string;
}

function HomePage({ zipCodePlaceholder = '', howMuchSunDescription = '', personalizedSolarReportDescription = '' }: TextProps): JSX.Element {
  return (
    <div>
      <Header />
      <div className="landing-page-container">
        <ToolHeader />
        <CheckYourRoofShade zipCodePlaceholder={zipCodePlaceholder} />
        <LandingPageMap
          title="Not sure how much sun your roof receives?"
          content={howMuchSunDescription || `In partnership with google, using advanced api, you can see the exact amount of sun and
          shade that your specific roof receives. This information is very valuable in deciding on solar panels.`}
        />
        <PersonalizedReport
          personalizedSolarReportDescription={personalizedSolarReportDescription}
        />
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
