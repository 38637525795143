import React, { useCallback } from 'react';
import './frequent-questions.scss';
import DefaultQuestions from './default-questions';
import WithDefault from '../WithDefault';

interface Props {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export default function FrequentQuestions({ title, children }: Props): JSX.Element {
  const titleFallback = useCallback(() => <h2>Frequently Asked Questions</h2>, []);

  return (
    <div className="fq-container">
      <div className="header-container">
        <WithDefault element={title} fallback={titleFallback} />
      </div>

      <div className="content-container">
        {children ?? <DefaultQuestions />}
      </div>
    </div>
  );
}
