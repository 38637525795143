import React from 'react';
import {
  GearAndToolsImage,
  MegaphoneImage,
  PiggyBankImage,
} from '../../../components/images';
import Base from '../../../components/reasons-section';
import { BathroomInstallImage, BathtubAndSpeedSquareImage } from '../images';

export default function Reasons(): React.ReactElement {
  return (
    <Base
      title="Why should you update or upgrade your bathroom?"
      image={<BathroomInstallImage />}
    >
      <Base.Item
        title="UPDATE THE LOOK"
        image={<BathtubAndSpeedSquareImage />}
      >
        Bathrooms are one of the most heavily used rooms in any house, so they suffer the most from
        wear and tear. Updating the decor and fixtures can turn any bathroom into a peaceful escape.
      </Base.Item>

      <Base.Item
        title="BOOST THE VALUE OF YOUR HOME"
        image={<MegaphoneImage />}
      >
        If you are looking to sell your house in the future, a newly renovated bathroom can offer a
        significant return on investment by adding value to your home.
      </Base.Item>

      <Base.Item
        title="FIX PLUMBING PROBLEMS"
        image={<GearAndToolsImage />}
      >
        If you’ve had issues with backups, clogs or leaks, a bathroom renovation is the perfect time
        to fix the problems with your plumbing while updating the look of your home.
      </Base.Item>

      <Base.Item
        title="LOWER UTILITY COSTS"
        image={<PiggyBankImage />}
      >
        By updating current fixtures you can make your bathroom more energy-efficient and
        eco-friendly, saving you money now and down the line.
      </Base.Item>
    </Base>
  );
}
