/* eslint-disable */

import e from 'express';
import React, {useState, useRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import './unsubscribe.scss';
import { unsubscribe } from '../../api/solar-data';

function PrivacyPage(): JSX.Element {

  const [submitted, setSubmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [emailError, setEmailError] = useState('')

  const handleOnSubmit = () => {
    if (email && (/\S+@\S+\.\S+/.test(email))) {
        unsubscribe(email, "homeotter", code).then((res: any) => {
          if (res.msg === true) {
            setSubmitted(true);
          } else {
            setEmailError('Someting wrong pls try again');
          }
        });
        setSubmitted(true);
    } else {
        setEmailError('Please enter a valid email address');
    }
  }

  const onChange = (value: any) => {
    if (value) {
        setCode(value);
        setCanSubmit(true);
    }
  }

  return (
    <div className='unsubscribe-page-container'>
      <Header />
      <div className="overlap">
        <div className="container">
          <div className="text-center" style={{textAlign: 'center'}}>
            <h1>Request to Unsubscribe</h1>
          </div>
          <div className="card">
            {submitted
             ? <h2 className="text">Thank you! Your request has been submitted!</h2>
             : (
              <>
                <h3 className="text">If you do not wish to be in our system or our partner agency's systems, please enter your details below and we will remove your info.</h3>
                <div className="email-error">
                    {emailError}
                </div>
                <input type="email" className="input" placeholder="E.G. YOURNAME@GMAIL.COM" onChange={(e) => setEmail(e.target.value)}/>
                <div className="captcha">
                    <ReCAPTCHA
                        sitekey="6Lcwit4cAAAAACQGeh9Ip3c9-Zf6bMUxAdJhyezH"
                        onChange={onChange}
                    />,
                </div>
                <button disabled={!canSubmit} className='btn' onClick={() => handleOnSubmit()}>Submit Request</button>
              </>
             )
            }
         </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPage;
