import { useEffect } from 'react';

const useScript = () => {
  const appendScript = (src: string, id?: string|undefined) => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    if (id) {
      s.id = id;
    }
    s.src = src;
    document.head.appendChild(s);
  };

  const listener = async () => {
    document.body.removeEventListener('mousedown', listener);
    document.body.removeEventListener('keydown', listener);
    document.body.removeEventListener('touchstart', listener);
    appendScript('/scripts/everflow.js');
    appendScript('/scripts/trusted_form.js');
    appendScript('/scripts/jornaya.js', 'LeadiDscript');
    appendScript('/scripts/everflow-custom.js');
    appendScript('/scripts/anura.js');
  };

  useEffect(() => {
    document.body.addEventListener('touchstart', listener);
    document.body.addEventListener('mousedown', listener);
    document.body.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('load', () => {});
    };
  }, []);
};

export default useScript;
