import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './get-started-form.scss';

import { validateRealPhoneNumber } from '../../api/solar-data';
import useScript from '../../hooks/useScript';
import { roofingKindTypes, roofingProjectTypes } from '../get-started-page/get-started-page.constants';
import FormCardV2 from '../../components/form-cardV2/form-cardV2';
import Option from '../../components/option/option';
import { roofFormValidationSchemas } from '../../utils/validations';
import ThumbtackItemsForm from '../../components/thumbtack-items-form/thumbtack-items-form';
import DialogComponent from '../../components/dialog/dialog';
import { getGeoLocation } from '../../api/location-api';
import { Place } from '../../components/google-places/google-places-autocomplete.types';
import GooglePlaceAutocomplete from '../../components/google-places/google-places-autocomplete';
import { validateZipCodeThumbtack } from '../../api/validate-api';
import { postRoofingFormData } from '../../api/roofing-api';
import { pixelTrackCustomEvent } from '../../utils/pixel';
import { ZIP_CODE_TOP_OFFER_ROOFING } from '../../constants/thumbtack';

interface GetStartedRoofFormProps {
  pageURL: string;
}

const SURVEY_LENGTH = 8;
const ANALYTICS_STEPS = [
  'Zip Code',
  'Own Rent',
  'Project Type',
  'Roofing Type',
  'Name Details',
  'Email Details',
  'Location Details',
  'Phone Number',
];

function GetStartedRoofForm(props: GetStartedRoofFormProps): JSX.Element {
  const { pageURL } = props;

  const [openDialog, setOpenDialog] = React.useState(false);

  const [step, setStep] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  const [place, setPlace] = React.useState<Place | null>(null);
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>(undefined);
  const [phoneSubmitted, setPhoneSubmitted] = React.useState(false);
  const [zipCodeLoading, setZipCodeLoading] = React.useState(false);

  // Hidden input values
  const [ip, setIP] = React.useState('');
  const [anuraFraud, setAnura] = React.useState('');
  const [leadIdCode, setLeadIdCode] = React.useState('');
  const [GCLID, setGCLID] = React.useState('');
  const [FBID, setFBID] = React.useState('');
  const [sourceID, setSourceID] = React.useState('');
  const [offerID, setOfferID] = React.useState('');
  const [token, setToken] = React.useState('');
  const [trustedFormCertURL, setTrustedFormCertURL] = React.useState('');
  const [userAgent, setUserAgent] = React.useState('');
  const [validateRealPhone, setValidateRealPhoneError] = React.useState<boolean | undefined>(false);

  const [hiddenInputFields] = React.useState<any>(
    <>
      <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
      <input id="offer_id" name="offer_id" type="hidden" value="" />
      <input id="oid" name="oid" type="hidden" value="" />
      <input id="source_id" name="source_id" type="hidden" value="" />
      <input id="sub4" name="sub4" type="hidden" value="" />
      <input id="authorization_token" name="authorization_token" type="hidden" value="" />
      <input id="anura_fraud" name="anura_fraud" type="hidden" value="" />
      <input type="hidden" id="gclid_field" name="gclid_field" value="" />
      <label hidden>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        {`By submitting this form, I am providing The Home Otter and one or more of its partners, 
          affiliates, or their agents (Matched Companies) my electronic signature giving prior 
          express written consent to be contacted and to receive telemarketing calls or texts 
          (including by prerecorded messages or using an autodialer or automated means) regarding 
          related products or services at the number and/or e-mail address provided, even 
          if the number provided is on a corporate, state, or national Do Not Call list. 
          I understand that my consent is not required to receive quotes or make a purchase, 
          I can revoke my consent at any time and I represent that I am at least 18 years 
          old and agree to be bound by Terms of Use and Privacy Policy.`}
      </label>
    </>,
  );

  const formik = useFormik({
    initialValues: {
      zipCode: '',
      own_rent: 'own',
      projectType: '',
      roofingType: '',
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      phone_number: '',
    },
    // Validation tops until Email
    validationSchema: roofFormValidationSchemas[step],
    onSubmit: () => {
    },
  });

  function CollectHiddenInputs() {
    // @formatter:off
    // Utility function to safely get input value
    const getInputValue = (id: string) => {
      const element = document.getElementById(id) as HTMLInputElement;
      return element ? element.value : '';
    };

    // Use the utility function to get values and update state
    setAnura(getInputValue('anura_fraud'));
    setToken(getInputValue('authorization_token'));
    setLeadIdCode(getInputValue('leadid_token'));
    setSourceID(getInputValue('source_id'));

    if (offerID === '') {
      setOfferID(getInputValue('offer_id'));
    }

    setTrustedFormCertURL(getInputValue('xxTrustedFormCertUrl_0'));
    setFBID(getInputValue('facbook_field'));
    setGCLID(getInputValue('gclid_field'));
    setUserAgent('');
    // @formatter:on
  }

  const onNext = React.useCallback(() => {
    if (step < SURVEY_LENGTH) {
      (window as any).dataLayer.push({
        event: 'roofing_v2_form_progress',
        form_id: 'roofing_v2_form',
        form_step_number: step,
        form_step_input_name: ANALYTICS_STEPS[step],
        object_input: {
          source_id: sourceID,
          offer_id: offerID,
          trusted_form_cert_url: trustedFormCertURL,
          user_agent: userAgent,
          landing_page_url: pageURL,
          anura: anuraFraud,
          lead_id_code: leadIdCode,
          authorization_token: token,
          gclid: GCLID,
          fbid: FBID,
          ip_address: ip,
          ...formik.values,

        },
      });
      setStep(prevStep => prevStep + 1);
      CollectHiddenInputs();
    }
  }, [step, setStep]);

  const onBack = React.useCallback(() => {
    if (step > 0) setStep(prevStep => prevStep - 1);
    setValidateRealPhoneError(false);
    setPhoneSubmitted(false);
  }, [step, setStep]);

  const handleReset = () => {
    formik.resetForm();
    setPlace(null);
    setPhoneNumber(undefined);
    setPhoneSubmitted(false);
    setSubmitted(false);
    setZipCodeLoading(false);
    setStep(0);
  };

  const getData = async () => {
    const response = await getGeoLocation();
    if (response.result?.ipString) {
      setIP(response.result.ipString);
    }
  };

  React.useEffect(() => {
    getData();

    const searchParams = new URLSearchParams(window.location.search);
    const targetStepValue = searchParams.get('debug_skip_to_step');

    if (!targetStepValue) {
      return;
    }

    const targetStep = parseInt(targetStepValue, 10);

    if (Number.isNaN(targetStep) || targetStep <= 0 || targetStep >= SURVEY_LENGTH - 1) {
      return;
    }

    setStep(targetStep);
  }, []);

  useScript();

  const checkZipCode = async () => {
    setZipCodeLoading(true);
    // Always false in local, because we check with file stored on EFS (only staging & production)
    const response = await validateZipCodeThumbtack(formik.values.zipCode);
    if (response?.result === true) {
      onNext();
    } else {
      setOpenDialog(true);
    }
    setZipCodeLoading(false);
  };

  const onSubmit = async () => {
    // noinspection TypeScriptValidateTypes
    const payload = {
      // Hidden input values
      source_id: sourceID,
      offer_id: offerID,
      trusted_form_cert_url: trustedFormCertURL,
      user_agent: userAgent,
      landing_page_url: pageURL,
      anura: anuraFraud,
      lead_id_code: leadIdCode,
      authorization_token: token,
      gclid: GCLID,
      fbid: FBID,
      ip_address: ip,

      zip_code: formik.values.zipCode,
      own_rent: formik.values.own_rent,
      project_type: formik.values.projectType,
      roofing_type: formik.values.roofingType,
      first_name: formik.values.firstName,
      last_name: formik.values.lastName,
      email_address: formik.values.email,
      address: `${place?.street_number} ${place?.route}`,
      city: place?.locality,
      state: place?.administrative_area,
      phone_number: phoneNumber,
    };
    await postRoofingFormData(payload);

    setSubmitted(true);

    // Send analytics to Google Tag Manager & Google Analytics
    (window as any).dataLayer.push({
      event: 'roofing_v2_form_submission',
      form_id: 'roofing_v2_form',
      form_step_number: step,
      form_step_input_name: ANALYTICS_STEPS[step],
      object_input: payload,
    });
    // Send analytics to Meta Pixel
    pixelTrackCustomEvent(
      'Custom Leads',
      {
        zip_code: formik.values.zipCode,
        source_id: sourceID,
        offer_id: offerID,
        trusted_form_cert_url: trustedFormCertURL,
        user_agent: userAgent,
        landing_page_url: pageURL,
        anura: anuraFraud,
        lead_id_code: leadIdCode,
        authorization_token: token,
        gclid: GCLID,
        fbid: FBID,
        ip_address: ip,
      },
    );

    toast.success('Form successfully submitted');
    handleReset();
  };

  // Listener for Thumbtack
  function isZipCodeInTopOffer(zipCode: string): boolean {
    const zipCodeNumber = parseInt(zipCode, 10);
    return ZIP_CODE_TOP_OFFER_ROOFING.zip_code.includes(zipCodeNumber);
  }

  React.useEffect(() => {
    const handleMessageEvent = (event: MessageEvent): void => {
      if (event.data === 'THUMBTACK_RF_CLOSE') {
        setOpenDialog(false);
        if (isZipCodeInTopOffer(formik.values.zipCode)) {
          pixelTrackCustomEvent(
            'Thumbtack High Leads',
            {
              value: ZIP_CODE_TOP_OFFER_ROOFING.price,
              currency: 'USD',
              zip_code: formik.values.zipCode,
              source_id: sourceID,
              offer_id: offerID,
              trusted_form_cert_url: trustedFormCertURL,
              user_agent: userAgent,
              landing_page_url: pageURL,
              anura: anuraFraud,
              lead_id_code: leadIdCode,
              authorization_token: token,
              gclid: GCLID,
              fbid: FBID,
              ip_address: ip,
            },
          );
        } else {
          pixelTrackCustomEvent(
            'Thumbtack Leads',
            {
              value: 0,
              currency: 'USD',
              zip_code: formik.values.zipCode,
              source_id: sourceID,
              offer_id: offerID,
              trusted_form_cert_url: trustedFormCertURL,
              user_agent: userAgent,
              landing_page_url: pageURL,
              anura: anuraFraud,
              lead_id_code: leadIdCode,
              authorization_token: token,
              gclid: GCLID,
              fbid: FBID,
              ip_address: ip,
            },
          );
        }
      }
    };
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  return (
    <>
      <form id="roofing_v2_form" className="get-started-roof-form" style={{ width: '100%' }}>
        {hiddenInputFields}
        {/* Zip Code */}
        <FormCardV2
          loading={zipCodeLoading}
          canProgress={formik.isValid && !!formik.values.zipCode}
          onNext={() => {
            checkZipCode();
          }}
          onBack={onBack}
          step={step}
          index={0}
          progressBar
          allSteps={SURVEY_LENGTH - 1}
          title="Get matched to roofing contractors near you. Where are you located?"
          capitalize={false}
        >
          <div className="form-body-content">
            <div className="input-form">
              <input
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.zipCode && formik.touched.zipCode && (
              <div className="error">{formik.errors.zipCode}</div>
              )}
            </div>
          </div>
        </FormCardV2>
        {/* Own / Rent ? */}
        <FormCardV2
          canProgress={formik.isValid && !!formik.values.own_rent}
          onNext={onNext}
          onBack={onBack}
          step={step}
          index={1}
          allSteps={SURVEY_LENGTH - 1}
          title="Do you own or rent your home?"
        >
          <div className="form-body-content">
            <Option
              selected={formik.values.own_rent === 'own'}
              onClick={() => formik.setFieldValue('own_rent', 'own')}
              value="Own"
            />
            <Option
              selected={formik.values.own_rent === 'rent'}
              onClick={() => formik.setFieldValue('own_rent', 'rent')}
              value="Rent"
            />
            {formik.errors.own_rent && formik.touched.own_rent && (
            <div className="error">{formik.errors.own_rent}</div>
            )}
          </div>
        </FormCardV2>

        {/* Roofing Project Types */}
        <FormCardV2
          allSteps={SURVEY_LENGTH - 1}
          canProgress={formik.isValid
          && !!formik.values.projectType
          && formik.values.projectType !== roofingProjectTypes[0]}
          index={2}
          onNext={onNext}
          onBack={onBack}
          step={step}
          title="What is your project type?"
        >
          <div className="providers-card" style={{ paddingTop: '12px' }}>
            <div className="options">
              <select
                name="projectType"
                onChange={formik.handleChange}
                value={formik.values.projectType}
              >
                {roofingProjectTypes.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
              {formik.errors.projectType && formik.touched.projectType && (
              <div className="error">{formik.errors.projectType}</div>
              )}
            </div>
          </div>
        </FormCardV2>

        {/* Roofing Project Kind Types */}
        <FormCardV2
          allSteps={SURVEY_LENGTH - 1}
          canProgress={formik.isValid
          && !!formik.values.roofingType
          && formik.values.roofingType !== roofingKindTypes[0]}
          index={3}
          onNext={onNext}
          onBack={onBack}
          step={step}
          title="What is your roofing type?"
        >
          <div
            className="providers-card"
            style={{
              paddingTop: '12px',
            }}
          >
            <div className="options">
              <select
                name="roofingType"
                onChange={formik.handleChange}
                value={formik.values.roofingType}
              >
                {roofingKindTypes.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
                {formik.errors.roofingType && formik.touched.roofingType && (
                <div className="error">{formik.errors.roofingType}</div>
                )}
              </select>
            </div>
          </div>
        </FormCardV2>

        {/* First & Last Name */}
        <FormCardV2
          canProgress={formik.isValid
          && !!formik.values.firstName
          && !!formik.values.lastName}
          onNext={onNext}
          onBack={onBack}
          step={step}
          index={4}
          progressBar={false}
          allSteps={SURVEY_LENGTH - 1}
          title="Who are we preparing this quote for?"
        >
          <div className="form-body-content">
            <div className="input-form">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
              />
              {formik.errors.firstName && formik.touched.firstName && (
              <div className="error">{formik.errors.firstName}</div>
              )}
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                onBlur={formik.handleBlur}
              />
              {formik.errors.lastName && formik.touched.lastName && (
              <div className="error">{formik.errors.lastName}</div>
              )}
            </div>
          </div>
        </FormCardV2>

        {/* Email */}
        <FormCardV2
          canProgress={formik.isValid
          && !!formik.values.email}
          onNext={onNext}
          onBack={onBack}
          step={step}
          index={5}
          allSteps={SURVEY_LENGTH - 1}
          title="What's the best email to send your quote?"
        >
          <div className="form-body-content">
            <div className="input-form">
              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && formik.touched.email && (
              <div className="error">{formik.errors.email}</div>
              )}
            </div>
          </div>
        </FormCardV2>

        {/* Place/ Address */}
        <FormCardV2
          canProgress={true}
          onNext={onNext}
          onBack={onBack}
          step={step}
          index={6}
          allSteps={SURVEY_LENGTH - 1}
          title="Let's find you on the map."
        >
          <div
            style={{
              paddingTop: '12px',
            }}
          >
            <div className="form-body-content">
              <div className="input-form">

                <GooglePlaceAutocomplete onPlaceChanged={setPlace} />
                <p className="text-ex">ex: 123 Main Street</p>
              </div>
            </div>
          </div>
        </FormCardV2>

        <FormCardV2
          lastCard
          loading={submitted && phoneSubmitted}
          progressBar={false}
          canProgress={!!phoneNumber}
          onNext={async () => {
            if (phoneNumber) {
              setPhoneSubmitted(true);
              validateRealPhoneNumber(phoneNumber).then((res: any) => {
                if (res === true) {
                  setValidateRealPhoneError(false);
                  onSubmit();
                } else {
                  setValidateRealPhoneError(true);
                  setPhoneSubmitted(false);
                }
              });
            }
          }}
          onBack={onBack}
          step={step}
          index={7}
          allSteps={SURVEY_LENGTH - 1}
          title="What phone number can we reach you at?"
          nextButtonContent="Submit"
        >
          <div key="phone-number" className="form-body-content">
            <div className="input-form">
              <input
                type="tel"
                id="phone"
                name="phone"
                pattern="[+]{1}[0-9]{11,14}"
                placeholder="Phone Number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {validateRealPhone && (
                <span className="error" style={{ padding: 8 }}>Phone number is invalid!</span>
              )}
            </div>
          </div>
          <div className="form-disclaimer" style={{ paddingTop: 12 }}>
            {`By submitting this form, I am providing The Home Otter and one or more of its partners, 
            affiliates, or their agents `}
            <Link
              key="matched-companies"
              target="_blank"
              rel="noopener noreferrer"
              to={{ pathname: '/matched-companies' }}
            >
              (Matched Companies)
            </Link>
            {` my electronic signature giving prior express written consent to be contacted and to receive 
                telemarketing calls or texts (including by prerecorded messages or using an autodialer or 
                automated means) regarding related products or services at the number and/or email address 
                provided, even if the number provided is on a corporate, state, or national Do Not Call list. 
              
                I understand that my consent is not required to receive quotes or make a purchase, I can revoke 
                my consent at any time, and I represent that I am at least 18 years old and agree to be bound by `}
            <Link
              key="tos"
              target="_blank"
              rel="noopener noreferrer"
              to={{ pathname: '/term' }}
            >
              Terms of Use
            </Link>
            {' and '}
            <Link
              key="privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              to={{ pathname: '/privacy' }}
            >
              Privacy Policy
            </Link>
            .
          </div>
        </FormCardV2>
      </form>
      <DialogComponent
        content={<ThumbtackItemsForm zipCode={formik.values.zipCode} />}
        open={openDialog}
        setOpen={setOpenDialog}
        closeButton={true}
        preventCloseOnOutsideClick={true}
      />
      <ToastContainer />

    </>
  );
}

export default GetStartedRoofForm;
