import React from 'react';
import './index.scss';

export enum Color {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

interface Props {
  children: React.ReactNode;
  color: Color;
  tagName?: keyof JSX.IntrinsicElements;
}

export default function Colorize({ children, color, tagName = 'span' }: Props): JSX.Element {
  return React.createElement(
    tagName,
    {
      className: `colorize colorize-${color}`,
    },
    children,
  );
}
