import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CCPAPage from '../../../pages/ccpa-page/ccpa-page';
import GetStartedPageWindowsV1 from '../../../pages/get-started-page/get-started-page-windows-v1';
import MatchedCompanies from '../../../pages/matched-companies/matched-companies';
import NotFoundPage from '../../../pages/not-found-page/not-found-page';
import PrivacyPage from '../../../pages/privacy-page/privacy-page';
import TermsPage from '../../../pages/terms-page/terms-page';
import Unsubscribe from '../../../pages/unsubscribe/unsubscribe';
import AddressConfirmation from './address-confirmation';
import HomePage from './home-page';
import ThankYou from './thank-you';

interface Props {
  basename?: string;
}

export default function WindowsV1({ basename = '/windowsV1' }: Props): JSX.Element {
  return (
    <BrowserRouter basename={basename}>
      <Routes>
        <Route
          path="/"
          element={<HomePage />}
        />

        <Route
          path="/get-started"
          element={<GetStartedPageWindowsV1 pageURL="https://homeotter.com/windowsV1" />}
        />

        <Route path="/confirm-address" element={<AddressConfirmation />} />
        <Route path="/thank-you" element={<ThankYou />} />

        <Route
          path="/term"
          element={(
            <TermsPage
              headerProps={{ simple: true }}
              footerProps={{ disclaimer: null }}
            />
          )}
        />

        <Route
          path="/privacy"
          element={(
            <PrivacyPage
              headerProps={{ simple: true }}
              footerProps={{ disclaimer: null }}
            />
          )}
        />

        <Route
          path="/contact"
          element={(
            <TermsPage
              headerProps={{ simple: true }}
              footerProps={{ disclaimer: null }}
            />
          )}
        />

        <Route path="/matched-companies" element={<MatchedCompanies />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/ccpa" element={<CCPAPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
