import React, { useEffect, useState } from 'react';
import { getPartners } from '../../api/solar-data';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import './matched-companies.scss';

function MatchedCompanies(): JSX.Element {
  const [partners, setPatrners] = useState([]);

  useEffect(() => {
    getPartners().then((res: any) => {
      setPatrners(res.partners);
    });
  }, []);

  return (
    <div className="term-page-container">
      <Header />
      <div className="overlap">
        <div className="container">
          <div className="text-center" style={{ textAlign: 'center' }}>
            <h1>Matched Companies include our Companies, Partners, and Affiliates.</h1>
            {partners?.map((e: any) => (<div dangerouslySetInnerHTML={{ __html: e }} />))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MatchedCompanies;
