import React from 'react';
import BaseLandingPageMap from '../../../components/landing-page-map/landing-page-map';

export default function LandingPageMap(): JSX.Element {
  return (
    <BaseLandingPageMap title="How We Create Your Solar Report">
      <p>
        We have partnered with Google Project Sunroof and Google Earth to show you the exact amount
        of sun or shade your roof gets each day. This information is critical in determining if
        solar panels can help you reduce your dependence on utility companies and the grid.
      </p>
    </BaseLandingPageMap>
  );
}
