import React from 'react';
import './solar-report-info.scss';

interface SolarReportInfoProps {
  title: string;
  icon: string;
  content?: string;
  children?: React.ReactNode;
}

function SolarReportInfoComponent({
  title,
  content,
  icon,
  children,
}: SolarReportInfoProps): JSX.Element {
  return (
    <div className="solar-report-info">
      <div className="icon">
        <img src={icon} alt="solar cell" width={65} />
      </div>

      <div className="title">
        {title}
      </div>

      <div className="seperator" />

      <div className="content">
        {children ?? content}
      </div>
    </div>
  );
}

export default SolarReportInfoComponent;
