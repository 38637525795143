/* eslint-disable */

import React, { useEffect } from 'react';
import Footer, { Props as FooterProps } from '../../components/footer/footer';
import Header, {Props as HeaderProps} from '../../components/header/header';
import './terms-page.scss';

interface Props {
  headerProps?: HeaderProps,
  footerProps?: FooterProps,
}

function TermsPage({ headerProps, footerProps }: Props): JSX.Element {
  return (
    <div className='term-page-container'>
      <Header {...headerProps} />
      <div className="overlap">
        <div className="container">
          <div className="text-center" style={{textAlign: 'center'}}>
            <h1>Website Terms, Conditions, and Notices</h1>
          </div>
          <p>Last updated: Feb 20, 2024</p>
          <p>Welcome to the homeotter.com website (the “Website”). The terms “we”, “us”, “our” HomeOtter, “Site”, "Blue Ink Digital", "Dobak Holdings LLC" and “DH” refer to the owner of the Website and/or our subsidiaries. The term “you” refers to the customer visiting the Website</p>
          <p>The Website is a free service provided to you. Neither the Website or HomeOtter is a licensed broker, carrier or direct provider of any kind of insurance products, financial products, or related services.</p>
          <h2>USE OF THE WEBSITE</h2>
          <p>This Website is offered to you conditioned upon your acceptance without modification of any/all the terms, conditions, and notices set forth below (collectively, the “Agreement”). By accessing or using this Website in any manner, you agree to be bound by the Privacy Policy and the Agreement and its terms which may change from time to time, and represent that you have read and understood its terms. Please read the Agreement carefully, as it contains information concerning your legal rights and limitations on these rights, as well as a section regarding applicable law and jurisdiction of disputes. If you do not accept all of these terms and conditions, please do not use this Website.</p>
          <p>You agree that by using this service you are at least 18 years of age and you are legally able to enter into a contract. If you do not agree, do not use this Website.</p>
          <p>Please be sure to review our <a href="/solarV1/privacy">Privacy Policy</a> to ensure you understand our practices regarding personal information provided by you.</p>
          <p>THIS AGREEMENT CONTAINS AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT WAIVE YOUR RIGHT TO A COURT HEARING OR JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. ARBITRATION IS MANDATORY AND THE EXCLUSIVE REMEDY FOR ANY AND ALL DISPUTES UNLESS SPECIFIED BELOW OR UNLESS YOU OPT-OUT.</p>
          <h2>PROHIBITED ACTIVITIES AND CONDITIONS OF USE</h2>
          <p>Any services offered to you by DH are only available to individuals who (1) can enter into legally binding contracts under applicable law and who are over eighteen (18) years of age; (2) all information that you provide to us in connection with the Website (e.g., name, e-mail address, and other information) is true and accurate and; (3) you are authorized and able to fulfill and perform the obligations and meet the conditions of a user as specified herein.</p>
          <p>Additionally, you agree not to:</p>
          <ul>
            <li>(i) access, monitor or copy any content or information of this Website using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;</li>
            <li>(ii) violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website;</li>
            <li>(iii) take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>(iv) deep-link to any portion of this Website for any purpose without our express written permission; or</li>
            <li>(v) “frame”, “mirror” or otherwise incorporate any part of this Website into any other website without our prior written authorization;</li>
            <li>(vii) attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by DH in connection with the Website or the services;</li>
            <li>(viii) use the Website for any purpose or in any manner that violates any law or regulation.</li>
          </ul>
          <h2>LINKS TO THIRD-PARTY SITES</h2>
          <p>This Website may contain hyperlinks to websites operated by parties other than DH. We do not control such websites and are not responsible for their contents or the privacy or other practices of such websites. Further, it is up to you to take precautions to ensure that whatever links you select or software you download (whether from this Website or other websites) is free of such items as viruses, worms, trojan horses, defects and other items of a destructive nature. Our inclusion of hyperlinks to such websites does not imply any endorsement of the material on such websites or any association with their operators.</p>
          <h2>NOTICE OF RIGHTS</h2>
          <p>By using the Website or the goods and services offered by or through the Website, In doing so, YOU EXPRESSLY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. You explicitly agree that you may not under any circumstances commence, maintain, or participate in any class action, class arbitration, or other representative action or proceeding against DH for any dispute, claim, or alleged damage arising out of, connected with, or relating to your use of the Website or the entering of any data on the website. In the event that this class action and or representative action waiver is unenforceable, any litigation arising out of, connected with, or related to the use of the Website brought against DH may be commenced only in the federal or state courts located in Delaware, USA. You hereby irrevocably consent to the jurisdiction of those courts for such purposes.</p>
          <h2>RIGHT TO USE WEBSITE AND THIRD PARTIES</h2>
          <p>This Agreement provides to you a personal, revocable, limited, non-exclusive, royalty-free, non-sublicensable, non-transferable license to use the Website conditioned on your continued compliance with the terms of this Agreement. You may print and download materials and information from the Website solely for your personal use, provided that all hard copies contain all copyright and other applicable notices contained in such materials and information. Notwithstanding the foregoing, you may not modify, translate, decompile, create derivative work(s) of, copy, distribute, disassemble, broadcast, transmit, publish, remove or alter any proprietary notices or labels, license, sublicense, transfer, sell, mirror, frame, exploit, rent, lease, private label, grant a security interest in, or otherwise use the Website in any manner not expressly permitted herein.</p>
          <p>You understand and agree that DH is not responsible or liable in any manner whatsoever for your use of, or inability to use and/or qualify for, any services or other third party service provider, sponsored products and/or services, or for any dispute between you and any third party service providers. You understand and agree that DH shall not be liable to you or any third party for any modification, suspension or discontinuation of any product, service or promotion offered by any third party service providers or for any omissions, errors, representations, warranties, breaches or negligence by any third party, including but not limited to third parties who obtained your information by your use of the Website.</p>
          <p>You agree to use the Website and services provided at your own risk. Under the terms of this Agreement, participants agree that DH is not liable for any incidental, indirect, consequential or exemplary damages, including (but not limited to) personal injury/wrongful death, damages to computer hardware, software failures, data failures, loss of profit, damages to goodwill, telecommunication equipment failures, or any other equipment failures or damages.</p>
          <h2>LIABILITY DISCLAIMER</h2>
          <p>PLEASE READ THIS SECTION CAREFULLY. THIS SECTION LIMITS DH’s LIABILITY TO YOU FOR ISSUES THAT MAY ARISE IN CONNECTION WITH YOUR USE OF THIS WEBSITE. IF YOU DO NOT UNDERSTAND THE TERMS IN THIS SECTION OR ELSEWHERE IN THE AGREEMENT, PLEASE CONSULT A LAWYER FOR CLARIFICATION BEFORE ACCESSING OR USING THIS WEBSITE.</p>
          <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES PUBLISHED ON THIS WEBSITE MAY INCLUDE INACCURACIES OR ERRORS. DH, ITS SUBSIDIARIES AND CORPORATE AFFILIATES (COLLECTIVELY, THE “DH GROUP COMPANIES”) DO NOT GUARANTEE THE ACCURACY OF, AND DISCLAIM ALL LIABILITY FOR, ANY ERRORS OR OTHER INACCURACIES RELATING TO THE INFORMATION.</p>
          <p>THE DH GROUP COMPANIES MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES CONTAINED ON THIS WEBSITE FOR ANY PURPOSE, AND THE INCLUSION OR OFFERING OF ANY PRODUCTS OR SERVICES ON THIS WEBSITE DOES NOT CONSTITUTE ANY ENDORSEMENT OR RECOMMENDATION OF SUCH PRODUCTS OR SERVICES BY THE DH GROUP COMPANIES. ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND. THE DH GROUP COMPANIES DISCLAIM ALL WARRANTIES AND CONDITIONS THAT THIS WEBSITE, ITS SERVERS OR ANY EMAIL SENT FROM THE DH GROUP COMPANIES, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. DH HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT.</p>
          <p>THE THIRD-PARTY SUPPLIERS PROVIDING INSURANCE OFFERINGS, FINANCIAL PRODUCT OFFERINGS, SWEEPSTAKES, CONTESTS, OR OTHER SERVICES OR PROMOTIONS ON THIS WEBSITE ARE INDEPENDENT CONTRACTORS AND NOT AGENTS OR EMPLOYEES OF THE DH GROUP COMPANIES. THE DH GROUP COMPANIES ARE NOT LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES, BREACHES OR NEGLIGENCE OF ANY SUCH SUPPLIERS OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES RESULTING THEREFROM. IN NO EVENT SHALL THE DH GROUP COMPANIES (OR THEIR OFFICERS, DIRECTORS AND AFFILIATES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE OR WITH THE DELAY OR INABILITY TO ACCESS, DISPLAY OR USE THIS WEBSITE (INCLUDING, BUT NOT LIMITED TO, YOUR RELIANCE UPON REVIEWS AND OPINIONS APPEARING ON THIS WEBSITE; ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED SITES, PRODUCTS, AND SERVICES OBTAINED THROUGH THIS WEBSITE; OR OTHERWISE ARISING OUT OF THE ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE) WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, AND EVEN IF DH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
          <p>If the DH Group Companies are found liable for any loss or damage that arises out of or is in any way connected with your use of this Website or services offered through DH, then the DH Group Companies’ liabilities will in no event exceed, in the aggregate, the greater of (a) the transaction fees paid to DH for the transaction(s) on this Website giving rise to the claim, or (b) One-Hundred Dollars (US $100.00).</p>
          <p>The limitation of liability reflects the allocation of risk between the parties. The limitations specified in this section will survive and apply even if any limited remedy specified in these terms is found to have failed of its essential purpose. The limitations of liability provided in these terms inure to the benefit of the DH Group Companies.</p>
          <h2>INDEMNIFICATION</h2>
          <p>You agree to defend and indemnify DH and its affiliates and any of their officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:</p>
          <ul>
            <li>(i) your breach of this Agreement or the documents referenced herein;</li>
            <li>(ii) your violation of any law or the rights of a third party; or</li>
            <li>(iii) your use of this Website or our services.</li>
          </ul>
          <h2>JURISDICTION AND GOVERNING LAW</h2>
          <p>This Website is operated by a U.S. entity and this Agreement is governed by the laws of the State of Delaware, USA. You hereby consent to the exclusive jurisdiction and venue of courts in Delaware U.S. and stipulate to the fairness and convenience of proceedings in such courts for all disputes arising out of or relating to the use of this Website. Except as related to the mandatory arbitration, you agree that all claims you may have against DH arising from or relating to this Website must be heard and resolved in a court of competent subject matter jurisdiction located in the State of Delaware. Use of this Website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including, without limitation, this paragraph. Any legal suit, action or proceeding arising out of or related to this Agreement or the matters contemplated hereunder shall be instituted exclusively in the federal courts of the United States or the courts of State of Delaware and each Party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action or proceeding and waives any objection based on improper venue or forum non conveniens.</p>
          <h2>AGREEMENT TO ARBITRATE</h2>
          <p>
            You and we each agree that any and all disputes or claims that relate to or arise from your use of or access to our Services, or any products or services sold, offered, or purchased through our
            Services, including any contact from our subsidiaries, affiliates, or agents, shall be resolved
            exclusively through final and binding arbitration between us and you, or between our
            subsidiaries, affiliates, or agents and you, rather than in court, except that you may assert
            claims in small claims court, if your claims qualify. The Federal Arbitration Act governs
            the interpretation and enforcement of this Agreement to Arbitrate section (this “Agreement to
            Arbitrate”).
          </p>
          <p>
            Prohibition of Class and Representative Actions and Non-Individualized Relief
            You and we agree that each of us may bring claims against the other only on an individual basis
            and not as a plaintiff or class member in any purported class or representative action or
            proceeding, including but not limited to actions under the Telephone Consumer Protection Act,
            47 U.S.C. § 227 et seq. Unless both you and us agree otherwise, the arbitrator may not consolidate or join more than one person’s or party’s claims, and may not otherwise preside over any form if a consolidated, representative, or class proceeding. The arbitrator may award relief, including monetary, injunctive, and declaratory relief, only in favor of the individual party seeking relief and only to the extent necessary to provide relief necessitated by that party’s individual claim(s). Any relief awarded cannot affect our other users.
          </p>
          <h2>Arbitration Procedures</h2>
          <p>
            The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority
            to resolve any dispute arising out of or relating to the interpretation, applicability, enforceability
            or formation of this Agreement to Arbitrate, any part of it, or of this Agreement including, but not
            limited to, any claim that all or any part of the Agreement to Arbitrate or this Agreement is void
            or voidable. The arbitration will be conducted by the American Arbitration Association (“AAA”) under its rules and procedures, including the AAA’s Supplementary Procedures for
            Consumer-Related Disputes (as applicable), as modified by this Agreement to Arbitrate. The
            AAA’s rules and a form for initiating arbitration proceedings is available on the AAA’s site at
            http://www.adr.org.
          </p>
          <p>
            The arbitration shall be held in the county in which you reside or at another mutually agreed
            location. If the value of the relief sought is $10,000 or less, you or we may elect to
            have the arbitration conducted by telephone or based solely on written submissions, which
            election shall be binding on you and us subject to the arbitrator’s discretion to require an
            in-person hearing, if the circumstances warrant. Attendance at an in-person hearing may be
            made by telephone by you and/or us unless the arbitrator requires otherwise.
            The arbitrator will decide the substance of all claims in accordance with the laws of the State of
            Delaware, including recognized principles of equity, and will honor all claims of privilege
            recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving
            our other users but is bound by rulings in prior arbitrations involving the same user to the extent
            required by applicable law. The arbitrator’s award shall be final and binding and judgment on
            the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
            This arbitration provision shall survive termination of this Agreement to Arbitrate and the closing
            of your Account or your use of or access to our Services, or any products or services sold,
            offered, or purchased through our Services.
          </p>
          <h2>Costs of Arbitration</h2>
          <p>
            Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules,
            unless otherwise stated in this Agreement to Arbitrate. If the value of the relief sought is $10,000
            or less, at your request, we will pay all filing, administration, and arbitrator fees associated with
            the arbitration. Any request for payment of fees by us should be submitted by mail to the AAA
            along with your Demand for Arbitration and we will arrange to pay all necessary fees directly to
            the AAA. If the value of the relief sought is more than $10,000 and you can demonstrate that the
            costs of arbitration will be prohibitive as compared to the costs of litigation, we will pay as much
            of the filing, administration, and arbitrator fees as the arbitrator deems necessary to prevent the
            arbitration from being cost-prohibitive. In the event the arbitrator determines the claim(s) you
            assert in the arbitration to be frivolous, you agree to reimburse us for all fees associated with the
            arbitration paid by us on your behalf, which you otherwise would be obligated to pay under the
            AAA’s rules.
          </p>
          <h2>Confidentiality</h2>
          <p>
            The arbitration proceedings and arbitration award shall be maintained by the parties as strictly
            confidential, except as is otherwise required by court order or as is necessary to confirm, vacate
            or enforce the award and for disclosure in confidence to the parties’ respective attorneys, tax
            advisors and senior management and to family members of a party who is an individual.
          </p>
          <h2>Severability</h2>
          <p>
            Except for any of the provisions in this Agreement to Arbitrate (“Prohibition of Class
            and Representative Actions and Non-Individualized Relief”), if an arbitrator or court decides
            that any part of this Agreement to Arbitrate is invalid or unenforceable, the other parts of this
            Agreement to Arbitrate shall still apply. If an arbitrator or court decides that any of the
            provisions in Section of this Agreement to Arbitrate (“Prohibition of Class and
            Representative Actions and Non- Individualized Relief”) is invalid or unenforceable, then the
            entirety of this Agreement to Arbitrate shall be null and void. The remainder of the
            Agreement and its Legal Disputes Section will continue to apply.
          </p>
          <div id="ccpa"></div>
          <h2>Authorization to Contact and CCPA</h2>
          <p>You understand and agree that by providing us with a telephone number and other contact information, you agree to receive communications, including via-email, texts, and calls (including text messages and calls made using an autodialer or prerecorded voice message), from us or on our behalf (or Our affiliates, subsidiaries, employees, contractors, agents, business partners or other third parties permitted to receive your information under the Privacy Policy and Companies at the email address or telephone number you provided, even if that number is on a National or State Do Not Call List. These calls may be for informational and marketing purposes, such as to provide you with information about our services, for assistance with applications, and to provide reminders of deadlines. You are not required to provide your consent to these calls as a condition of any purchase through us, and you may revoke any consent for marketing messages, phone calls or text messages by contacting us as listed below. If you are subject to the California Consumer Protection Act (CCPA) you may revoke their consent using the “Do not sell my personal information” page or by contacting us as described below. Standard text messaging and telephone minute charges applied by your cell phone carrier will apply. We may monitor and/or record telephone conversations for our business purposes, such as quality assurance and training purposes and to protect our rights and the rights of others.</p>
          <h2>CONTACT US</h2>
          <p>If you have any questions regarding this Agreement you may contact DH by filling out the form on the Contact Us portion of the Website or by emailing <a href="mailto:legal@blueinkdigital.com">legal@blueinkdigital.com</a></p>
          <h2>ADDRESS</h2>
          <p>6 Liberty Square Boston, MA 02109 </p>
          </div>
        </div>
      <Footer {...footerProps} />
    </div>
  );
}

export default TermsPage;
