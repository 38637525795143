import React from 'react';
import OtterImage from '../../images/otter charectors-08.png';
import Intent from '../../intent';
import Divider from '../divider';
import GetStartedButton from '../get-started-button';
import './index.scss';

interface Props {
  title: React.ReactNode,
  subtitle?: React.ReactNode,
  content?: React.ReactNode,
  image: React.ReactNode,
}

export default function Hero({
  title,
  subtitle,
  content,
  image,
}: Props): JSX.Element {
  return (
    <div className="hero-container">
      <div className="content-wrapper">
        <div className="content">
          {title}
          {subtitle}

          <Divider />

          <div className="text">
            {content}
          </div>

          <GetStartedButton intent={Intent.Secondary} className="cta-button" />
        </div>

        <div className="hero-image">
          {image}
        </div>
      </div>

      <div className="otter">
        <img src={OtterImage} alt="home otter mascot" />
      </div>
    </div>
  );
}
