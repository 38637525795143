import React from 'react';
import { capitalCase } from 'change-case';

type BadgeProps = {
  text: string;
};

function Badge({
  text,
}: BadgeProps) {
  return (
    <div style={{
      borderRadius: 999,
      padding: 8,
      paddingLeft: 12,
      paddingRight: 12,
      color: '#3730a3',
      backgroundColor: '#eef2ff',
      fontSize: 12,
      fontWeight: 600,
    }}
    >
      {capitalCase(text)}
    </div>
  );
}

export default Badge;
