/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import axios from 'axios';
import {
  getUtilityProvider,
  validateRealPhoneNumber,
} from '../../api/solar-data';
import { getGooglePlace } from '../../api/location-api';
import Footer from '../../components/footer/footer';
import FormCard from '../../components/form-card/form-card';
import Header from '../../components/header/header';
import Option from '../../components/option/option';
import useScript from '../../hooks/useScript';
import { monthlyBills, sun, homeTypes } from './get-started-page.constants';
import './get-started-page.scss';
import { showPrice, namesOnNext } from './utils';
import pushDataLyer from '../../utils/gtm';

interface TextProps {
  pageURL?: string;
  version?: string;
}
const SURVEY_LENGTH = 10;

function GetStartedPageV3({ pageURL = '', version = '' }: TextProps): JSX.Element {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();
  const { solarData, dispatch } = useStoreon('solarData');
  const [step, setStep] = useState(0);
  const [place, setPlace] = useState<string|undefined>(undefined);
  const [zipCode, setZipCode] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [address, setAddress] = useState<any>('');
  const [homeType, setHomeType] = useState<string|undefined>('Select One');
  const [monthlyBill, setMonthlyBill] = useState<string|undefined>(undefined);
  const [sunAmount, setSunAmount] = useState<string|undefined>(undefined);
  const [rent, setRent] = useState<string|undefined>(undefined);
  const [utilityProviders, setUtilityProviders] = useState<string[]|undefined>(undefined);
  const [utilityProvider, setUtilityProvider] = useState<string|undefined>(undefined);
  const [firstName, setFirstName] = useState<string|undefined>(undefined);
  const [lastName, setLastName] = useState<string|undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string|undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [email, setEmail] = useState<string|undefined>(undefined);
  const [emailError, setEmailError] = useState<string>('');
  const [anuraFraud, setAnura] = useState('');
  const [leadIdCode, setLeadIdCode] = useState('');
  const [GCLID, setGCLID] = useState('');
  const [FBID, setFBID] = useState('');
  const [sourceID, setSourceID] = useState('');
  const [offerID, setOfferID] = useState('');
  const [token, setToken] = useState('');
  const [trustedFormCertURL, setTrustedFormCertURL] = useState('');
  const [userAgent, setUserAgent] = useState('');
  const [validateRealPhone, setValidateRealPhoneError] = useState<boolean|undefined>(false);
  const [zipError, setZipError] = useState('');
  const [nameError, setNameError] = useState('');

  const [inputs] = useState<any>(
    <>
      <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
      <input id="offer_id" name="offer_id" type="hidden" value="" />
      <input id="oid" name="oid" type="hidden" value="" />
      <input id="source_id" name="source_id" type="hidden" value="" />
      <input id="sub4" name="sub4" type="hidden" value="" />
      <input id="authorization_token" name="authorization_token" type="hidden" value="" />
      <input id="anura_fraud" name="anura_fraud" type="hidden" value="" />
      <input type="hidden" id="gclid_field" name="gclid_field" value="" />
      <label hidden>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        {`By submitting this form, I am providing The Home Otter and one or more of its partners, 
          affiliates, or their agents (Matched Companies) my electronic signature giving prior 
          express written consent to be contacted and to receive telemarketing calls or texts 
          (including by prerecorded messages or using an autodialer or automated means) regarding 
          related products or services at the number and/or e-mail address provided, even 
          if the number provided is on a corporate, state, or national Do Not Call list. 
          I understand that my consent is not required to receive quotes or make a purchase, 
          I can revoke my consent at any time and I represent that I am at least 18 years 
          old and agree to be bound by Terms of Use and Privacy Policy.`}
      </label>
    </>,
  );

  function CollectHiddenInputs() {
    setAnura((document.getElementById('anura_fraud') as HTMLInputElement).value);
    setToken((document.getElementById('authorization_token') as HTMLInputElement).value);
    setLeadIdCode((document.getElementById('leadid_token') as HTMLInputElement).value);
    setSourceID((document.getElementById('source_id') as HTMLInputElement).value);
    if (offerID === '') {
      setOfferID((document.getElementById('offer_id') as HTMLInputElement).value);
    }
    setTrustedFormCertURL((document.getElementById('xxTrustedFormCertUrl_0') as HTMLInputElement).value);
    setFBID((document.getElementById('facbook_field') as HTMLInputElement).value);
    setGCLID((document.getElementById('gclid_field') as HTMLInputElement).value);
    setUserAgent('');
  }

  const onNext = useCallback(() => {
    if (step < SURVEY_LENGTH) {
      pushDataLyer(step, version);
      setStep((prevStep) => prevStep + 1);
      CollectHiddenInputs();
    }
  }, [step, setStep]);

  const onBack = useCallback(() => {
    if (step > 0) setStep((prevStep) => prevStep - 1);
    setValidateRealPhoneError(false);
    setPhoneSubmitted(false);
  }, [step, setStep]);

  const [ip, setIP] = useState();
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = useCallback(() => {
    dispatch('setUserData', {
      zip_code: zipCode,
      ip_address: ip,
      source_id: sourceID,
      offer_id: offerID,
      trusted_form_cert_url: trustedFormCertURL,
      user_agent: userAgent,
      landing_page_url: pageURL || 'https://homeotter.com/solarV3',
      monthly_bill: monthlyBill,
      own_rent: rent,
      home_type: homeType,
      sun: sunAmount,
      address: `${address.street_number} ${address.route}`,
      city: address.locality,
      state: address.administrative_area,
      electric_provider: utilityProvider,
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      anura: anuraFraud,
      lead_id_code: leadIdCode,
      authorization_token: token,
      gclid: GCLID,
      fbid: FBID,
      phone_number: phoneNumber,
    });
    dispatch('getSolarData', {
      latitude,
      longitude,
      firstname: firstName,
      lastname: lastName,
      bill: monthlyBill,
      address,
      cb: () => {
        navigate({ pathname: '/analysis', search: params });
      },
    });
    setSubmitted(true);
    pushDataLyer(9, version);
  }, [phoneNumber, dispatch]);

  useScript();

  const canProgressHomeType = () => {
    if (homeType === 'Select One') {
      return false;
    }
    return true;
  };

  const handleSetMonthlyBill = (value: string) => {
    setMonthlyBill(value);
    onNext();
  };

  const handleSetOwnRent = (value: string) => {
    setRent(value);
    onNext();
  };

  const handleSunRoof = (value: string) => {
    setSunAmount(value);
    onNext();
  };

  const handleElectricProvider = (value: string) => {
    setUtilityProvider(value);
    onNext();
  };

  const utilityDropdown = (elements: any) => {
    elements.unshift('Choose from list');
    return elements;
  };

  return (
    <div className="get-started-page">
      <Header />
      <div className="get-started-page-container">
        <form>
          {version === 'v4'
          && (
          <h2 style={{
            width: 'fit-content',
            maxWidth: '75%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10vh',
            fontSize: '36px',
          }}
          >
            See how much it will cost to install solar panels for your home
          </h2>
          )}
          {inputs}
          <FormCard
            canProgress={!!zipCode}
            onNext={async () => {
              if (zipCode) {
                getUtilityProvider(zipCode).then((res: any) => {
                  if (res.message === 'Utility Providers not found') {
                    setZipError('This zip code is invalid');
                  } else {
                    setZipError('');
                    setUtilityProviders(res.utility_providers);
                    onNext();
                  }
                });
              }
            }}
            onBack={onBack}
            step={step}
            index={0}
            progressBar
            allSteps={SURVEY_LENGTH - 1}
            title="What is your zip code?"
          >
            <div className="form-body-content">
              <div className="name-form">
                <div className="email-error">
                  {zipError}
                </div>
                <input type="text" placeholder="Zip Code" onChange={(e) => setZipCode(e.target.value)} />
              </div>
            </div>
          </FormCard>
          <FormCard nextButton={false} canProgress={!!monthlyBill} onNext={onNext} onBack={onBack} step={step} index={1} allSteps={SURVEY_LENGTH - 1} title="Roughly how much do you pay for electricity each month?">
            <div className="form-body-content">
              {monthlyBills.map((e: any, i) => (
                <Option
                  selected={monthlyBill === e}
                  key={i}
                  onClick={() => {
                    handleSetMonthlyBill(e);
                  }}
                  value={showPrice(e)}
                />
              ))}
            </div>
          </FormCard>
          <FormCard nextButton={false} canProgress={!!rent} onNext={onNext} onBack={onBack} step={step} index={2} allSteps={SURVEY_LENGTH - 1} title="DO YOU OWN OR RENT?">
            <div className="form-body-content">
              <Option selected={rent === 'own'} onClick={() => handleSetOwnRent('own')} value="Own" />
              <Option selected={rent === 'rent'} onClick={() => handleSetOwnRent('rent')} value="Rent" />
            </div>
          </FormCard>
          <FormCard canProgress={canProgressHomeType()} onNext={onNext} onBack={onBack} step={step} index={3} allSteps={SURVEY_LENGTH - 1} title="WHAT TYPE OF HOME DO YOU LIVE IN?">
            <div className="providers-card">
              <div className="options">
                <select
                  className={`${homeTypes.findIndex((e) => homeType === e) > 1 ? 'selected' : ''}`}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setHomeType(e.target.value);
                  }}
                >
                  {homeTypes.map((e) => (<option key={e} value={e}>{e}</option>))}
                </select>
              </div>
            </div>
          </FormCard>
          <FormCard nextButton={false} canProgress={!!sunAmount} onNext={onNext} onBack={onBack} step={step} index={4} allSteps={SURVEY_LENGTH - 1} title="HOW MUCH SUN DOES YOUR ROOF GET?">
            <div className="form-body-content">
              {sun.map((e, i) => (
                <Option
                  selected={sunAmount === e}
                  key={i}
                  onClick={() => {
                    handleSunRoof(e);
                  }}
                  value={e}
                />
              ))}
            </div>
          </FormCard>
          <FormCard
            canProgress={!!place}
            onNext={async () => {
              if (place) {
                getGooglePlace(place, zipCode).then((resp: any) => {
                  if (resp.is_building) {
                    setLatitude(resp.latitude);
                    setLongitude(resp.longitude);
                  } else {
                    // set fixed lat, lng for show blur report
                    setLatitude('40.7346777');
                    setLongitude('-73.82374519999999');
                  }
                  setAddress({
                    street_number: resp.street_number,
                    route: resp.route,
                    administrative_area: resp.administrative_area_level_1,
                    zipCode: resp.postal_code,
                    country: resp.country,
                    locality: resp.locality,
                    is_building: resp.is_building,
                    original_address: resp.original_address,
                  });
                  onNext();
                });
              }
            }}
            onBack={onBack}
            step={step}
            index={5}
            allSteps={SURVEY_LENGTH - 1}
            title="WHAT IS THE ADDRESS YOU WANT THE SOLAR PANELS INSTALLED AT?"
          >
            <div className="form-body-content">
              <div className="name-form">
                <input type="text" placeholder="Street Address" onChange={(e) => setPlace(e.target.value)} />
                <p className="text-ex">ex: 123 Main Street</p>
              </div>
            </div>
          </FormCard>
          <FormCard canProgress={!!utilityProvider && utilityProvider !== 'Choose from list'} onNext={onNext} onBack={onBack} step={step} index={6} allSteps={SURVEY_LENGTH - 1} title="WHO IS YOUR ELECTRIC PROVIDER?">
            <div className="providers-card">
              {utilityProviders ? (
                <>
                  {utilityProviders && (
                  <>
                    <div
                      className={`provider-option ${utilityProviders[0] === utilityProvider ? 'selected' : ''}`}
                      onClick={() => {
                        handleElectricProvider(utilityProviders[0]);
                      }}
                    >
                      {utilityProviders[0]}

                    </div>
                    <div
                      className={`provider-option ${utilityProviders[1] === utilityProvider ? 'selected' : ''}`}
                      onClick={() => {
                        handleElectricProvider(utilityProviders[1]);
                      }}
                    >
                      {utilityProviders[1]}
                    </div>
                  </>
                  )}
                  <div className="options">
                    <select
                      className={`${utilityProviders.findIndex((e) => utilityProvider === e) > 1 ? 'selected' : ''}`}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setUtilityProvider(e.target.value);
                      }}
                    >
                      {utilityDropdown([...utilityProviders].slice(2, utilityProviders.length))
                        .map((e: any) => (<option key={e} value={e}>{e}</option>))}
                    </select>
                  </div>
                </>
              ) : <div className="provider-option">No utility providers :/</div>}
            </div>
          </FormCard>
          <FormCard canProgress={!!firstName && !!lastName} onNext={() => namesOnNext(firstName, lastName, setNameError, onNext)} onBack={onBack} step={step} index={7} progressBar={false} allSteps={SURVEY_LENGTH - 3} title="Who are we preparing this report for?">
            <div className="form-body-content">
              <div className="email-error">
                {nameError}
              </div>
              <div className="name-form">
                <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>
          </FormCard>
          <FormCard
            canProgress={!!email}
            onNext={async () => {
              if (email && (/\S+@\S+\.\S+/.test(email))) {
                onNext();
              } else {
                setEmailError('Please enter a valid email address');
              }
            }}
            onBack={onBack}
            step={step}
            index={8}
            allSteps={SURVEY_LENGTH - 1}
            title="What's the best email for the report?"
          >
            <div className="email-input">
              <div className="email-error">
                {emailError}
              </div>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                placeholder="Email address"
              />
            </div>
          </FormCard>
          <FormCard
            lastCard
            loading={!solarData && submitted && phoneSubmitted}
            progressBar={false}
            canProgress={!!phoneNumber}
            onNext={async () => {
              if (phoneNumber) {
                setPhoneSubmitted(true);
                validateRealPhoneNumber(phoneNumber).then((res: any) => {
                  if (res === true) {
                    setValidateRealPhoneError(false);
                    onSubmit();
                  } else {
                    setValidateRealPhoneError(true);
                    setPhoneSubmitted(false);
                  }
                });
              }
            }}
            onBack={onBack}
            step={step}
            index={9}
            allSteps={SURVEY_LENGTH - 1}
            title="Finally, what phone number can we reach you at?"
          >
            <div className="form-body-content">
              <div className="name-form">
                <input type="tel" id="phone" name="phone" pattern="[+]{1}[0-9]{11,14}" placeholder="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
                {validateRealPhone && (<span className="error-code">Phone number is invalid!</span>)}
              </div>
            </div>
            <div className="form-disclaimer">
              {[
                'By submitting this form, I am providing The Home Otter and one or more of its ',
                'partners, affiliates, or their agents ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/matched-companies' }}> (Matched Companies) </Link>,
                'my electronic signature giving prior express written consent to be contacted ',
                'and to receive telemarketing calls or texts (including by prerecorded messages ',
                'or using an autodialer or automated means) regarding related products or ',
                'services at the number and/or e-mail address provided, even if the number ',
                'provided is on a corporate, state, or national Do Not Call list. I understand ',
                'that my consent is not required to receive quotes or make a purchase, ',
                'I can revoke my consent at any time and I represent that I am at least 18 ',
                'years old and agree to be bound by ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/term' }}>Terms of Use</Link>,
                ' and ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/privacy' }}>Privacy Policy.</Link>,
              ]}
            </div>
          </FormCard>
        </form>
      </div>
      <Footer tel="+1 855-424-9699" />

    </div>
  );
}

export default GetStartedPageV3;
