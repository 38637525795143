import React, { useCallback } from 'react';
import CheckYourRoofShade from '../../components/check-your-roof-shade/check-your-roof-shade';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import LandingPageMap from '../../components/landing-page-map/landing-page-map';
import SolarReport from '../../components/solar-report/solar-report';
import WhatYoullGet from '../../components/what-youll-get/what-youll-get';
import WhyReportComponent from '../../components/why-report/why-report';
import WithDefault from '../../components/WithDefault';
import './home-page.scss';

interface TextProps {
  title?: string;
  howCreateDescription?: string;
  zipCodePlaceholder?: string;
  whatIsInReportSection1?: string;
  whatIsInReportSection2?: string;
  whatIsInReportSection3?: string;
  whatIsInReportSection4?: string;
  whySouldYouGet?: string;
  questions?: boolean;
  headerElement?: React.ReactNode;
  mapElement?: React.ReactNode;
  checkRoofElement?: React.ReactNode;
  whatYoullGetElement?: React.ReactNode;
  whyReportElement?: React.ReactNode;
}

function HomePage({
  headerElement,
  mapElement,
  checkRoofElement,
  whatYoullGetElement,
  whyReportElement,
  title = '',
  howCreateDescription = '',
  zipCodePlaceholder = '',
  whatIsInReportSection1 = '',
  whatIsInReportSection2 = '',
  whatIsInReportSection3 = '',
  whatIsInReportSection4 = '',
  whySouldYouGet = '',
  questions = false,
}: TextProps): JSX.Element {
  const mapFallback = useCallback(() => (
    <LandingPageMap
      title="How do we create your Solar Report"
      content={howCreateDescription || `We have partnered with Google Sunroof Project and Google Earth, to show you your home.
        You will be able to see the exact amount of sun or shade your home receives.
        This information is critical when deciding if solar is right for you.`}
    />
  ), [howCreateDescription]);

  const checkRoofFallback = useCallback(() => (
    <CheckYourRoofShade zipCodePlaceholder={zipCodePlaceholder} />
  ), [zipCodePlaceholder]);

  const whatYoullGetFallback = useCallback(() => (
    <WhatYoullGet
      whatIsInReportSection1={whatIsInReportSection1}
      whatIsInReportSection2={whatIsInReportSection2}
      whatIsInReportSection3={whatIsInReportSection3}
      whatIsInReportSection4={whatIsInReportSection4}
    />
  ), [
    whatIsInReportSection1,
    whatIsInReportSection2,
    whatIsInReportSection3,
    whatIsInReportSection4,
  ]);

  const whyReportFallback = useCallback(() => (
    <WhyReportComponent
      whySouldYouGet={whySouldYouGet}
      questions={questions}
    />
  ), [whySouldYouGet, questions]);

  return (
    <div>
      <Header />

      <div className="landing-page-container">
        {headerElement ?? <SolarReport title={title} />}

        <WithDefault element={mapElement} fallback={mapFallback} />
        <WithDefault element={checkRoofElement} fallback={checkRoofFallback} />
        <WithDefault element={whatYoullGetElement} fallback={whatYoullGetFallback} />
        <WithDefault element={whyReportElement} fallback={whyReportFallback} />

        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
