import React from 'react';
import { H1, H2 } from '../../../components/header-tag';
import Hero from '../../../components/hero';
import { RoofHeaderImage } from '../../../components/images';
import Base from '../../../pages/home-page/home-page-v2';
import Reasons from './reasons';
import RoofTypes from './roof-types';
import CTA from '../../../components/cta';

// TODO Replace hero image with hero image from roofing assets /tyler
export default function HomePage(): React.ReactElement {
  return (
    <Base headerProps={{ simple: true }} footerProps={{ disclaimer: null }}>
      <Hero
        title={<H2>Save On</H2>}
        subtitle={(
          <H1>
            Roof
            <br />
            Renovations
          </H1>
        )}
        content={(
          <p>
            Get quotes from quality service providers for free - and without any payment information
            required.
          </p>
        )}
        image={<RoofHeaderImage />}
      />

      <Reasons />

      <CTA />

      <RoofTypes />
    </Base>
  );
}
