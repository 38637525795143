import React, { useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useStoreon } from 'storeon/react';
import { ImSpinner2 } from 'react-icons/im';
import { postFormData, getStateAndCity } from '../../api/solar-data';
import AnalysisHeader from '../../components/analysis-header/analysis-header';
import Card from '../../components/card/card';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import MapComponent from '../../components/map/map';
import './analysis-page.scss';
import GooglePlaceAutocomplete from '../../components/google-places/google-places-autocomplete';
import { Place } from '../../components/google-places/google-places-autocomplete.types';

function NotReached({ data }: any): JSX.Element {
  const state = data.address.administrative_area;
  const city = data.address.locality;
  let url = '';
  if (state && city) {
    url = `https://homeotter.com/state/${state}/city/${city}`;
  } else if (state) {
    url = `https://homeotter.com/state/${state}`;
  } else {
    url = 'https://homeotter.com/';
  }
  return (
    <div className="unreached">
      <h3>
        {
        `Sorry, Project Sunroof hasn't reached that address yet.
        Check out why you consider going solar in ${city}`
        }
      </h3>
      <a href={url} target="_blank" rel="noreferrer">
        <button className="btn-primary" type="button">Get Report</button>
      </a>
    </div>
  );
}

function AnalysisPage(): JSX.Element {
  const { solarData, dispatch } = useStoreon('solarData');
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useStoreon('userData');
  const [place, setPlace] = useState<Place|undefined>(undefined);
  const [blur, setBlur] = useState('none');
  const [showModal, setShowModal] = useState(false);
  const analysis = useRef<HTMLDivElement>(null);
  const [allowToPost, setAllowToPost] = useState(true);
  const allowPostRef = useRef(allowToPost);

  // NOTE [2023-06-12] Added as a stop-gap measure to capture lost leads. This should not be left
  //  here long term. /tyler
  // useEffect(() => {
  //   fetch('https://hooks.zapier.com/hooks/catch/2811046/3hta00c/silent', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       ...userData,
  //       is_gm_internal: userData.email_address === 'gmtesting@gmail.com'
  //         || window.location.search.indexOf('gmtesting=1') !== -1,
  //     }),
  //   });
  // }, []);

  useEffect(() => {
    if (userData) {
      document.onvisibilitychange = () => {
        if (document.visibilityState === 'hidden') {
          // In this case we move the city & state filling to the backend side. If the page is
          // closed we have only one attempt (one network call) to try to send the data to
          // Jangl, before the page context is destroyed.
          if (allowPostRef.current) {
            userData.state = '';
            userData.city = '';
            userData.address = solarData.address.original_address;
            postFormData(userData);
            allowPostRef.current = false;
            setAllowToPost(false);
          }
        }
      };
      if (solarData.address.is_building) {
        setShowModal(false);
        setBlur('none');
        if (allowPostRef.current) {
          postFormData(userData);
          setAllowToPost(false);
          allowPostRef.current = false;
        }
      } else {
        setShowModal(true);
        setBlur('blur(4px)');
        setTimeout(() => {
          if (allowPostRef.current) {
            getStateAndCity(userData.zip_code).then((resp: any) => {
              userData.state = resp.state;
              userData.city = resp.city;
              userData.address = solarData.address.original_address;
              postFormData(userData);
              allowPostRef.current = false;
              setAllowToPost(false);
            });
          }
        }, 30000, allowPostRef);
      }
    }
  }, []);

  const handleOnClick = () => {
    if (place) {
      if (place.administrative_area && place.country
          && place.locality && place.location && place.route
          && place.street_number && place.zipCode && place.zipCode === userData.zip_code) {
        setIsLoading(true);
        userData.address = `${place?.street_number} ${place?.route}`;
        userData.city = place.administrative_area;
        userData.state = place.country;
        if (allowToPost) {
          allowPostRef.current = false;
          postFormData(userData);
        }
        dispatch('getSolarData', {
          latitude: place.location.lat,
          longitude: place.location.lng,
          firstname: userData.first_name,
          lastname: userData.last_name,
          bill: userData.monthly_bill,
          address: place,
          cb: () => {
            setShowModal(false);
            setBlur('none');
          },
        });
      }
    }
  };

  return (
    <div>
      {showModal && (
      <div className="modal-content">
        <div className="modal">
          <div className="modal-form">
            <h2>Sorry we could not find your address</h2>
            <h3>
              Please find your address on the Google Address picker to display your solar report
            </h3>
            <div className="form-body-content">
              <GooglePlaceAutocomplete onPlaceChanged={setPlace} />
            </div>
            {isLoading ? <div className="spinner"><ImSpinner2 /></div> : (
              <button onClick={() => handleOnClick()} className="btn-primary" type="button" style={{ marginTop: 30 }}>Get report</button>
            )}
          </div>
        </div>
      </div>
      )}
      <div className="analysis-page-main" ref={analysis} style={{ filter: `${blur}` }}>
        <Header />
        {solarData.usable_sunlight_per_year ? (
          <div className="analysis-page-container">
            <AnalysisHeader name={solarData.firstname} address={solarData.address} />
            <MapComponent
              hours={solarData.usable_sunlight_per_year}
              sq={solarData.available_sq_feet}
              savings={solarData.savings.buying}
              latitude={solarData.latitude}
              longitude={solarData.longitude}
            />
            <div className="anlysis-cards">
              <Card title="Your Recommended Solar Installation Size">
                <div className="size-1 secondary-color space-1">
                  {`This size will cover about ${solarData.solar_installation_size.size_cover}% of your
                electricity usage. Solar
                installations are sized in kilowatts (kW).`}
                </div>
                <div className="size-3 primary-color">
                  {solarData.solar_installation_size.kilowatts}
                  {' '}
                  kW
                </div>
              </Card>
              <Card title="Your Potential Environment Impact">
                <div className="size-1 secondary-color space-1">
                  Estimated annual environmental impact of the recommended solar installation size
                </div>
                <div className="size-3 primary-color">
                  {solarData.solar_installation_size.carbon}
                  {' '}
                  metric tons
                </div>
              </Card>
              <Card title="State and Federal Incentives">
                <div className="size-1 space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.state_and_federal_incentives.federal_investment}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      Federal Investment Tax Credit (ITC).
                    </span>
                  </span>
                </div>
                <div className="size-1 space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={
                          solarData.state_and_federal_incentives.state_tax_credit
                            ? solarData.state_and_federal_incentives.state_tax_credit
                            : 0
                        }
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      State Tax Credit.
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={
                          solarData.state_and_federal_incentives.solar_renewable_energy_credits
                            ? solarData.state_and_federal_incentives.solar_renewable_energy_credits
                            : 0
                        }
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      Solar Renewable Energy Credits (SRECs).
                    </span>
                  </span>
                </div>
              </Card>
              <Card
                tooltip="Buying solar panels will have the highest upfront cost, but it will allow you to benefit
                the most from the power of these panels. Benefits include added home value,
                ability to take advantage of tax credits and incentives,
                and not having to pay the bank or a third party to use the panels each month."
                title="Buying Solar"
              >
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="secondary-color">
                      Up-front Cost After Incentives
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.buying_solar.up_front_cost_after_incentives}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.buying_solar.years_until_payback}
                        thousandSeparator=","
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      Years Until Payback.
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.buying_solar.year_benefits}
                        thousandSeparator=","
                        displayType="text"
                        prefix="$"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      20 -Year Benefits.
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span className="primary-color under-lined">
                    <NumberFormat
                      value={solarData.buying_solar.year_savings}
                      thousandSeparator=","
                      prefix="$"
                      displayType="text"
                    />
                  </span>
                  <span className="secondary-color">
                    {' '}
                    Total 20 -Year Savings.
                  </span>
                </div>
              </Card>
              <Card
                tooltip="If you would like to get started with solar without the large upfront cost,
                leasing is a great option. When you lease solar panels you will pay a solar company a
                fixed monthly amount in order to use the energy generated by these solar panels.
                Typical leasing contracts for solar panels last 20-25 years."
                title="Leasing Solar"
              >
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="secondary-color">
                      Up-front Cost After Incentives
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.leasing_solar.up_front_cost}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="secondary-color">
                      Average Annual Savings
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.leasing_solar.average_annual_savings}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.leasing_solar.year_savings}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      Total 20 -Year Savings
                    </span>
                  </span>
                </div>
              </Card>
              <Card
                tooltip="Another option to reduce the initial cost of solar is to take out a loan. Most loan options
                require a minimum of 640 credit score, but the program Property Assessed Clean Energy looks
                to see if you have a certain percent of equality in your home rather than credit score.
                When you take out a loan something to consider is the interest rate as that will
                increase your monthly amount over time."
                title="Loaning Solar"
              >
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="secondary-color">
                      Up-front Cost After Incentives
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.loaning_solar.up_front_cost}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="secondary-color">
                      Average Annual Savings
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.loaning_solar.average_annual_savings}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                  </span>
                </div>
                <div className="size-1 secondary-color space-2">
                  <span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.loaning_solar.year_savings}
                        thousandSeparator=","
                        prefix="$"
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      Total 20 -Year Savings
                    </span>
                  </span>
                </div>
              </Card>
              <Card title="Estimated Job Size">
                <div className="size-3 no-title">
                  <div className="size-2 secondary-color space-1">
                    Up-Front Cost After Installation
                  </div>
                  <div className="size-3 primary-color">
                    <NumberFormat
                      value={solarData.buying_solar.up_front_cost}
                      thousandSeparator=","
                      prefix="$"
                      displayType="text"
                    />
                    {' '}
                  </div>
                </div>
              </Card>
              <Card title="Quick Facts">
                <div className="size-4 secondary-color space-2">
                  <span>
                    <span className="secondary-color">
                      {'Policies & Incentives ->'}
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      <NumberFormat
                        value={solarData.quick_facts.policies_and_incentives}
                        thousandSeparator=","
                        displayType="text"
                      />
                    </span>
                    <span className="secondary-color">
                      {' '}
                      in the state of
                      {' '}
                    </span>
                    <span className="primary-color under-lined">
                      {solarData.quick_facts.state}
                    </span>
                  </span>
                </div>
                <div className="size-4 secondary-color space-2">
                  <span className="">
                    Number of Homes in
                    {' '}
                  </span>
                  <span className="primary-color under-lined">
                    {`${solarData.quick_facts.state}`}
                  </span>
                  {' -> '}
                  <span className="primary-color under-lined">
                    <NumberFormat
                      value={solarData.quick_facts.state_homes_powered_by_solar}
                      thousandSeparator=","
                      displayType="text"
                    />
                  </span>
                  <span className="secondary-color">
                    {' '}
                    Homes
                  </span>
                </div>
                <div className="size-4 secondary-color space-2">
                  <span className="">
                    {'Growth Projection ->'}
                    {' '}
                  </span>
                  <span className="primary-color under-lined">
                    <NumberFormat
                      value={solarData.quick_facts.grow_projection}
                      thousandSeparator=","
                      displayType="text"
                    />
                  </span>
                  <span className="secondary-color">
                    {' '}
                    MW over the next 5 years
                  </span>
                </div>
                <div className="size-4 secondary-color space-2">
                  <span className="">
                    {'Electricity Rate ->'}
                    {' '}
                  </span>
                  <span className="primary-color under-lined">
                    <NumberFormat
                      value={solarData.quick_facts.electricity_rate}
                      thousandSeparator=","
                      decimalScale={3}
                      displayType="text"
                      prefix="$"
                    />
                  </span>
                  <span className="secondary-color">
                    {' '}
                    per Kilowatt Hour
                  </span>
                </div>
                <div className="size-4 secondary-color space-2">
                  <span className="">
                    {'Cost per Watt ->'}
                    {' '}
                  </span>
                  <span className="primary-color under-lined">
                    <NumberFormat
                      value={solarData.quick_facts.cost_per_watt}
                      thousandSeparator=","
                      displayType="text"
                      decimalScale={3}
                      prefix="$"
                    />
                  </span>
                </div>
              </Card>
            </div>
            <Footer />
          </div>
        ) : (
          <NotReached data={solarData} />
        )}
      </div>
    </div>
  );
}

export default AnalysisPage;
