import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function QuestionItem({ title, children }: Props): JSX.Element {
  return (
    <div className="question-container">
      <div className="question-title">
        <h2>{ title }</h2>
      </div>

      <div className="question-content">
        { children }
      </div>
    </div>
  );
}
