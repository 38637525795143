import axios from 'axios';

const API_HOST = process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:3333/api` : `https://${window.location.hostname}/api`;

export const getSolarData = async (latitude: number, longitude: number, bill: number) => {
  // use port 3001 in development
  const resp = await axios.get(`${API_HOST}/solar_data?latitude=${latitude}&longitude=${longitude}&monthly_bill=${bill}`, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return resp.data;
};

export const validateRealPhoneNumber = async (phone: string) => {
  const resp = await axios.post(`${API_HOST}/validate_phone_number`, { phone }, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return resp.data;
};

// twillio code verification
export const validatePhoneNumber = async (phone: any) => {
  const resp = await axios.post(`${API_HOST}/start_verification`, { phone }, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return resp.data;
};

export const validatePhoneCode = async (phone: string, code: string) => {
  const resp = await axios.post(`${API_HOST}/check_verification`, { phone, code }, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return resp.data;
};

export function isUtilityProviderResponseValid(message: string): boolean {
  return message !== 'Utility Providers not found';
}

export const getUtilityProvider = async (zipCode: string) => {
  const resp = await axios.get(`${API_HOST}/utility_provider?zip_code=${zipCode}`, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  return resp.data;
};

export const postFormData = async (formData: any) => {
  console.info(formData);
  const resp = await fetch(`${API_HOST}/capture_data`, {
    method: 'POST',
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    keepalive: true,
    body: JSON.stringify(formData),
  });
  return resp.json();
};

export const postWindowsFormData = async (formData: any) => {
  const resp = await fetch(`${API_HOST}/capture_window_data`, {
    method: 'POST',
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    keepalive: true,
    body: JSON.stringify(formData),
  });
  return resp.json();
};

export const postBathroomFormData = async (formData: any) => {
  const resp = await fetch(`${API_HOST}/capture_bathroom_data`, {
    method: 'POST',
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    keepalive: true,
    body: JSON.stringify(formData),
  });
  return resp.json();
};

export const getPartners = async () => {
  const resp = await axios.get(`${API_HOST}/partners`, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      accept: 'application/json',
    },
  });
  return resp.data;
};

export const unsubscribe = async (email: string, source: string, code: string) => {
  const resp = await axios.post(`${API_HOST}/unsubscribe`, { source, email, code }, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      accept: 'application/json',
    },
  });
  return resp.data;
};

export const registerOptOut = async (email: string, phone: string, state: string) => {
  const resp = await axios.post(`${API_HOST}/register-opt-out`, { email, phone, state }, {
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      accept: 'application/json',
    },
  });
  return resp.data;
};

export const getStateAndCity = async (zipCode: string) => {
  const resp = await fetch(`${API_HOST}/state_and_city_by_zip?zip_code=${zipCode}`, {
    method: 'GET',
    headers: {
      'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
      accept: 'application/json',
    },
    keepalive: true,
  });
  return resp.json();
};
