import React, { useEffect, useState } from 'react';

import './index.scss';
import GetStartedRoofForm from './get-started-form';

function RoofingV2(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible2(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible3(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible4(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible5(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Append the Google Fonts link tags to the head
    const robotoCondensedLink = document.createElement('link');
    robotoCondensedLink.href = 'https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap';
    robotoCondensedLink.rel = 'stylesheet';

    const robotoLink = document.createElement('link');
    robotoLink.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap';
    robotoLink.rel = 'stylesheet';

    document.head.appendChild(robotoCondensedLink);
    document.head.appendChild(robotoLink);

    return () => {
      // Clean up the links when the component is unmounted
      document.head.removeChild(robotoCondensedLink);
      document.head.removeChild(robotoLink);
    };
  }, []);

  return (
    <div className="roofing-v2 roof-container" id="formId">
      {/* Navbar/  Header */}
      <nav className="image-container" style={{ justifyContent: 'center' }}>
        <img
          className="image"
          src="https://lh3.googleusercontent.com/NITjFrINYC5lYtolMccc3GkEHsVClLOnH4TkwjmfFxUqpGSA9OJkfjnJzgSR4fB3ZF5wa8TxspDg_WKjOaVAO2gTcpWVBJFBivE=w264"
          alt="logo"
          style={{ width: '190px' }}
        />
      </nav>
      {/* Get Started Section */}
      <section
        id="get-started"
        className="has-background-image section"
        style={{
          backgroundImage: 'url("https://lh3.googleusercontent.com/zmRIyfR6DJt-4S7QLOEB5Tt3Mdb2jLpo9_3oEd6SU1wh8Q8Dd3mz_wv0zaeuRD8AXgS7wwXO7-XRq51taNbNcG8VqE8SNxWs8f8=w1831")',
        }}
      >
        <div className="linear-gradient-overlay">
          <div
            className="hero-with-form-container"
            style={{
              minHeight: '600px',
            }}
          >
            <div
              className="hero-content lp-text-react lp-headline-react"
              data-widget-type="LpTextReact"
            >
              <p
                className="headline"
                style={{
                  marginTop: 0,
                }}
              >
                Living With A Damaged Roof?
              </p>
              <p className="description">
                See all the best deals & get free quotes
                from trusted, high quality roofing repair &
                replacement specialists near you. No payment
                info required.
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <GetStartedRoofForm pageURL="https://homeotter.com/roofingV2" />
            </div>
          </div>
        </div>
      </section>
      {/* Main Content Section */}
      <section className="main-container">
        <div className="text-center">
          <p className="font-scale-9 heading-roof">
            <span style={{ color: 'rgba(19, 20, 28, 1)' }}>
              <strong className="headline">
                Want the best price from local roofing contractors you can trust?
              </strong>
            </span>
          </p>
        </div>
        <hr className="horizontal-line" style={{ marginBottom: '80px' }} />
        <div style={{ width: '100%' }}>
          <div className="flex">
            <div style={{ backgroundColor: 'rgb(0, 106, 243)', width: '100%' }}>
              <div
                className={`image-animate-container ${
                  isVisible ? 'slide-in' : 'slide-out'
                }`}
                style={{ backgroundColor: 'white', width: '100%' }}
              >
                <div className="responsive">
                  <img
                    className="responsive-image"
                    src="https://lh3.googleusercontent.com/2X3HFm_rXcjloU_4yOBN13kr0gpgrrDz2PXwnJ5jTeADb4K2CLt_8edRYLJSqGF0mlq7ztWqf1pbjLs7Yh3WafjNjefknOQ-Gw=w522"
                    alt="Descriptive Alt Text"
                  />
                  <div className="home-otter-widget">
                    <div className="home-otter-text">
                      <p className="intro-text">
                        <span className="highlighted-text">
                          <strong>At HomeOtter</strong>
                        </span>
                        <span className="regular-text">
                          , we’ve pioneered an efficient online tool to connect
                          you with top-rated roofing contractors in your area.
                          It’s a simple, quick, and hassle-free experience
                          tailored just for you.
                        </span>
                      </p>
                      <p className="steps-text">
                        <span className="step">
                          <span className="step-number">Step 1. </span>
                          Click on the button below.
                        </span>
                        <br />
                        <span className="step">
                          <span className="step-number">Step 2. </span>
                          Enter your zip code.
                        </span>
                        <br />
                        <span className="step">
                          <span className="step-number">Step 3. </span>
                          See top-rated contractors in your area and state.
                        </span>
                        <br />
                        <span className="step">
                          <span className="step-number">Step 4. </span>
                          Take advantage of the best local deals and savings!
                        </span>
                      </p>
                      <div className="get-quote-button-wrapper">
                        <a
                          href="#get-started"
                          rel="noopener noreferrer"
                          className="get-quote-button"
                        >
                          GET FREE QUOTE &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="horizontal-line" />
        <div style={{ width: '100%' }}>
          <h1
            className="heading-roof zoom-text "
            style={{ textAlign: 'center', marginBottom: '34px' }}
          >
            <span
              style={{
                color: 'rgb(0, 106, 243)',
                fontSize: '3.5rem',
              }}
            >
              Why
            </span>
            <span> </span>
            <span style={{ fontSize: '3.5rem', marginBottom: '20px' }}>
              You Need A New Roof?
            </span>
          </h1>
          <div style={{ width: '100%', backgroundColor: 'rgb(0, 106, 243)' }}>
            <div
              className={`image-animate-container ${
                isVisible2 ? 'slide-in' : 'slide-out'
              }`}
              style={{ backgroundColor: 'white', width: '100%' }}
            >
              <div className="responsive">
                <img
                  className="responsive-image"
                  src="https://lh3.googleusercontent.com/DV_0ofZqvktkRuEuk63bLH_VwLH4KVTs76tafRq0aEpG40OlLeiRkaQkZk3rz0yVzQ18zYyFxxq-da3-GamRBLljE9SzXvDc5dk=w533"
                  data-src="https://lh3.googleusercontent.com/2X3HFm_rXcjloU_4yOBN13kr0gpgrrDz2PXwnJ5jTeADb4K2CLt_8edRYLJSqGF0mlq7ztWqf1pbjLs7Yh3WafjNjefknOQ-Gw"
                  data-image-upload-source="builder3"
                  alt="Descriptive Alt Text"
                />
                <div className="home-otter-widget">
                  <div className="home-otter-text">
                    <h2>
                      Void future maintenance &
                      {' '}
                      <span style={{ color: 'rgba(0, 106, 243, 1)' }}>
                        repairs
                      </span>
                    </h2>
                    <p className="intro-text">
                      <span className="regular-text">
                        Living with a damaged roof often leads to significant
                        financial costs further down the road. Your location,
                        weather, and environment can drastically impact your
                        roof’s performance, so don’t wait until it’s too late.
                        Make the smart decision and give yourself that extra
                        layer of protection today.
                      </span>
                    </p>
                    <div className="get-quote-button-wrapper">
                      <a
                        href="#get-started"
                        rel="noopener noreferrer"
                        className="get-quote-button"
                      >
                        GET FREE QUOTE &rarr;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: 'rgb(0, 106, 243)', width: '100%' }}>
            <div
              className={`image-animate-container ${
                isVisible3 ? 'slide-in' : 'slide-right'
              }`}
              style={{
                backgroundColor: 'white',
                width: '100%',
                marginTop: '20px',
                marginLeft: '0',
              }}
            >
              <div className="responsive-reverse">
                <div className="home-otter-widget">
                  <div
                    className="home-otter-text"
                    style={{ paddingRight: '30px' }}
                  >
                    <h2>
                      A new roof =
                      {' '}
                      <span style={{ color: 'rgba(0, 106, 243, 1)' }}>
                        a smart investment
                      </span>
                    </h2>
                    <p className="intro-text">
                      <span className="regular-text">
                        New, modern roofing technology helps homeowners conserve
                        energy and save money on their bills. Couple this with
                        the fact that the cost of many new roofs are covered by
                        your insurance, you’ll start earning back on your
                        investment from day 1!
                      </span>
                    </p>
                    <div className="get-quote-button-wrapper">
                      <a
                        href="#get-started"
                        rel="noopener noreferrer"
                        className="get-quote-button"
                      >
                        GET FREE QUOTE &rarr;
                      </a>
                    </div>
                  </div>
                </div>
                <img
                  className="responsive-image"
                  src="https://lh3.googleusercontent.com/WsKvYUev1Kam7HWmB1fyR1PXp-PijLfeA4xh3nJQ6cxDqd7ckbf77tYKrJrtjZlDhghVEwDrHX2y7ijy7BUvBUL0JqaWs2CugPQ=w533"
                  data-src="https://lh3.googleusercontent.com/2X3HFm_rXcjloU_4yOBN13kr0gpgrrDz2PXwnJ5jTeADb4K2CLt_8edRYLJSqGF0mlq7ztWqf1pbjLs7Yh3WafjNjefknOQ-Gw"
                  data-image-upload-source="builder3"
                  alt="Descriptive Alt Text"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'rgb(0, 106, 243)',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <div
              className={`image-animate-container  ${
                isVisible4 ? 'slide-in' : 'slide-out'
              }`}
              style={{ backgroundColor: 'white', width: '100%' }}
            >
              <div className="responsive">
                <img
                  className="responsive-image"
                  src="https://lh3.googleusercontent.com/27GnD7AiIl-gu4GwcvnIqszH6xTR0b-EN7Hx08m8E-N3S9nua2K3CwEENthtoU08wCYTMlq1hXKnOxwC3GHBMKEfhQM-RtzXYZML=w522"
                  data-src="https://lh3.googleusercontent.com/2X3HFm_rXcjloU_4yOBN13kr0gpgrrDz2PXwnJ5jTeADb4K2CLt_8edRYLJSqGF0mlq7ztWqf1pbjLs7Yh3WafjNjefknOQ-Gw"
                  data-image-upload-source="builder3"
                  alt="Descriptive Alt Text"
                />
                <div className="home-otter-widget">
                  <div className="home-otter-text">
                    <h2>
                      Boost the value of your
                      {' '}
                      <span style={{ color: 'rgba(0, 106, 243, 1)' }}>
                        home
                      </span>
                    </h2>
                    <p
                      className="intro-text"
                      style={{ fontSize: '1.2rem', textAlign: 'left' }}
                    >
                      <span className="regular-text">
                        On average, a new roof will increase your home’s value
                        by more than $15,000. And in a 2019 survey from the
                        National Association of REALTORS, 33% of realtors found
                        that a new roof helped them close the sale of a home.
                      </span>
                    </p>
                    <div className="get-quote-button-wrapper">
                      <a
                        href="#get-started"
                        rel="noopener noreferrer"
                        className="get-quote-button"
                      >
                        GET FREE QUOTE &rarr;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="horizontal-line" />
        <div
          className="custom-container"
          style={{
            textAlign: 'center',
            fontSize: '1.7rem',
          }}
        >
          <div>
            <h1
              className="custom-heading"
              style={{ color: 'rgba(19, 20, 28, 1)' }}
            >
              Our Customers Love Us!
            </h1>
            <p className="custom">
              “Absolutely love our new roof! Great customer service from start
              to finish. Everyone I spoke to was very knowledgeable and
              friendly. Product is excellent with service to match. We will
              definitely recommend them to others in the future.” -
              <strong> Blake W. (Memphis, TN)</strong>
            </p>
            <p className="custom" style={{ color: 'rgba(19, 20, 28, 1)' }}>
              “The sales reps were not pushy. Very friendly and helped us with
              financing. The workers were amazing. They worked fast and did a
              great job. We are very pleased.” -
              <strong> Diane G. (Harrisburg, PA)</strong>
            </p>
            <p className="custom" style={{ color: 'rgba(19, 20, 28, 1)' }}>
              “The sales people took the time to look over the roof then to
              explain what needed done, and what would be done. The work crew
              was efficient and did a quality job. They also cleaned up around
              the house. Good job all around.” -
              <strong> Kevin K. (Toledo, OH)</strong>
            </p>
          </div>
        </div>
        <hr className="horizontal-line" />
        <div style={{ width: '100%' }}>
          <div
            className="responsive"
            style={{ backgroundColor: 'rgb(0, 106, 243)', width: '100%' }}
          >
            <div
              className={`image-animate-container  ${
                isVisible5 ? 'slide-in' : 'slide-right'
              }`}
              style={{ backgroundColor: 'white', width: '100%' }}
            >
              <div
                className="replacing"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  flexDirection: 'column',
                }}
              >
                <h1
                  className="font-scale-10"
                  style={{ textAlign: 'center', color: 'rgb(0, 0, 0)' }}
                >
                  <span
                    className="font-scale-10"
                    style={{
                      color: 'rgba(0, 106, 243, 1)',
                    }}
                  >
                    Replacing
                    {' '}
                  </span>
                  <span
                    className="font-scale-10"
                    style={{ color: 'rgb(19, 20, 28)' }}
                  >
                    Your Roof
                  </span>
                </h1>
                <div>
                  <div
                    className={`image-animate-container ${
                      isVisible5 ? 'slide-in' : 'slide-right'
                    }`}
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '1.4rem',
                    }}
                  >
                    <p
                      className="font-scale-10 "
                      style={{
                        lineHeight: '1.75',
                        textAlign: 'justify',
                        marginBottom: '60px',
                        color: 'rgb(0, 0, 0)',
                        fontSize: '20px',
                      }}
                    >
                      Is essential to maintaining the integrity and value of
                      your home. Over time, roofs deteriorate due to weather
                      conditions, age, and wear, leading to potential leaks and
                      structural issues. Investing in a new roof enhances your
                      home protection, energy efficiency, and overall
                      appearance. Let us assist you in finding the best roofing
                      solution tailored to your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA Section */}
      <section
        className="has-background-image section"
        style={{
          backgroundImage: 'url("https://lh3.googleusercontent.com/aa5xbft5nkJILWcCx5z42rwggXoegU10bjLCWErwq7kdtDU5OgT0V6r_UEs1nznw_IwIk_9YnvBrCjs9rLM8Mzm6_Odrgstst-Y=w1831")',
          // eslint-disable-next-line object-property-newline
          width: '100%',
          height: '644px',
        }}
      >
        <div className="linear-gradient-overlay">
          <div
            className="cta-container"
          >
            <div
              style={{
                maxWidth: 'max-content',
              }}
            >
              <div
                className="lp-text-react lp-headline-react"
                data-widget-type="LpTextReact"
              >
                <p className="headline">
                  Get A Free Quote Today
                </p>
              </div>
              <div
                className="lp-button-react-wrapper css-163w4ef"
                data-widget-type="LpButtonReact"
                style={{ marginTop: '50px' }}
              >
                <a
                  data-widget-link="true"
                  href="#get-started"
                  rel="noopener noreferrer"
                  data-link-type="external"
                  className="lp-button-react"
                >
                  GET STARTED →
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Section */}
      <section
        style={{ textAlign: 'center', marginTop: '30px', marginBottom: '50px' }}
      >
        <p
          style={{
            fontSize: '14px',
            color: 'rgba(19,20,28,0.8)',
            margin: '0px',
          }}
        >
          ©2024 HomeOtter. All rights reserved.
          {' '}
        </p>
        <a
          href="https://homeotter.com/roofingV1/privacy"
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: '14px',
            color: 'rgba(19,20,28,0.8)',
            lineHeight: '1.5',
          }}
        >
          Privacy Policy.
        </a>
      </section>
    </div>
  );
}

export default RoofingV2;
