import React from 'react';

function NotFoundPage(): JSX.Element {
  return (
    <>
      Page not found
    </>
  );
}

export default NotFoundPage;
