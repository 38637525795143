import React from 'react';
import BaseFrequentQuestions from '../../../components/frequent-questions/frequent-questions';
import QuestionItem from '../../../components/frequent-questions/question-item';

export default function FrequentQuestions(): JSX.Element {
  return (
    <BaseFrequentQuestions
      title={(
        <p>
          Here are some of the most common questions we hear from people who are considering solar
          panels. Our Solar Report was designed to give you the answers you need based on your
          unique situation.
        </p>
      )}
    >
      <QuestionItem title="How much are solar panels?">
        <p>
          The price for solar power ranges depending on how many panels are necessary
          for your roof, but luckily, the price of solar panels has decreased over the
          years. When considering solar panels for your home, you need to consider the
          price of the panels themselves and the installation price. Our report uses
          Google Project Sunroof and Google Earth to tell you the estimated costs for
          your roof, based on your roof shade, location, and more.
        </p>
      </QuestionItem>

      <QuestionItem title="Is my house good for solar?">
        <p>
          You may be wondering if your house qualifies for solar energy and what kind
          of return on your investment you can expect. Solar panels for home or
          residential areas are a great way to lower your electric bill and help the
          environment at the same time. In order to see if your roof gets enough sun,
          fill out our quick 2-minute questionnaire and we’ll calculate this
          information for you.
        </p>
      </QuestionItem>

      <QuestionItem title="What incentives are available in my area?">
        <p>
          There are a variety of state and federal incentives available to homeowners
          who are looking to invest in solar panels. Our report will show you what
          incentives are available in your area as well as how these incentives could
          lower your overall investment.
        </p>
      </QuestionItem>

      <QuestionItem title="How many solar panels do I need?">
        <p>
          One of the contributing factors to the cost of your system will be how many
          solar panels you’ll need to power your house. Every roof is a different
          size, so it’s important to get an estimate on the number of panels you need.
          That way you won’t under or over-buy. Our solar panel calculator makes it
          easy to see what makes the most sense for your individual needs.
        </p>
      </QuestionItem>
    </BaseFrequentQuestions>
  );
}
