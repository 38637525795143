import React from 'react';
import './address-detail.scss';

interface AddressDetailProps {
    icon: any;
    title: any;
    content: any;
}

function AddressDetail({ icon, title, content }: AddressDetailProps): JSX.Element {
  console.log(title);
  return (
    <div className="address-detail">
      <div className="icon">
        <img className="responsive-image" src={icon} alt="solar-house" />
      </div>
      <div className="address-container">
        <div className="title">
          {title}
        </div>
        <div className="content">
          {content}
        </div>
      </div>
    </div>
  );
}

export default AddressDetail;
