import axios from 'axios';
import { GeneralResponseModel } from '../model/ResponseModel';

const API_HOST = process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:3333/api` : `https://${window.location.hostname}/api`;

export const getPartnersThumbtack = async (category:string, zipCode: string):
Promise<GeneralResponseModel<any>> => {
  try {
    const response = await axios.get(`${API_HOST}/partners/thumbtack?category=${category}&zip_code=${zipCode}`, {
      headers: {
        'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
        accept: 'application/json',
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    console.error(`Unexpected status code: ${response.status}`);
    throw response.status;
  } catch (error) {
    console.error('Error fetching thumbtack list:', error);
    throw error;
  }
};
