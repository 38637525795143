/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable quotes */

/* eslint-disable */

import React, { useCallback, useEffect, useState } from "react";
import "./splash1-page.scss";
import { Link, useNavigate } from "react-router-dom";
import { useStoreon } from "storeon/react";
import axios from "axios";

import {
  showPrice,
  namesOnNext,
} from "../get-started-page/utils";
import useScript from "../../hooks/useScript";
import Option from "../../components/option/option";
import {
  homeTypes,
  monthlyBills,
  sun,
} from "../get-started-page/get-started-page.constants";
import {
  getUtilityProvider,
  validateRealPhoneNumber,
} from "../../api/solar-data";
import { getGooglePlace } from '../../api/location-api';
import FormCards from "../../components/form-card/form-cards";
import pushDataLyer from "../../utils/gtm";

const SURVEY_LENGTH = 10;
const version = "4";

export function Splash1(): JSX.Element {
  const [zipCode, setZipCode] = useState<string>("");
  const params = new URLSearchParams(window.location.search).toString();
  const { solarData, dispatch } = useStoreon("solarData");
  const [step, setStep] = useState(0);
  const [place, setPlace] = useState<string | undefined>(undefined);
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [address, setAddress] = useState<any>("");
  const [homeType, setHomeType] = useState<string | undefined>("Select One");
  const [monthlyBill, setMonthlyBill] = useState<string | undefined>(undefined);
  const [sunAmount, setSunAmount] = useState<string | undefined>(undefined);
  const [rent, setRent] = useState<string | undefined>(undefined);
  const [utilityProviders, setUtilityProviders] = useState<
    string[] | undefined
  >(undefined);
  const [utilityProvider, setUtilityProvider] = useState<string | undefined>(
    undefined
  );
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string>("");
  const [anuraFraud, setAnura] = useState("");
  const [leadIdCode, setLeadIdCode] = useState("");
  const [GCLID, setGCLID] = useState("");
  const [FBID, setFBID] = useState("");
  const [sourceID, setSourceID] = useState("");
  const [offerID, setOfferID] = useState("");
  const [token, setToken] = useState("");
  const [trustedFormCertURL, setTrustedFormCertURL] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const [validateRealPhone, setValidateRealPhoneError] = useState<
    boolean | undefined
  >(false);
  const [zipError, setZipError] = useState("");
  const [nameError, setNameError] = useState("");

  const navigate = useNavigate();

  const [inputs] = useState<any>(
    <>
      <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
      <input id="offer_id" name="offer_id" type="hidden" value="" />
      <input id="oid" name="oid" type="hidden" value="" />
      <input id="source_id" name="source_id" type="hidden" value="" />
      <input id="sub4" name="sub4" type="hidden" value="" />
      <input
        id="authorization_token"
        name="authorization_token"
        type="hidden"
        value=""
      />
      <input id="anura_fraud" name="anura_fraud" type="hidden" value="" />
      <input type="hidden" id="gclid_field" name="gclid_field" value="" />
      <label hidden>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        {`By submitting this form, I am providing The Home Otter and one or more of its partners, 
          affiliates, or their agents (Matched Companies) my electronic signature giving prior 
          express written consent to be contacted and to receive telemarketing calls or texts 
          (including by prerecorded messages or using an autodialer or automated means) regarding 
          related products or services at the number and/or e-mail address provided, even 
          if the number provided is on a corporate, state, or national Do Not Call list. 
          I understand that my consent is not required to receive quotes or make a purchase, 
          I can revoke my consent at any time and I represent that I am at least 18 years 
          old and agree to be bound by Terms of Use and Privacy Policy.`}
      </label>
    </>
  );

  function CollectHiddenInputs() {
    setAnura(
      (document.getElementById("anura_fraud") as HTMLInputElement).value
    );
    setToken(
      (document.getElementById("authorization_token") as HTMLInputElement).value
    );
    setLeadIdCode(
      (document.getElementById("leadid_token") as HTMLInputElement).value
    );
    setSourceID(
      (document.getElementById("source_id") as HTMLInputElement).value
    );
    if (offerID === "") {
      setOfferID(
        (document.getElementById("offer_id") as HTMLInputElement).value
      );
    }
    setTrustedFormCertURL(
      (document.getElementById("xxTrustedFormCertUrl_0") as HTMLInputElement)
        .value
    );
    setFBID(
      (document.getElementById("facbook_field") as HTMLInputElement).value
    );
    setGCLID(
      (document.getElementById("gclid_field") as HTMLInputElement).value
    );
    setUserAgent("");
  }

  const onNext = useCallback(() => {
    if (step < SURVEY_LENGTH) {
      pushDataLyer(step, version);
      setStep((prevStep) => prevStep + 1);
      CollectHiddenInputs();
    }
  }, [step, setStep]);

  const onBack = useCallback(() => {
    if (step > 0) setStep((prevStep) => prevStep - 1);
    setValidateRealPhoneError(false);
    setPhoneSubmitted(false);
  }, [step, setStep]);

  const [ip, setIP] = useState();
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);
  (window as any).dataLayer = (window as any).dataLayer || [];
  const onSubmit = useCallback(() => {
    dispatch("setUserData", {
      zip_code: zipCode,
      ip_address: ip,
      source_id: sourceID,
      offer_id: offerID,
      trusted_form_cert_url: trustedFormCertURL,
      user_agent: userAgent,
      landing_page_url: "https://homeotter.com/solarV3",
      monthly_bill: monthlyBill,
      own_rent: rent,
      home_type: homeType,
      sun: sunAmount,
      address: `${address.street_number} ${address.route}`,
      city: address.locality,
      state: address.administrative_area,
      electric_provider: utilityProvider,
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      anura: anuraFraud,
      lead_id_code: leadIdCode,
      authorization_token: token,
      gclid: GCLID,
      fbid: FBID,
      phone_number: phoneNumber,
    });
    dispatch("getSolarData", {
      latitude,
      longitude,
      firstname: firstName,
      lastname: lastName,
      bill: monthlyBill,
      address,
      cb: () => {
        navigate({ pathname: "/analysis", search: params });
      },
    });
    setSubmitted(true);
    pushDataLyer(9, version);
  }, [phoneNumber, dispatch]);

  useScript();

  const canProgressHomeType = () => {
    if (homeType === "Select One") {
      return false;
    }
    return true;
  };

  const handleSetMonthlyBill = (value: string) => {
    setMonthlyBill(value);
    (window as any).dataLayer.push({
      event: "monthly_bill_submit",
      value,
    });
    onNext();
  };

  const handleSetOwnRent = (value: string) => {
    setRent(value);
    (window as any).dataLayer.push({
      event: "rent_submit",
      value,
    });

    onNext();
  };

  const handleSunRoof = (value: string) => {
    setSunAmount(value);
    (window as any).dataLayer.push({
      event: "home_type_submit",
      value,
    });
    onNext();
  };

  const handleElectricProvider = (value: string) => {
    setUtilityProvider(value);
    (window as any).dataLayer.push({
      event: "electric_provider_submit",
      value,
    });

    onNext();
  };

  const utilityDropdown = (elements: any) => {
    elements.unshift("Choose from list");
    return elements;
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className="splash1-container"
      id="formId"
      style={{
        overflowY: "scroll",
        height: "100%",
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
      }}
    >
      <div className="image-container">
        <img
          className="image"
          src="https://lh3.googleusercontent.com/NITjFrINYC5lYtolMccc3GkEHsVClLOnH4TkwjmfFxUqpGSA9OJkfjnJzgSR4fB3ZF5wa8TxspDg_WKjOaVAO2gTcpWVBJFBivE=w264"
          alt="logo"
        />
      </div>
      <div className="section-container">
        <div className="section-box">
          <div className="section-text texts">
            <p className="text-style">
              See How Much You Could Save With Solar!
              <span className="arrow">→</span>
            </p>
          </div>
          <div className="section-zip">
            <div
              className="get-started-page-container"
              style={{ width: "100%" }}
            >
              <form>
                {inputs}
                <FormCards
                  canProgress={!!zipCode}
                  onNext={async () => {
                    if (zipCode) {
                      getUtilityProvider(zipCode).then((res: any) => {
                        if (res.message === "Utility Providers not found") {
                          setZipError("This zip code is invalid");
                        } else {
                          setZipError("");
                          setUtilityProviders(res.utility_providers);
                          (window as any).dataLayer.push({
                            event: "zip_code_submit",
                            zipCode,
                          });
                          onNext();
                        }
                      });
                    }
                  }}
                  onBack={onBack}
                  step={step}
                  index={0}
                  progressBar
                  allSteps={SURVEY_LENGTH - 1}
                  title="What is your zip code?"
                >
                  <div className="form-body-content">
                    <div className="name-form">
                      <div
                        className="email-error"
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                      >
                        {zipError}
                      </div>
                      <input
                        type="text"
                        placeholder="Zip Code"
                        onChange={(e) => setZipCode(e.target.value)}
                        style={{
                          width: "60vmin",
                          height: "50px",
                          backgroundColor: "#377fe6",
                          border: "none",
                          outline: "none",
                          marginTop: "20px",
                          opacity: "0.8",
                          marginBottom: "10px",
                          color: "white",
                          marginLeft: "0px",
                        }}
                      />
                    </div>
                  </div>
                </FormCards>
                <FormCards
                  canProgress={!!monthlyBill}
                  onNext={onNext}
                  onBack={onBack}
                  step={step}
                  index={1}
                  allSteps={SURVEY_LENGTH - 1}
                  title="Roughly how much do you pay for electricity each month?"
                >
                  <div className="form-body-content">
                    {monthlyBills.map((e: any, i) => (
                      <Option
                        selected={monthlyBill === e}
                        key={i}
                        onClick={() => {
                          handleSetMonthlyBill(e);
                        }}
                        value={showPrice(e)}
                      />
                    ))}
                  </div>
                </FormCards>
                <FormCards
                  canProgress={!!rent}
                  onNext={onNext}
                  onBack={onBack}
                  step={step}
                  index={2}
                  allSteps={SURVEY_LENGTH - 1}
                  title="DO YOU OWN OR RENT?"
                >
                  <div className="form-body-content">
                    <Option
                      selected={rent === "own"}
                      onClick={() => handleSetOwnRent("own")}
                      value="Own"
                    />
                    <Option
                      selected={rent === "rent"}
                      onClick={() => handleSetOwnRent("rent")}
                      value="Rent"
                    />
                  </div>
                </FormCards>
                <FormCards
                  canProgress={canProgressHomeType()}
                  onNext={onNext}
                  onBack={onBack}
                  step={step}
                  index={3}
                  allSteps={SURVEY_LENGTH - 1}
                  title="WHAT TYPE OF HOME DO YOU LIVE IN?"
                >
                  <div className="providers-card">
                    <div className="options">
                      <select
                        style={{
                          height: "30px",
                          width: "52%",
                          marginLeft: "24%",
                          marginRight: "24%",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                        className={`${
                          homeTypes.findIndex((e) => homeType === e) > 0
                            ? "selected"
                            : ""
                        }`}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setHomeType(e.target.value);
                        }}
                      >
                        {homeTypes.map((e) => (
                          <option key={e} value={e} selected={e === homeType}>
                            {e}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </FormCards>
                <FormCards
                  canProgress={!!sunAmount}
                  onNext={onNext}
                  onBack={onBack}
                  step={step}
                  index={4}
                  allSteps={SURVEY_LENGTH - 1}
                  title="HOW MUCH SUN DOES YOUR ROOF GET?"
                >
                  <div className="form-body-content">
                    {sun.map((e, i) => (
                      <Option
                        selected={sunAmount === e}
                        key={i}
                        onClick={() => {
                          handleSunRoof(e);
                        }}
                        value={e}
                      />
                    ))}
                  </div>
                </FormCards>
                <FormCards
                  canProgress={!!place}
                  onNext={async () => {
                    if (place) {
                      getGooglePlace(place, zipCode).then((resp: any) => {
                        if (resp.is_building) {
                          setLatitude(resp.latitude);
                          setLongitude(resp.longitude);
                        } else {
                          // set fixed lat, lng for show blur report
                          setLatitude("40.7346777");
                          setLongitude("-73.82374519999999");
                        }
                        setAddress({
                          street_number: resp.street_number,
                          route: resp.route,
                          administrative_area: resp.administrative_area_level_1,
                          zipCode: resp.postal_code,
                          country: resp.country,
                          locality: resp.locality,
                          is_building: resp.is_building,
                          original_address: resp.original_address,
                        });
                        (window as any).dataLayer.push({
                          event: "location_details_submit",
                          address,
                        });
                        onNext();
                      });
                    }
                  }}
                  onBack={onBack}
                  step={step}
                  index={5}
                  allSteps={SURVEY_LENGTH - 1}
                  title="WHAT IS THE ADDRESS YOU WANT THE SOLAR PANELS INSTALLED AT?"
                >
                  <div className="form-body-content">
                    <div className="name-form">
                      <input
                        type="text"
                        placeholder="Street Address"
                        onChange={(e) => setPlace(e.target.value)}
                        style={{
                          height: "20px",
                          width: "50vmin",
                          marginTop: "10px",
                          backgroundColor: "#377fe6",
                          border: "none",
                          outline: "none",
                          color: "white",
                          margin: "0",
                        }}
                      />
                      <p className="text-ex">Example: 123 Main Street, 01234</p>
                    </div>
                  </div>
                </FormCards>
                <FormCards
                  canProgress={
                    !!utilityProvider && utilityProvider !== "Choose from list"
                  }
                  onNext={onNext}
                  onBack={onBack}
                  step={step}
                  index={6}
                  allSteps={SURVEY_LENGTH - 1}
                  title="WHO IS YOUR ELECTRIC PROVIDER?"
                >
                  <div className="providers-card">
                    {utilityProviders ? (
                      <>
                        {utilityProviders && (
                          <>
                            <div
                              className={`provider-option ${
                                utilityProviders[0] === utilityProvider
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => {
                                handleElectricProvider(utilityProviders[0]);
                              }}
                            >
                              {utilityProviders[0]}
                            </div>
                            <div
                              className={`provider-option ${
                                utilityProviders[1] === utilityProvider
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => {
                                handleElectricProvider(utilityProviders[1]);
                              }}
                            >
                              {utilityProviders[1]}
                            </div>
                          </>
                        )}
                        <div className="options">
                          <select
                            className={`${
                              utilityProviders.findIndex(
                                (e) => utilityProvider === e
                              ) > 1
                                ? "selected"
                                : ""
                            }`}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              setUtilityProvider(e.target.value);
                            }}
                          >
                            {utilityDropdown(
                              [...utilityProviders].slice(
                                2,
                                utilityProviders.length
                              )
                            ).map((e: any) => (
                              <option key={e} value={e}>
                                {e}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : (
                      <div className="provider-option">
                        No utility providers :/
                      </div>
                    )}
                  </div>
                </FormCards>
                <FormCards
                  canProgress={!!firstName && !!lastName}
                  onNext={() => {
                    namesOnNext(firstName, lastName, setNameError, onNext);
                    (window as any).dataLayer.push({
                      event: "name_details_submit",
                      firstName,
                      lastName,
                    });
                  }}
                  onBack={onBack}
                  step={step}
                  index={7}
                  progressBar={false}
                  allSteps={SURVEY_LENGTH - 3}
                  title="Who are we preparing this report for?"
                >
                  <div className="form-body-content">
                    <div className="email-error" style={{ color: "red" }}>
                      {nameError}
                    </div>
                    <div className="name-form">
                      <input
                        type="text"
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </FormCards>
                <FormCards
                  canProgress={!!email}
                  onNext={async () => {
                    if (email && /\S+@\S+\.\S+/.test(email)) {
                      (window as any).dataLayer.push({
                        event: "email_details_submit",
                        email,
                      });
                      onNext();
                    } else {
                      setEmailError("Please enter a valid email address");
                    }
                  }}
                  onBack={onBack}
                  step={step}
                  index={8}
                  allSteps={SURVEY_LENGTH - 1}
                  title="What's the best email for the report?"
                >
                  <div className="email-input">
                    <div
                      className="email-error"
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      {emailError}
                    </div>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </FormCards>
                <FormCards
                  lastCard
                  loading={!solarData && submitted && phoneSubmitted}
                  progressBar={false}
                  canProgress={!!phoneNumber}
                  onNext={async () => {
                    if (phoneNumber) {
                      setPhoneSubmitted(true);
                      validateRealPhoneNumber(phoneNumber).then((res: any) => {
                        if (res === true) {
                          setValidateRealPhoneError(false);
                          (window as any).dataLayer.push({
                            event: "phone_number_submit",
                            phoneNumber,
                          });
                          (window as any).dataLayer.push({
                            event: "multi_step_form_submit",
                            ...onSubmit,
                          });
                          onSubmit();
                        } else {
                          setValidateRealPhoneError(true);
                          setPhoneSubmitted(false);
                        }
                      });
                    }
                  }}
                  onBack={onBack}
                  step={step}
                  index={9}
                  allSteps={SURVEY_LENGTH - 1}
                  title="Finally, what phone number can we reach you at?"
                >
                  <div className="form-body-content">
                    <div className="name-form">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        pattern="[+]{1}[0-9]{11,14}"
                        placeholder="Phone Number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      {validateRealPhone && (
                        <span className="error-code" style={{ color: "red" }}>
                          Phone number is invalid!
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="form-disclaimer"
                    style={{ marginTop: "10px" }}
                  >
                    {[
                      "By submitting this form, I am providing The Home Otter and one or more of its ",
                      "partners, affiliates, or their agents ",
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={{ pathname: "/matched-companies" }}
                      >
                        {" "}
                        (Matched Companies){" "}
                      </Link>,
                      "my electronic signature giving prior express written consent to be contacted ",
                      "and to receive telemarketing calls or texts (including by prerecorded messages ",
                      "or using an autodialer or automated means) regarding related products or ",
                      "services at the number and/or e-mail address provided, even if the number ",
                      "provided is on a corporate, state, or national Do Not Call list. I understand ",
                      "that my consent is not required to receive quotes or make a purchase, ",
                      "I can revoke my consent at any time and I represent that I am at least 18 ",
                      "years old and agree to be bound by ",
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={{ pathname: "/term" }}
                      >
                        Terms of Use
                      </Link>,
                      " and ",
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={{ pathname: "/privacy" }}
                      >
                        Privacy Policy.
                      </Link>,
                    ]}
                  </div>
                </FormCards>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="about-container">
        <div className="about-left">
          <div className="left-heading">
            What’s In Your Free
            <span className="solar-text" style={{ margin: "10px" }}>
              Solar Report?
            </span>
          </div>
          <div className="left-text">
            Your report includes all the tax incentives and government-funded
            solar panel programs available for your local area, so it’s easy to
            see how much you’d save. You will also see a breakdown of all solar
            savings as well as the average cost for your recommended system
            size. Plus, our solar payback calculator will show you the number of
            years until payback would happen.
          </div>
        </div>
        <div className="about-right">
          <div className="right-heading">
            How We Create Your
            <span className="solar-text" style={{ margin: "10px" }}>
              Solar Report
            </span>
          </div>
          <div className="right-text">
            Fill out a form with some basic information about your home and you
            receive a personalised report within a few minutes! We ve partnered
            with
            <b style={{ marginLeft: "5px", marginRight: "5px" }}>
              Google Project Sunroof and Google Earth
            </b>
            to show you the exact amount of sun or shade your roof gets each
            day. Meaning you get the most accurate data to determine how much
            money solar could save you!
          </div>
        </div>
      </div>
      <div
        className="testimonial-container"
      >
        <div className="testimonial-content">
          I got a clear, accurate report straight to my email which ended up
          being incredibly helpful for choosing the best local supplier.
          <div className="testimonial-author">— Ben, Orange County</div>
        </div>
      </div>
      <div className="why-solar-container">
        <h2 className="why-solar-heading">
          Why Solar
          <span className="highlight" style={{ margin: "10px" }}>
            Now?
          </span>
        </h2>
        <p className="why-solar-subheading">
          Solar energy is financially smart, especially with state-level
          incentives and savings from trusted local contractors.
        </p>
        <div className="benefits-container">
          <div className="benefit-item">
            <img
              src="https://lh3.googleusercontent.com/vg5ptrJiDjfKaCiIhc1PZHs8gCsDzkmIowEaKJFj36Ave94aXikf9rzVVIFlfxNgiQV1j1DEnRdwa1LMug_tyJpytYDGBlcGoJw=s0"
              alt="Savings Icon"
              className="benefit-icon"
            />
            <h3>75% Savings on Your Electricity Bills</h3>
            <p>
              Drastically reducing your monthly expenses with efficient solar
              power.
            </p>
          </div>
          <div className="benefit-item">
            <img
              src="https://lh3.googleusercontent.com/itDvS_Sj6pL0yBYAcLktdJfPLi5Ah2wjk26pVGQOrpONdBv3VnJ32uOAwiisBEo4vPHzMiwvBxGB9pGpw3fRSVehT2veGHCZGw=s0"
              alt="House Value Icon"
              className="benefit-icon"
            />
            <h3>$10,000 Increase in House Value</h3>
            <p>
              Making your home more attractive to buyers and boosting your
              equity.
            </p>
          </div>
          <div className="benefit-item">
            <img
              src="https://lh3.googleusercontent.com/vg5ptrJiDjfKaCiIhc1PZHs8gCsDzkmIowEaKJFj36Ave94aXikf9rzVVIFlfxNgiQV1j1DEnRdwa1LMug_tyJpytYDGBlcGoJw=s0"
              alt="Free Electricity Icon"
              className="benefit-icon"
            />
            <h3>25+ Years of Free Electricity</h3>
            <p>
              Securing a sustainable energy source that lowers your utility
              costs.
            </p>
          </div>
        </div>
      </div>
      <div className="customer-container">
        <h2 className="customer-heading">
          Our Customers
          <span className="highlight-cus" style={{ margin: "10px" }}>
            Love Us
          </span>
        </h2>
        <div className="customer-content">
          <p className="customer">
            &ldquo;They did a great job on my installation.&rdquo;
            <strong style={{ margin: "4px" }}>Juan-Suchite</strong>
          </p>
          <p className="customer">
            &ldquo;I had a great experience and learned a lot about solar
            panels. Everything was done so smoothly starting from signing the
            contract through installation.&rdquo;
            <strong style={{ margin: "3px" }}>Kat-Phimmasone</strong>
          </p>
          <p className="customer">
            &ldquo;The entire process of installing 13 solar panels took only a
            month, with very clear communication throughout. I definitely
            recommend this company.&rdquo;
            <strong style={{ margin: "3px" }}>Blake-Allen</strong>
          </p>
          <p className="customer">
            &ldquo;The installation team is great and work done in order. The
            crew notifies us for the best possible actual location of the panel
            that meets the requirements and providing necessary information for
            the install schedule and inspection.&rdquo;
            <strong style={{ margin: "3px" }}>Carlo-Samson</strong>
          </p>
        </div>
      </div>
      <div className="graph-design">
        <img
          src="https://lh3.googleusercontent.com/H8WJXdZm6Fw2pKUquFQmnElvUtqJv_H_gwA8TiSTKfQTpiWbezL-QBRGPskvMoa45Q6GXKzJV3qNRJT4GmyboyKZSlA4NlDySy0=w1058"
          alt="img"
          style={{ width: "100%" }}
        />
      </div>
      <div className="solar-container">
        <h2 className="solar-heading">
          See If Solar Is
          <span className="highlight-solar" style={{ margin: "10px" }}>
            Right For You
          </span>
        </h2>
        <p className="solar-subheading">
          Quickly fill out the solar survey and get a solar quote in no time
          from top solar installers in your area. You will be provided a
          comprehensive solar report to see exactly how much you can save.
        </p>
        <button
          type="submit"
          onClick={() => scrollToSection("formId")}
          className="solar-button"
          style={{ cursor: "pointer" }}
        >
          Get Quote Now
        </button>
      </div>
      <div className="copyright-section">
        <div className="copyright-content">
          © 2024 HomeOtter, All Rights Reserved.
          <span className="under-line">Privacy Policy</span>
        </div>
      </div>
    </div>
  );
}
