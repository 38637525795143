import React from 'react';
import SolarReportInfo from '../../../components/solar-report-info/solar-report-info';
import RowWrapper from '../../../components/what-youll-get/RowWrapper';
import BaseWhatYoullGet from '../../../components/what-youll-get/what-youll-get';
import DiagramImage from '../../../images/diagram.png';
import DecisionImage from '../../../images/ecology-and-environment.png';
import SolarImage from '../../../images/solar-cell.png';
import CostBenefitImage from '../../../images/sustainable.png';

export default function WhatYoullGet(): JSX.Element {
  return (
    <BaseWhatYoullGet>
      <RowWrapper>
        <SolarReportInfo
          title="COST & BENEFIT OF ADDING SOLAR PANELS TO YOUR HOME"
          icon={CostBenefitImage}
        >
          You will be able to see a breakdown of all solar savings as well as the
          average cost for your recommended system size. Your report will also compare
          the cost and benefits of leasing, buying with cash, or financing your solar
          panels. Plus, our solar payback calculator will show you the number of years
          until payback would happen.
        </SolarReportInfo>

        <SolarReportInfo
          title="UPFRONT & OUT-OF-POCKET COSTS FOR YOUR SOLAR SYSTEM RECOMMENDATION"
          icon={DiagramImage}
        >
          Your report will include the initial cost after tax incentives and government-funded solar
          panel programs. This is the amount that must be paid during the first year. These numbers
          will be compared with out-of-pocket costs, which are calculated before tax incentives.
        </SolarReportInfo>
      </RowWrapper>

      <RowWrapper>
        <SolarReportInfo
          title="RECOMMENDED SOLAR INSTALLATION SIZE FOR YOUR UNIQUE ROOF"
          icon={SolarImage}
        >
          We use the data from Google Sunroof Project to determine how many solar panels would fit
          on your specific roof. This size recommendation will cover about 99% of your electricity
          usage, dramatically reducing or even eliminating your electric bill.
        </SolarReportInfo>

        <SolarReportInfo
          title="ALL THE INFORMATION YOU NEED TO MAKE A SMART DECISION"
          icon={DecisionImage}
        >
          Choosing to install solar panels is a big decision. From understanding tax incentives and
          government-funded solar panel programs to finding the right installer, there’s a lot of
          information to digest. We wanted to make the process of making the right decision as
          simple as possible. That’s why we created this personalized solar report.
        </SolarReportInfo>
      </RowWrapper>
    </BaseWhatYoullGet>
  );
}
