import React from 'react';
import CTA from '../../../components/cta';
import { H1, H2 } from '../../../components/header-tag';
import Hero from '../../../components/hero';
import { OtterHelmetImage } from '../../../components/images';
import Base from '../../../pages/home-page/home-page-v2';
import { BathroomHeaderImage } from '../images';
import BathroomTypes from './bathroom-types';
import './home-page.scss';
import Reasons from './reasons';

export default function HomePage(): React.ReactElement {
  return (
    <div id="bathroom-home-page">
      <Base headerProps={{ simple: true }} footerProps={{ disclaimer: null }}>
        <Hero
          title={<H2>Save On</H2>}
          subtitle={(
            <H1>
              BATHROOM
              <br />
              RENOVATIONS
            </H1>
          )}
          content={(
            <p>
              Get quotes from quality service providers for free - and without any payment
              information required.
            </p>
          )}
          image={<BathroomHeaderImage />}
        />

        <Reasons />

        <CTA />

        <div id="bathroom-types-container">
          <BathroomTypes />

          <OtterHelmetImage />
        </div>
      </Base>
    </div>
  );
}
