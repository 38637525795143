/* eslint-disable */

import React from 'react';
import Footer, { Props as FooterProps } from '../../components/footer/footer';
import Header, { Props as HeaderProps } from '../../components/header/header';
import './privacy-page.scss';

interface Props {
  headerProps?: HeaderProps;
  footerProps?: FooterProps;
}

function PrivacyPage({ headerProps, footerProps }: Props): JSX.Element {
  return (
    <div className='term-page-container'>
      <Header {...headerProps} />
      <div className="overlap">
        <div className="container">
          <div className="text-center" style={{textAlign: 'center'}}>
            <h1>Website Terms, Conditions, and Notices</h1>
          </div>
          <h2>PRIVACY POLICY</h2>
          <p>Last updated: Feb 20, 2024</p>
          <p>TheQuoteMatch and Dobak Holdings LLC, (“DH”), through its website the TheQuoteMatch.com (the “Site”) is committed to ensuring the privacy and security of each user that visits our website. Your privacy rights are important to us and we are committed to respecting your online privacy and making sure that any information you share with us by using our website is properly protected.</p>
          <p>This privacy policy (the “Privacy Policy”) outlines the privacy practices associated with the Site. You agree to this Privacy Policy, in its entirety, when you: (a) access or use the Site; (b) register on the Site to receive information, products and/or services from third party service providers, including brokers, their carriers, agents, or representatives (“Third Party Providers”). We reserve the right to amend or otherwise modify this Privacy Statement at any time. Please revisit this page occasionally to check for updates</p>
          <h2>Information Collected</h2>
          <p>When you visit our website, we may collect personally identifiable information (“PII”) and non-personally identifiable information through various sources. PII means any information that may be used to identify an individual, including but not limited to name, address, and telephone number. Non-PII is data that is not used to specifically identify, contact or locate an individual, including but not limited to zip code, gender, and age.</p>
          <p>As is true of most websites, we may gather certain information automatically and store it in log files. This information may include Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data, among other possible data. We keep this information for an indefinite amount of time to improve the operation of our Site and to provide better services to our users.</p>
          <p>This contact information is generally stored on our servers and used to fulfill your information request. If you have not opted out of its use, we may share it with our data licensees, including our advertising clients and their vendors, and other advertising partners and third parties.</p>
          <h2>Using the Information Collected</h2>
          <p>The information that is automatically collected by this Site allows DH to further develop and improve the layout, design, and usability of its sites, in order to better serve your internet needs. Information you provide to us may be used for marketing purposes, and may result in follow-up communications. We automatically collect some information from your computer or device when you visit the Site. For example, we may collect session data, including your IP address, web browser software, and referring website. We also may collect information about your online activity, such as content viewed, pages visited, and searches. One of our goals in collecting this automatic information is to help us understand the interests and preferences of our users and customize your user experience.</p>
          <p>DH may use the information we collect from and about you in the following ways: (1) to fulfill your requests for information; (2) to respond to your inquiries; (3) to review Site usage and operations; (4) to address problems with the Site, our business, or our services; (5) to contact you with Site updates, newsletters and other informational and promotional materials from us and third party marketing offers from our trusted partners, as well as from other companies and; (6) for any other promotional, marketing or sales purpose.</p>
          <p>We may also disclose information collected from and about you as follows: (1) to our related companies and service providers, to perform a business, professional or technical support function for us; (2) to our marketing partners, advertisers or other third parties, who may contact you with their own offers; (3) as necessary if we believe that there has been a violation of our Terms of Use or of our rights or the rights of any third party; (4) to respond to legal process (such as a search warrant, subpoena or court order) and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law; (5) in the event that our company or substantially all of its assets are acquired, your personal information may be one of the transferred assets and; (6) to other third parties at DH’s discretion. By accessing this Site or by using our service to request information from our advertising clients and advertising partners, you are giving permission to DH and these parties to contact you using methods of their choice.</p>
          <h2>With whom we share your information</h2>
          <p>DH may share your information with the following entities:</p>
          <p>Third-party vendors who provide services or functions on our behalf, including business analytics, information processing, customer service, marketing, public relations, distribution of offers or promotional programs, and fraud prevention. We may also authorize third-party vendors to collect information on our behalf, including, for example, as necessary to operate features of our Site or to facilitate the delivery of online advertising tailored to your interests.</p>
          <p>Business partners with whom we may offer products or services in conjunction with or other third parties. We may share information about you, including your PII, with those partners or other partners doing business with DH. Please note that we do not control the privacy practices of these third-party business partners.</p>
          <p>Referring Websites. If you were referred to the Site from another website, we may share your PII or other information collected from the Site with that referring website. We have not placed limitations on the referring websites’ use of personal information and we encourage you to review the privacy policies of any website that referred you to DH or the Site.</p>
          <p>Social Media Services. You may be able to access certain third party social media websites and services through our Site (such as Facebook). When you do so, you are sharing information with those sites, and the information you share will be governed by their privacy policies. You may also be able to modify your privacy settings with these third party social media websites.</p>
          <p>Companies within our corporate family. We may share your personal information within the DH group, as well as with our parent companies and domestic and international corporate affiliate companies and websites. This sharing enables us to provide you with information about products and services, which might interest you. To the extent that our parent company and corporate affiliates have access to your information, they will follow practices that are at least as restrictive as the practices described in this Privacy Policy.</p>
          <p>Please note that we do not have access to or control over cookies or other technologies that any third parties may use to collect information about your interests, and the information practices of these third parties are not covered by this Privacy Policy.</p>
          <h2>Response to Do Not Track signals</h2>
          <p>Your web browser may transmit a “Do Not Track” (“DNT”) signal to DH, this Site, and other websites. Because of differences in web browsers’ default settings and the manner in which browsers incorporate DNT signals, it is sometimes unclear to site operators whether site visitors have selected the DNT setting themselves, or whether they are aware that they have a choice in determining the setting. DH and this Site do not currently respond to DNT signals. Once the Internet industry has settled on standards related to this issue and DH recognizes such standards, we may reevaluate this approach.</p>
          <p>Use of Information from other Sources</p>
          <p>We also may periodically obtain both personal and non-personal information about you from affiliated entities, business partners and other independent third-party sources and add it to other information about you. For example, if you visit the Site by “clicking through” from a site operated by one of our business partners, and you have registered with that partner, then information about you that you have provided to that partner may be shared with us. As another example, if you access third party services, such as social media services, through our Site or before coming to any of our other websites, we may collect information such as your user name, password, and other information made available to us through those services. We will work with our existing partners and potential future partners to improve and personalize your use of our Site in accordance with the practices outlined in this section and Privacy Policy.</p>
          <h2>Cookies</h2>
          <p>We use cookies for authentication. You can control the use of cookies by having your browser disable cookies or warn you before accepting a cookie. If you reject cookies, you may still use our Site, but your ability to use some features or areas of our Site may be limited.</p>
          <p>We may partner with a third party to either display advertising on this Site or to manage our advertising on other sites. Our third party partner may use technologies such as cookies to gather information about your activities on this Site and other sites in order to provide you advertising based upon your browsing activities and interests.</p>
          <p>To learn more about targeted ads or to opt-out of this type of advertising, visit the Network Advertising Initiative website or Digital Advertising Alliance website.</p>
          <h2>DNC Rights</h2>
          <p>Your acceptance of the terms and conditions of this Site constitutes your express written consent to receive telemarketing calls and text messages from third-party marketers, including by means of a pre-recorded message, automated text messages, and automated voice dialing. Your consent will be effective regardless of whether the number you have provided: (a) is a home, business, or cell phone line; or (b) is or will be registered on any state or federal Do-Not-Call (DNC) list, and such consent shall remain in effect until you revoke your consent by contacting us using the methods described in the “Contact Us” section.</p>
          <h2>Children’s Privacy Rights</h2>
          <p>We do not accept registrations from persons under the age of eighteen. If you are between the ages of 16-18 you must have your parent’s approval to register. If we learn that a user is under the age of eighteen without valid parental approval, we will terminate the account and delete the user’s information. We will not be liable for any content or advertisements viewed by minor children in violation of the terms of this Privacy Policy.</p>
          <p>In compliance with the Children’s Online Privacy Protection Act (COPPA), the Site is not directed at or designed to attract children. Accordingly, we do not knowingly or intentionally collect personally identifiable information from anyone under 13 years of age.</p>
          <h2>California’s Privacy Rights</h2>
          <p>Under California’s California Civil Code Section 1798.83, we provide a method for California residents to “opt-out” of having their information shared with third-parties and to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. Contact us using the methods described in the “Contact Us” section to unsubscribe or to request such information.</p>
          <h2>Opt Out</h2>
          <p>To stop receiving future communications from DH, please go to the Contact Us page and write “Opt Out” in the comment section to opt out. DH may maintain separate e-mail lists for different purposes. If you have registered or submitted information under more than one email account, you must submit separate opt-out requests for each account.</p>
          <p>We may provide information to third parties, and third parties may independently collect information about users. Information shared with, or collected by, these third parties is subject to those third parties’ policies governing use and disclosure of personal information. Users should contact those third parties directly if they do not wish to receive communications from them, do not want those third parties to collect information about them, or wish to take any other actions with regard to such information.</p>
          <h2>CONTACT US</h2>
          <p>If you have any questions regarding this Agreement you may contact DH by filling out the form on the Contact Us portion of the Website or by emailing <a href="mailto:legal@blueinkdigital.com">legal@blueinkdigital.com</a></p>
          <h2>ADDRESS</h2>
          <p>
            6 Liberty Square, Boston, MA 02109
          </p>
        </div>
      </div>
      <Footer {...footerProps} />
    </div>
  );
}

export default PrivacyPage;
