import React from 'react';
import Colorize, { Color } from '../../../components/color';
import { H1, H3 } from '../../../components/header-tag';
import AssistanceImage from '../../../images/windows/happy-home.png';
import CostImage from '../../../images/windows/money-hand.png';
import SavingsImage from '../../../images/windows/window-icon.png';
import './how-to.scss';

interface CardProps {
  image: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

function Card({ image, title, children }: CardProps): JSX.Element {
  return (
    <div className="how-to-card">
      <div className="image">
        {image}
      </div>

      <H3>{title}</H3>

      <div className="content">
        {children}
      </div>
    </div>
  );
}

export default function HowTo(): JSX.Element {
  return (
    <div className="how-to-save">
      <H1>
        HOW TO SAVE ON YOUR
        <Colorize color={Color.Secondary}> REPLACEMENT WINDOWS</Colorize>
      </H1>

      <div className="cards">
        <Card image={<img src={SavingsImage} alt="home window" />} title="21-31%">
          Replacing single pane windows with double panes can save between 21% and 31% off of
          heating and cooling costs according to Energy Star.
        </Card>

        <Card
          image={<img src={CostImage} alt="3 dollar signs in circles over a hand" />}
          title="$100-650"
        >
          In the United States, the average price of replacing a window is around $100-650.
        </Card>

        <Card
          image={<img src={AssistanceImage} alt="two crossed hands over a home" />}
          title="Government Assistance"
        >
          The Weatherization Assistance Program is administrated at both the state and local level.
          Eligibility includes low-income families, the elderly, and persons with disabilities
        </Card>
      </div>
    </div>
  );
}
