import React from 'react';
import OtterLogo from '../../images/otter_character_1.png';
import WhatYouGetImage from '../../images/what_you_get.png';
import DefaultItems from '../why-report/default-items';
import './what-youll-get.scss';

interface TextProps {
  whatIsInReportSection1?: string;
  whatIsInReportSection2?: string;
  whatIsInReportSection3?: string;
  whatIsInReportSection4?: string;
  children?: React.ReactNode;
}

function WhatYoullGet({
  whatIsInReportSection1 = '',
  whatIsInReportSection2 = '',
  whatIsInReportSection3 = '',
  whatIsInReportSection4 = '',
  children,
}: TextProps): JSX.Element {
  return (
    <div className="what-youll-get">
      <div className="left_container">
        <div className="header">
          What is in your solar report?
        </div>

        <div className="image-container">
          <img className="image" src={WhatYouGetImage} alt="solar info" />
        </div>
      </div>

      <div className="right_container">
        {children ?? (
          <DefaultItems
            whatIsInReportSection1={whatIsInReportSection1}
            whatIsInReportSection2={whatIsInReportSection2}
            whatIsInReportSection3={whatIsInReportSection3}
            whatIsInReportSection4={whatIsInReportSection4}
          />
        )}
      </div>

      <div className="right_logo">
        <img className="img" src={OtterLogo} alt="otter solar" />
      </div>
    </div>
  );
}

export default WhatYoullGet;
