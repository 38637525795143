/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import axios from 'axios';
import {
  getUtilityProvider,
  postFormData,
  validatePhoneNumber,
  validatePhoneCode,
} from '../../api/solar-data';
import Footer from '../../components/footer/footer';

import FormCard from '../../components/form-card/form-card';
import GooglePlaceAutocomplete from '../../components/google-places/google-places-autocomplete';
import { Place } from '../../components/google-places/google-places-autocomplete.types';
import Header from '../../components/header/header';
import Option from '../../components/option/option';
import useScript from '../../hooks/useScript';
import { monthlyBills, sun, homeTypes } from './get-started-page.constants';
// import OtterIcon from '../../images/otter characters - 02 (4).png';
import { showPrice, namesOnNext } from './utils';
import './get-started-page.scss';
import pushDataLyer from '../../utils/gtm';

const SURVEY_LENGTH = 10;

interface TextProps {
  version?: string;
}

function GetStartedPageV1({ version = '' }: TextProps): JSX.Element {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();
  const { solarData, dispatch } = useStoreon('solarData');
  const [step, setStep] = useState(0);
  const [homeType, setHomeType] = useState<string|undefined>('Select One');
  const [monthlyBill, setMonthlyBill] = useState<string|undefined>(undefined);
  const [sunAmount, setSunAmount] = useState<string|undefined>(undefined);
  const [rent, setRent] = useState<string|undefined>(undefined);
  const [place, setPlace] = useState<Place|undefined>(undefined);
  const [utilityProviders, setUtilityProviders] = useState<string[]|undefined>(undefined);
  const [utilityProvider, setUtilityProvider] = useState<string|undefined>(undefined);
  const [firstName, setFirstName] = useState<string|undefined>(undefined);
  const [lastName, setLastName] = useState<string|undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string|undefined>(undefined);
  const [verificationError, setVerificationError] = useState<boolean|undefined>(undefined);
  const [verificationCode, setVerificationCode] = useState<string|undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [phoneSubmitted, setPhoneSubmitted] = useState(false);
  const [email, setEmail] = useState<string|undefined>(undefined);
  const [emailError, setEmailError] = useState<string>('');
  const [anuraFraud, setAnura] = useState('');
  const [leadIdCode, setLeadIdCode] = useState('');
  const [GCLID, setGCLID] = useState('');
  const [FBID, setFBID] = useState('');
  const [token, setToken] = useState('');
  const [sourceID, setSourceID] = useState('');
  const [offerID, setOfferID] = useState('');
  const [trustedFormCertURL, setTrustedFormCertURL] = useState('');
  const [userAgent, setUserAgent] = useState('');
  const [validateRealPhone, setValidateRealPhoneError] = useState<boolean|undefined>(false);
  const [nameError, setNameError] = useState('');

  const [inputs] = useState<any>(
    <>
      <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
      <input id="offer_id" name="offer_id" type="hidden" value="" />
      <input id="oid" name="oid" type="hidden" value="" />
      <input id="source_id" name="source_id" type="hidden" value="" />
      <input id="sub4" name="sub4" type="hidden" value="" />
      <input id="authorization_token" name="authorization_token" type="hidden" value="" />
      <input id="gclid_field" name="gclid_field" type="hidden" value="" />
      <input id="anura_fraud" name="anura_fraud" type="hidden" value="" />
      <input id="facbook_field" name="gclid_field" type="hidden" value="" />
      <label hidden>
        <input type="hidden" id="leadid_tcpa_disclosure" value="" />
        {`By submitting this form, I am providing The Home Otter and one or more of its partners, 
          affiliates, or their agents (Matched Companies) my electronic signature giving prior 
          express written consent to be contacted and to receive telemarketing calls or texts 
          (including by prerecorded messages or using an autodialer or automated means) regarding 
          related products or services at the number and/or e-mail address provided, even 
          if the number provided is on a corporate, state, or national Do Not Call list. 
          I understand that my consent is not required to receive quotes or make a purchase, 
          I can revoke my consent at any time and I represent that I am at least 18 years 
          old and agree to be bound by Terms of Use and Privacy Policy.`}
      </label>
    </>,
  );

  const [ip, setIP] = useState();

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  function CollectHiddenInputs() {
    setAnura((document.getElementById('anura_fraud') as HTMLInputElement).value);
    setToken((document.getElementById('authorization_token') as HTMLInputElement).value);
    setLeadIdCode((document.getElementById('leadid_token') as HTMLInputElement).value);
    setSourceID((document.getElementById('source_id') as HTMLInputElement).value);
    if (offerID === '') {
      setOfferID((document.getElementById('offer_id') as HTMLInputElement).value);
    }
    setTrustedFormCertURL((document.getElementById('xxTrustedFormCertUrl_0') as HTMLInputElement).value);
    setFBID((document.getElementById('facbook_field') as HTMLInputElement).value);
    setGCLID((document.getElementById('gclid_field') as HTMLInputElement).value);
    setUserAgent('');
  }
  const onNext = useCallback(() => {
    if (step < SURVEY_LENGTH - 1) {
      pushDataLyer(step, version);
      setStep((prevStep) => prevStep + 1);
      CollectHiddenInputs();
    }
  }, [step, setStep]);

  const onBack = useCallback(() => {
    if (step > 0) setStep((prevStep) => prevStep - 1);
    setValidateRealPhoneError(false);
    setVerificationError(false);
    setPhoneSubmitted(false);
  }, [step, setStep]);

  const onSubmit = useCallback(() => {
    if (email && (/\S+@\S+\.\S+/.test(email))) {
      postFormData({
        zip_code: place?.zipCode,
        ip_address: ip,
        source_id: sourceID,
        offer_id: offerID,
        trusted_form_cert_url: trustedFormCertURL,
        user_agent: userAgent,
        landing_page_url: 'https://homeotter.com/solarV1',
        monthly_bill: monthlyBill,
        own_rent: rent,
        home_type: homeType,
        sun: sunAmount,
        address: `${place?.street_number} ${place?.route}`,
        city: place?.administrative_area,
        state: place?.country,
        electric_provider: utilityProvider,
        first_name: firstName,
        last_name: lastName,
        authorization_token: token,
        email_address: email,
        anura: anuraFraud,
        lead_id_code: leadIdCode,
        gclid: GCLID,
        fbid: FBID,
        phone_number: phoneNumber,
      }).then((res: any) => {
        console.log(res);
      });
      dispatch('getSolarData', {
        latitude: place?.location.lat,
        longitude: place?.location.lng,
        firstname: firstName,
        lastname: lastName,
        bill: monthlyBill,
        address: place,
        cb: () => {
          navigate({ pathname: '/analysis', search: params });
        },
      });
      setSubmitted(true);
      pushDataLyer(9, version);
      // navigate('../analysis');
    } else {
      setEmailError('Please enter a valid email address');
    }
  }, [place, dispatch, email]);

  useScript();

  const canProgressHomeType = () => {
    if (homeType === 'Select One') {
      return false;
    }
    return true;
  };

  const handleSetMonthlyBill = (value: string) => {
    setMonthlyBill(value);
    onNext();
  };

  const handleSetOwnRent = (value: string) => {
    setRent(value);
    onNext();
  };

  const handleSunRoof = (value: string) => {
    setSunAmount(value);
    onNext();
  };

  const handleElectricProvider = (value: string) => {
    setUtilityProvider(value);
    onNext();
  };

  const utilityDropdown = (elements: any) => {
    elements.unshift('Choose from list');
    return elements;
  };

  return (
    <div className="get-started-page">
      <Header />
      <div className="get-started-page-container">
        <form>
          {inputs}
          <FormCard nextButton={false} canProgress={!!monthlyBill} onNext={onNext} onBack={onBack} step={step} index={0} allSteps={SURVEY_LENGTH - 4} title="Roughly how much do you pay for electricity each month?">
            <div className="form-body-content">
              {monthlyBills.map((e: any, i) => (
                <Option
                  selected={monthlyBill === e}
                  key={i}
                  onClick={() => {
                    handleSetMonthlyBill(e);
                  }}
                  value={showPrice(e)}
                />
              ))}
            </div>
          </FormCard>
          <FormCard nextButton={false} canProgress={!!rent} onNext={onNext} onBack={onBack} step={step} index={1} allSteps={SURVEY_LENGTH - 4} title="DO YOU OWN OR RENT?">
            <div className="form-body-content">
              <Option selected={rent === 'own'} onClick={() => handleSetOwnRent('own')} value="Own" />
              <Option selected={rent === 'rent'} onClick={() => handleSetOwnRent('rent')} value="Rent" />
            </div>
          </FormCard>
          <FormCard canProgress={canProgressHomeType()} onNext={onNext} onBack={onBack} step={step} index={2} allSteps={SURVEY_LENGTH - 4} title="WHAT TYPE OF HOME DO YOU LIVE IN?">
            <div className="providers-card">
              <div className="options">
                <select
                  className={`${homeTypes.findIndex((e) => homeType === e) > 1 ? 'selected' : ''}`}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setHomeType(e.target.value);
                  }}
                >
                  {homeTypes.map((e) => (<option key={e} value={e}>{e}</option>))}
                </select>
              </div>
            </div>
          </FormCard>
          <FormCard nextButton={false} canProgress={!!sunAmount} onNext={onNext} onBack={onBack} step={step} index={3} allSteps={SURVEY_LENGTH - 4} title="HOW MUCH SUN DOES YOUR ROOF GET?">
            <div className="form-body-content">
              {sun.map((e, i) => (
                <Option
                  selected={sunAmount === e}
                  key={i}
                  onClick={() => {
                    handleSunRoof(e);
                  }}
                  value={e}
                />
              ))}
            </div>
          </FormCard>
          <FormCard
            canProgress={!!place?.zipCode && !!place?.street_number && !!place?.route}
            onNext={async () => {
              if (place?.zipCode) {
                getUtilityProvider(place?.zipCode).then((res: any) => {
                  setUtilityProviders(res.utility_providers);
                });
              }
              onNext();
            }}
            onBack={onBack}
            step={step}
            index={4}
            allSteps={SURVEY_LENGTH - 4}
            title="LET'S FIND YOU ON THE MAP"
            subtitle="Select your address on the drop down"
          >
            <div className="form-body-content">
              <GooglePlaceAutocomplete onPlaceChanged={setPlace} />
            </div>
          </FormCard>
          <FormCard canProgress={!!utilityProvider && utilityProvider !== 'Choose from list'} onNext={onNext} onBack={onBack} step={step} index={5} allSteps={SURVEY_LENGTH - 4} title="WHO IS YOUR ELECTRIC PROVIDER?">
            <div className="providers-card">
              {utilityProviders ? (
                <>
                  {utilityProviders && (
                  <>
                    <div
                      className={`provider-option ${utilityProviders[0] === utilityProvider ? 'selected' : ''}`}
                      onClick={() => {
                        handleElectricProvider(utilityProviders[0]);
                      }}
                    >
                      {utilityProviders[0]}

                    </div>
                    <div
                      className={`provider-option ${utilityProviders[1] === utilityProvider ? 'selected' : ''}`}
                      onClick={() => {
                        handleElectricProvider(utilityProviders[1]);
                      }}
                    >
                      {utilityProviders[1]}
                    </div>
                  </>
                  )}
                  <div className="options">
                    <select
                      className={`${utilityProviders.findIndex((e) => utilityProvider === e) > 1 ? 'selected' : ''}`}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setUtilityProvider(e.target.value);
                      }}
                    >
                      {utilityDropdown([...utilityProviders].slice(2, utilityProviders.length))
                        .map((e: any) => (<option key={e} value={e}>{e}</option>))}
                    </select>
                  </div>
                </>
              ) : <div className="provider-option">No utility providers :/</div>}
            </div>
          </FormCard>
          <FormCard
            loading={phoneSubmitted}
            canProgress={!!phoneNumber}
            onBack={onBack}
            step={step}
            index={6}
            progressBar={false}
            allSteps={SURVEY_LENGTH - 4}
            title="ENTER YOUR PHONE NUMBER"
            subtitle="We will send you a verification code"
            onNext={async () => {
              if (phoneNumber) {
                setPhoneSubmitted(true);
                validatePhoneNumber(phoneNumber).then((res: any) => {
                  if (res.status === 'invalid') {
                    setValidateRealPhoneError(true);
                    setPhoneSubmitted(false);
                  } else if (res.status === 'pending') {
                    onNext();
                  }
                });
              }
            }}
          >
            <div className="form-body-content">
              <div className="name-form">
                {validateRealPhone && (<span className="error-code">Phone number is invalid or destination has been blocked!</span>)}
                <input type="tel" id="phone" name="phone" pattern="[+]{1}[0-9]{11,14}" placeholder="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
              </div>
            </div>
          </FormCard>
          <FormCard
            canProgress={!!phoneNumber && !!verificationCode}
            onNext={async () => {
              if (verificationCode && phoneNumber) {
                validatePhoneCode(phoneNumber, verificationCode).then((res: any) => {
                  if (res === true) {
                    setVerificationError(false);
                    onNext();
                  } else {
                    setVerificationError(true);
                  }
                });
              }
            }}
            onBack={onBack}
            step={step}
            index={7}
            progressBar={false}
            allSteps={SURVEY_LENGTH - 4}
            title="PLEASE ENTER THE VERIFICATION CODE SENT TO YOUR PHONE NUMBER"
          >
            <div className="form-body-content" style={{ flexFlow: 'column' }}>
              <div className="name-form">
                {verificationError && (<span className="error-code">Verification code is invalid!</span>)}
                <input type="text" id="phone" name="phone" placeholder="Verification Code" onChange={(e) => setVerificationCode(e.target.value)} />
              </div>
              <div style={{ paddingTop: 10 }}>
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: 16,
                  }}
                  type="button"
                  onClick={() => validatePhoneNumber(phoneNumber)}
                >
                  Resend verification code
                </button>
              </div>
            </div>
          </FormCard>
          <FormCard canProgress={!!firstName && !!lastName} onNext={() => namesOnNext(firstName, lastName, setNameError, onNext)} onBack={onBack} step={step} index={8} progressBar={false} allSteps={SURVEY_LENGTH - 3} title="Who are we preparing this report for?">
            <div className="form-body-content">
              <div className="email-error">
                {nameError}
              </div>
              <div className="name-form">
                <input type="text" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} />
                <input type="text" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>
          </FormCard>
          <FormCard loading={!solarData && submitted} lastCard canProgress onNext={onSubmit} onBack={onBack} step={step} index={9} progressBar={false} allSteps={SURVEY_LENGTH - 4} title="FINALLY, WHAT IS YOUR EMAIL?">
            <div className="email-input">
              <div className="email-error">
                {emailError}
              </div>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                placeholder="Email address"
              />
            </div>
            <div className="form-disclaimer">
              {[
                'By submitting this form, I am providing The Home Otter and one or more of its ',
                'partners, affiliates, or their agents ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/matched-companies' }}> (Matched Companies) </Link>,
                'my electronic signature giving prior express written consent to be contacted ',
                'and to receive telemarketing calls or texts (including by prerecorded messages ',
                'or using an autodialer or automated means) regarding related products or ',
                'services at the number and/or e-mail address provided, even if the number ',
                'provided is on a corporate, state, or national Do Not Call list. I understand ',
                'that my consent is not required to receive quotes or make a purchase, ',
                'I can revoke my consent at any time and I represent that I am at least 18 ',
                'years old and agree to be bound by ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/term' }}>Terms of Use</Link>,
                ' and ',
                <Link target="_blank" rel="noopener noreferrer" to={{ pathname: '/privacy' }}>Privacy Policy.</Link>,
              ]}
            </div>
          </FormCard>
          {/* <div className="otter-img-3">
            <img className="otter-icon-3" src={OtterIcon} alt="otter solar" />
          </div> */}
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default GetStartedPageV1;
