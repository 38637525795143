import React from 'react';
import { StoreContext } from 'storeon/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/home-page/home-page';
import AnalysisPage from './pages/analysis-page/analysis-page';
import store from './store/store';
import GetStartedPageV1 from './pages/get-started-page/get-started-page-v1';
import GetStartedPageV2 from './pages/get-started-page/get-started-page-v2';
import GetStartedPageV3 from './pages/get-started-page/get-started-page-v3';
import GetStartedPageV5 from './pages/get-started-page/get-started-page-v5';
import NotFoundPage from './pages/not-found-page/not-found-page';
import ToolsPage from './pages/tool-page/tool-page';
import TermsPage from './pages/terms-page/terms-page';
import PrivacyPage from './pages/privacy-page/privacy-page';
import MatchedCompanies from './pages/matched-companies/matched-companies';
import Unsubscribe from './pages/unsubscribe/unsubscribe';
import CCPAPage from './pages/ccpa-page/ccpa-page';
import BathroomV1 from './versions/bathroom/v1';
import BudgetConsciousV1 from './versions/budget-conscious/V1';
import BudgetConsciousV2 from './versions/budget-conscious/V2';
import IndependentConsumerV1 from './versions/independent-consumer/V1';
import RoofingV1 from './versions/roofing/v1';
import WindowsV1 from './versions/windows/V1';
import { Splash1 } from './pages/splash1-page/splash1-page';
import { Roofing } from './pages/roofingv1-v1/roofingv1-v1-page';
import RoofingV2 from './pages/roofingv2';
import AdminPage from './pages/admin-page';

function App() {
  return (
    <StoreContext.Provider value={store}>
      <BrowserRouter basename="/solarV1">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route path="/get-started" element={<GetStartedPageV1 version="v1" />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/term" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<TermsPage />} />
          <Route path="/matched-companies" element={<MatchedCompanies />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/ccpa" element={<CCPAPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/solarV2">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route path="/get-started" element={<GetStartedPageV2 version="v2" />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/term" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<TermsPage />} />
          <Route path="/matched-companies" element={<MatchedCompanies />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/ccpa" element={<CCPAPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/solarV3">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route path="/get-started" element={<GetStartedPageV3 version="v3" />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/term" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<TermsPage />} />
          <Route path="/matched-companies" element={<MatchedCompanies />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/ccpa" element={<CCPAPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/roofingv1-v1">
        <Routes>
          <Route path="/" element={<Roofing />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/roofingV2">
        <Routes>
          <Route path="/" element={<RoofingV2 />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/term" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/matched-companies" element={<MatchedCompanies />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/solarV4">
        <Routes>
          <Route
            path="/"
            element={(
              <HomePage
                questions
                title="Solar Calculator"
                howCreateDescription="We have partnered with Google Project Sunroof and Google Earth, to show you your home. You will be able to see the exact amount of sun or shade your home receives. The information is critical when deciding if solar is worth it for you as each home is different."
                zipCodePlaceholder="Enter your U.S. Zip Code"
                whatIsInReportSection1="We will use the information generated from Google Sunroof Project to determine how many solar panels would fit on your specific roof. This generated size recommendation will cover about 99% of your electricity usage. Also, to note, these solar installations are sized in kilowatts (kW)."
                whatIsInReportSection2="You will be able to see a breakdown of all things solar savings. The average solar panel cost for your recommended system size in addition to the cost and benefits of leasing, buying with cash, or financing the solar panels. Additionally, with the use of our solar payback calculator, you will be able to see the number of years until payback would happen."
                whatIsInReportSection3="The estimated annual environmental impact of your recommended solar installation size will be displayed."
                whatIsInReportSection4="The Initial cost after tax incentives and government funded solar panel programs will be shown. This is the amount that must be paid during the first year. These numbers will be compared with out of pocket cost, which is before tax incentives."
                whySouldYouGet="With that being said, every house is different. It is in your best interest to use our resources and solar panel cost calculator to figure out what makes the most sense for your individual needs."
              />
            )}
          />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route
            path="/get-started"
            element={<GetStartedPageV3 pageURL="https://homeotter.com/solarV4" version="v4" />}
          />
          <Route
            path="/tools"
            element={(
              <ToolsPage
                zipCodePlaceholder="Enter your U.S. Zip Code"
                howMuchSunDescription="In partnership with google, using advanced API, you can see the exact amount of sun and shade that your specific roof receives. This information is very valuable in deciding on solar panels. This tool will also act as a solar system size calculator as we will show you the recommended amount of solar panels that would make sense for your home."
                personalizedSolarReportDescription="Use our solar power calculator by zip code and receive a free, fully customized, solar report."
              />
            )}
          />
          <Route path="/term" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/matched-companies" element={<MatchedCompanies />} />
          <Route path="/contact" element={<TermsPage />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/ccpa" element={<CCPAPage />} />
          <Route path="/splash1" element={<Splash1 />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/solarV5">
        <Routes>
          <Route
            path="/"
            element={(
              <HomePage
                questions
                title="Solar Calculator"
                howCreateDescription="We have partnered with Google Project Sunroof and Google Earth, to show you your home. You will be able to see the exact amount of sun or shade your home receives. The information is critical when deciding if solar is worth it for you as each home is different."
                zipCodePlaceholder="Enter your U.S. Zip Code"
                whatIsInReportSection1="We will use the information generated from Google Sunroof Project to determine how many solar panels would fit on your specific roof. This generated size recommendation will cover about 99% of your electricity usage. Also, to note, these solar installations are sized in kilowatts (kW)."
                whatIsInReportSection2="You will be able to see a breakdown of all things solar savings. The average solar panel cost for your recommended system size in addition to the cost and benefits of leasing, buying with cash, or financing the solar panels. Additionally, with the use of our solar payback calculator, you will be able to see the number of years until payback would happen."
                whatIsInReportSection3="The estimated annual environmental impact of your recommended solar installation size will be displayed."
                whatIsInReportSection4="The Initial cost after tax incentives and government funded solar panel programs will be shown. This is the amount that must be paid during the first year. These numbers will be compared with out of pocket cost, which is before tax incentives."
                whySouldYouGet="With that being said, every house is different. It is in your best interest to use our resources and solar panel cost calculator to figure out what makes the most sense for your individual needs."
              />
            )}
          />
          <Route path="/analysis" element={<AnalysisPage />} />
          <Route
            path="/get-started"
            element={<GetStartedPageV5 pageURL="https://homeotter.com/solarV5" version="v5" />}
          />
          <Route
            path="/tools"
            element={(
              <ToolsPage
                zipCodePlaceholder="Enter your U.S. Zip Code"
                howMuchSunDescription="In partnership with google, using advanced API, you can see the exact amount of sun and shade that your specific roof receives. This information is very valuable in deciding on solar panels. This tool will also act as a solar system size calculator as we will show you the recommended amount of solar panels that would make sense for your home."
                personalizedSolarReportDescription="Use our solar power calculator by zip code and receive a free, fully customized, solar report."
              />
            )}
          />
          <Route path="/term" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<TermsPage />} />
          <Route path="/matched-companies" element={<MatchedCompanies />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/ccpa" element={<CCPAPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>

      <BudgetConsciousV1 />
      <BudgetConsciousV2 />
      <IndependentConsumerV1 />

      <WindowsV1 />

      <RoofingV1 />

      <BathroomV1 />
    </StoreContext.Provider>
  );
}

export default App;
