import React from 'react';
import SolarReportInfoComponent from '../solar-report-info/solar-report-info';
import SolarImage from '../../images/solar-cell.png';
import SustainableImage from '../../images/sustainable.png';
import EcologyImage from '../../images/ecology-and-environment.png';
import DiagramImage from '../../images/diagram.png';

interface Props {
  whatIsInReportSection1?: string;
  whatIsInReportSection2?: string;
  whatIsInReportSection3?: string;
  whatIsInReportSection4?: string;
}

export default function DefaultItems({
  whatIsInReportSection1,
  whatIsInReportSection2,
  whatIsInReportSection3,
  whatIsInReportSection4,
}: Props): JSX.Element {
  return (
    <>
      <SolarReportInfoComponent
        title="RECOMMENDED SOLAR INSTALLATION SIZE FOR YOUR UNIQUE ROOF"
        content={whatIsInReportSection1 || `This generated size recommendation will cover about 99% of your electricity usage.
          These solar installations are sized in kilowatts (kW)`}
        icon={SolarImage}
      />
      <SolarReportInfoComponent
        title="COST & BENEFIT"
        content={whatIsInReportSection2 || `Cost and benefits of leasing, buying with cash, or financing the solar panels.
          Additionally, you will see the number of years until payback occurs.`}
        icon={SustainableImage}
      />
      <SolarReportInfoComponent
        title="ENVIRONMENTAL IMPACT OF ADDING SOLAR PANELS"
        content={whatIsInReportSection3 || 'Estimated annual environmental impact of your recommended solar installation size.'}
        icon={EcologyImage}
      />
      <SolarReportInfoComponent
        title="UP FRONT & OUT OF POCKET COST FOR YOUR SOLAR PANEL RECOMMENDATION"
        content={whatIsInReportSection4 || `Initial cost after tax incentives: it's the amount that must be paid during the first year.
          Contrasted with out of pocket cost, which is before tax incentives.`}
        icon={DiagramImage}
      />
    </>
  );
}
