import React from 'react';
import Hero from '../../../components/hero';
import HeaderImage from '../../../images/windows/windows-header.png';
import BaseHomePage from '../../../pages/home-page/home-page-v2';
import HowTo from './how-to';
import Reasons from './reasons-section';
import CTA from '../../../components/cta';

export default function HomePage(): JSX.Element {
  return (
    <BaseHomePage footerProps={{ disclaimer: null }} headerProps={{ simple: true }}>
      <Hero
        title={<h2>SAVE ON</h2>}
        subtitle={(
          <h1>
            WINDOW
            <br />
            RENOVATIONS
          </h1>
        )}
        content={(
          <p>
            Get quotes from quality service providers for free - and without any payment information
            required.
          </p>
        )}
        image={<img src={HeaderImage} alt="workers installing a new window" />}
      />

      <Reasons />

      <CTA />

      <HowTo />
    </BaseHomePage>
  );
}
