import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import './footer.scss';

export interface Props {
  disclaimer?: React.ReactNode;
  tel?:string;
}

function Footer({ disclaimer, tel }: Props): JSX.Element {
  const params = new URLSearchParams(window.location.search).toString();
  const location = useLocation();
  const navigate = useNavigate();
  const needConfirmation = () => !['/get-started', '/get-started/'].includes(location.pathname);
  const handleClick = (navigateTo: string) => {
    navigate({ pathname: navigateTo, search: params });
  };

  return (
    <div className="footer">
      <div className="disclaimer">
        <div className="disclaimer-container">
          <div className="disclaimer-text">
            <p>
              {disclaimer !== undefined ? disclaimer : (
                <>
                  *These numbers are projections and are not guaranteed. These projections are
                  calculated with data from Google Sunroof Project, So Association, National
                  Renewable Energy Laboratory, Google Maps, and SIRE (NC Clean Energy Technology
                  Center).
                </>
              )}
            </p>
          </div>

          <div className="have-question">
            <p>
              Have a question? Call us
            </p>
            <div className="phone-number">
              <a href="tel:(833)-794-1267" className="mobile">{tel}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="links">
        <div className="links-container">
          {needConfirmation()
            ? (
              <>
                <Link to={{ pathname: '/term', hash: '#ccpa', search: params }}> CCPA Terms </Link>
                <div className="seperator" />
                <Link to={{ pathname: '/term', search: params }}> Term of Use </Link>
                <div className="seperator" />
                <Link to={{ pathname: '/privacy', search: params }}> Privacy Policy </Link>
                <div className="seperator" />
                <Link to={{ pathname: '/unsubscribe', search: params }}> Unsubscribe </Link>
                <div className="seperator" />
                <Link to={{ pathname: '/ccpa', search: params }}> CCPA </Link>
              </>
            )
            : (
              <>
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => ConfirmationModal(handleClick, '/term')}
                >
                  CCPA Terms
                </button>
                <div className="seperator" />
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => ConfirmationModal(handleClick, '/term')}
                >
                  Term of Use
                </button>
                <div className="seperator" />
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => ConfirmationModal(handleClick, '/privacy')}
                >
                  Privacy Policy
                </button>
                <div className="seperator" />
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => ConfirmationModal(handleClick, '/unsubscribe')}
                >
                  Unsubscribe
                </button>
                <div className="seperator" />
                <button
                  className="btn-link"
                  type="button"
                  onClick={() => ConfirmationModal(handleClick, '/ccpa')}
                >
                  CCPA
                </button>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
