/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './option.scss';

interface OptionProps {
    value: string;
    selected: boolean;
    onClick: (value: string) => void;
}

function Option({ value, selected, onClick }: OptionProps): JSX.Element {
  const handleClick = React.useCallback(() => {
    onClick(value);
  }, [value]);

  return <div onClick={handleClick} className={`${selected && 'selected'} option`}>{value}</div>;
}

export default Option;
