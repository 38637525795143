import React from 'react';
import Intent from '../intent';
import './button.scss';

interface Props {
  children: React.ReactNode;
  intent?: Intent;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export default function Button({
  intent = Intent.Primary,
  className,
  onClick,
  children,
}: Props): JSX.Element {
  return (
    <button
      type="button"
      className={`bid-button ${intent} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
