import React from 'react';
import './landing-page-map.scss';

import MapImage from '../../images/map.png';

interface LandingPageMapContent {
  title: string;
  content?: string;
  children?: React.ReactNode;
}

function LandingPageMap({ title, content, children }: LandingPageMapContent): JSX.Element {
  return (
    <div className="landing-page-map">
      <div className="landing-page-map-container">
        <div className="info">
          <h1>
            {title}
          </h1>
          <div className="seperator" />
          {children ?? (
            <h2>
              {content}
            </h2>
          )}
        </div>
        <div className="image-container">
          <img className="img" src={MapImage} alt="solar map" />
        </div>
      </div>

    </div>
  );
}

export default LandingPageMap;
