import React from 'react';
import Intent from '../../intent';
import GetStartedButton from '../get-started-button';
import './index.scss';

export default function CTA() {
  return (
    <div className="cta-container">
      <div className="content">
        <GetStartedButton intent={Intent.Secondary} className="cta-button" />
      </div>
    </div>
  );
}
