import React from 'react';
import Footer, { Props as FooterProps } from '../../components/footer/footer';
import Header, { Props as HeaderProps } from '../../components/header/header';

interface Props {
  children: React.ReactNode;
  footerProps?: FooterProps;
  headerProps?: HeaderProps;
}

export default function HomePage({ children, footerProps, headerProps }: Props): JSX.Element {
  return (
    <div>
      <Header {...headerProps} />

      <div className="landing-page-container">
        {children}

        <Footer {...footerProps} />
      </div>
    </div>
  );
}
