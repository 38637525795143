export function arrayToString(arr: string[] | null, delimiter: string = ', '): string {
  if (arr !== null) {
    return arr.join(delimiter);
  }
  return '';
}

export function stringToArray(value: string, delimiter: string = ','): string[] {
  return value
    .split(delimiter)
    .map(item => item.trim())
    .filter(Boolean);
}
