import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import './dialog.scss';

type DialogComponentProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  content: React.ReactNode;
  closeButton?: boolean;
  preventCloseOnOutsideClick?: boolean;
}

function DialogComponent({
  open, setOpen, title, content,
  closeButton = true, preventCloseOnOutsideClick = false,
}: DialogComponentProps): JSX.Element {
  const handleOpenChange = (isOpen: boolean) => {
    if (!preventCloseOnOutsideClick || isOpen) {
      setOpen(isOpen);
    }
  };
  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            {
              closeButton && (
              <button
                type="button"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => { setOpen(false); }}
              >
                ⓧ
              </button>
              )
            }
          </div>
          <Dialog.Title className="DialogTitle">{title}</Dialog.Title>
          <Dialog.Description />
          <div>
            {content}
          </div>
          <Dialog.Close />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default DialogComponent;
