/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './hamburger-menu.scss';

function HamburgerMenu(props: React.PropsWithChildren<{}>): JSX.Element {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <div className="hamburger-menu">
      <div className="button" onClick={handleClick}>
        <FontAwesomeIcon icon={faBars} size="2x" />
      </div>

      <div className={`${isOpen ? 'open' : 'closed'} menu`}>
        {children}
      </div>
    </div>
  );
}

export default HamburgerMenu;
