import React from 'react';
import WhyReportComponent from '../../../components/why-report/why-report';
import FrequentQuestions from './frequent-questions';

export default function WhyReport(): JSX.Element {
  return (
    <WhyReportComponent compact={false}>
      <FrequentQuestions />

      <p>
        Our solar panel calculator makes it easy to see what makes the most sense for your
        individual needs. Click “Get Started” to get your free report.
      </p>
    </WhyReportComponent>
  );
}
