import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CheckYourRoofShade from '../../../components/check-your-roof-shade/check-your-roof-shade';
import AnalysisPage from '../../../pages/analysis-page/analysis-page';
import CCPAPage from '../../../pages/ccpa-page/ccpa-page';
import GetStartedPageV5 from '../../../pages/get-started-page/get-started-page-v5';
import HomePage from '../../../pages/home-page/home-page';
import MatchedCompanies from '../../../pages/matched-companies/matched-companies';
import NotFoundPage from '../../../pages/not-found-page/not-found-page';
import PrivacyPage from '../../../pages/privacy-page/privacy-page';
import TermsPage from '../../../pages/terms-page/terms-page';
import ToolsPage from '../../../pages/tool-page/tool-page';
import Unsubscribe from '../../../pages/unsubscribe/unsubscribe';
import LandingPageMap from './landing-page-map';
import WhatYoullGet from './what-youll-get';
import WhyReport from './why-report';
import SolarReport from './solar-report';

export default function IndependentConsumerV1(): JSX.Element {
  return (
    <BrowserRouter basename="/solarIC1">
      <Routes>
        <Route
          path="/"
          element={(
            <HomePage
              questions
              headerElement={<SolarReport />}
              mapElement={<LandingPageMap />}
              checkRoofElement={(
                <CheckYourRoofShade
                  title="Enter Your Zip Code To See How Much Energy Can Be Produced From Your Roof"
                  buttonLabel="Check My Roof"
                  zipCodePlaceholder="Enter your U.S. Zip Code"
                />
              )}
              whatYoullGetElement={<WhatYoullGet />}
              whyReportElement={<WhyReport />}
            />
          )}
        />

        <Route path="/analysis" element={<AnalysisPage />} />

        <Route
          path="/get-started"
          element={<GetStartedPageV5 pageURL="https://homeotter.com/solarIC1" version="v5" />}
        />

        <Route
          path="/tools"
          element={(
            <ToolsPage
              zipCodePlaceholder="Enter your U.S. Zip Code"
              howMuchSunDescription="In partnership with google, using advanced API, you can see the exact amount of sun and shade that your specific roof receives. This information is very valuable in deciding on solar panels. This tool will also act as a solar system size calculator as we will show you the recommended amount of solar panels that would make sense for your home."
              personalizedSolarReportDescription="Use our solar power calculator by zip code and receive a free, fully customized, solar report."
            />
          )}
        />

        <Route path="/term" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/contact" element={<TermsPage />} />
        <Route path="/matched-companies" element={<MatchedCompanies />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/ccpa" element={<CCPAPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
