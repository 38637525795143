import React from 'react';
import './index.scss';
import EligibleZipcode from '../../components/eligible-zipcode';

function AdminPage(): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const credential = window.prompt('Enter credential:');

    // Credentials are also used in API Auth Key
    if (credential === 'ZJEemeag59tV8rzVqGHSSWikacwkqPyJ8EpJpZE5l7PoFh0r6BSlogXzGLdq2UVoF5SuF3xdDmqpx2GfLkiBkIz26aTg0gFCX2HR') {
      setIsAuthenticated(true);
    } else {
      alert('Access denied.');
      window.location.href = '/';
    }
  }, []);

  if (!isAuthenticated) {
    return <div>Unauthorized</div>;
  }

  return (
    <div className="admin-page">
      <h1>Admin Panel</h1>
      <EligibleZipcode />
    </div>
  );
}

export default AdminPage;
