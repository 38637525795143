import React from 'react';
import Intent from '../../intent';
import './index.scss';

interface Props {
  intent?: Intent;
}

export default function Divider({ intent = Intent.Primary }: Props): JSX.Element {
  return (
    <div className={`divider ${intent}`} />
  );
}
