import React from 'react';
import BaseSolarReport, { SeparatorPosition } from '../../../components/solar-report/solar-report';

export default function SolarReport(): JSX.Element {
  return (
    <BaseSolarReport
      separatorPosition={SeparatorPosition.AfterSubtitle}
      titleElement={(
        <h1>
          Save On Your Electric Bill With Solar Panels
        </h1>
      )}
      subtitleElement={null}
      content={(
        <h3>
          Use our personalized Solar Calculator to determine your savings in just 2
          minutes.
        </h3>
      )}
    />
  );
}
