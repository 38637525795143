import React from 'react';
import fullStar from '../../images/svg/star.svg';
import halfStar from '../../images/svg/half_star.svg';
import emptyStar from '../../images/svg/empty_star.svg';

type RatingProps = {
  rating: number;
  maxRating?: number; // default is 5
  numReview: number;
  showValue?: boolean;
};

function Rating({
  rating, maxRating = 5, numReview, showValue = false,
}: RatingProps) {
  const stars = [];
  const roundedRating = Math.round(rating * 2) / 2; // Round to nearest half

  for (let i = 1; i <= maxRating; i += 1) {
    if (i <= roundedRating) {
      stars.push(<img key={i} alt="star-rating" src={fullStar} />);
    } else if (i - 0.5 === roundedRating) {
      stars.push(<img key={i} alt="star-rating" src={halfStar} />);
    } else {
      stars.push(<img key={i} alt="star-rating" src={emptyStar} />);
    }
  }

  // Format the rating value to one decimal place
  const formattedRating = rating.toFixed(1);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {showValue && (
      <span style={{
        marginRight: 6, fontSize: 18, color: '#38a887', fontWeight: 600,
      }}
      >
        {formattedRating}
      </span>
      )}
      <div style={{ display: 'flex' }}>{stars}</div>
      <span style={{ marginLeft: 6, fontSize: 14, fontWeight: 400 }}>
        {`(${numReview})`}
      </span>
    </div>
  );
}

export default Rating;
