import React from 'react';
import './card.scss';

interface CardProps {
    tooltip?: string;
    title?: string | undefined;
}

function Card(props: React.PropsWithChildren<CardProps>): JSX.Element {
  const { title, children, tooltip } = props;

  return (
    <div className="card">
      {tooltip && (
      <div className="tooltip">
        {tooltip}
      </div>
      )}
      {title && (
        <div className="card-header">
          <p>
            {title}
          </p>
        </div>
      )}
      <div className="card-body">
        {children}
      </div>
      <div className="card-footer" />
    </div>
  );
}

Card.defaultProps = {
  title: undefined,
  tooltip: undefined,
};

export default Card;
