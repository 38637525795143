import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import Button from '../../components/button';
import GooglePlaceAutocomplete from '../../components/google-places/google-places-autocomplete';
import { Place } from '../../components/google-places/google-places-autocomplete.types';
import { H3 } from '../../components/header-tag';
import { OtterHouseImage, WarningImage } from '../../components/images';
import { send as sendToZapierBackup } from '../../versions/useZapierBackupCollection';
import './index.scss';

interface Props {
  postUserDataCallback: (data: any) => any;
}

export default function AddressConfirmationContent(
  { postUserDataCallback }: Props,
): React.ReactElement {
  // noinspection TypeScriptValidateTypes
  const { userData, dispatch } = useStoreon('userData');
  const [place, setPlace] = React.useState<Place | null>(null);

  const navigate = useNavigate();

  function navigateToThankYouPage() {
    const params = new URLSearchParams(window.location.search);
    navigate({ pathname: '/thank-you', search: params.toString() });
  }

  // This ref is used to signal to other effects and callbacks that they're currently allowed to
  // force-submit user data. Callbacks should check the current value of this ref before
  // entering their critical sections, and should then immediately update the value to `false`
  // before proceeding.
  const isSubmitAllowedRef = React.useRef(true);

  // If the user idles on this page for longer than 30 seconds, we want to automatically send
  // them to the thank-you page so that their information can be submitted.
  const idleRedirectTaskRef = React.useRef<number | null>(null);
  React.useEffect(() => {
    idleRedirectTaskRef.current = window.setTimeout(() => {
      idleRedirectTaskRef.current = null;

      if (!isSubmitAllowedRef.current)
        return;

      isSubmitAllowedRef.current = false;
      navigateToThankYouPage();
    }, 30_000);

    return () => {
      if (idleRedirectTaskRef.current === null)
        return;

      window.clearTimeout(idleRedirectTaskRef.current);
      idleRedirectTaskRef.current = null;
    };
  }, []);

  // Here we hook the `visibilityChange` event on `document` to force a submission if the user
  // closes the tab, navigates away, or (if they're on mobile) closes the browser app.
  //
  // Unlike with the idle timeout, we need to immediately submit the user's data instead of
  // sending the user to the thank-you page and letting that component handle submission. Since
  // we don't know exactly what behavior triggered this event, we can't rely on redirects being
  // respected by the browser e.g. in the case where the user is closing the tab.
  React.useEffect(() => {
    const handler = () => {
      if (document.visibilityState !== 'hidden' || !isSubmitAllowedRef.current)
        return;

      isSubmitAllowedRef.current = false;

      postUserDataCallback(userData);
      sendToZapierBackup(userData);
    };

    document.addEventListener('visibilitychange', handler);
    return () => document.removeEventListener('visibilitychange', handler);
  }, [postUserDataCallback]);

  const onSubmit = React.useCallback(() => {
    if (place === null || !isSubmitAllowedRef.current)
      return;

    isSubmitAllowedRef.current = false;

    // Storeon's `dispatch()` function only updates the data in the store; it does not send
    // anything to the server. That is handled on the thank-you page when the component is loaded.
    //
    // This behavior is mimicked from the existing solar landing pages in order to maintain
    // consistency throughout the application.

    // noinspection TypeScriptValidateTypes
    dispatch('setUserData', {
      ...userData,
      address: `${place.street_number} ${place.route}`,
      city: place.locality,
      state: place.administrative_area,
    });

    navigateToThankYouPage();
  }, [place, userData]);

  return (
    <div className="form-card">
      <div className="confirm-address-container">
        <div className="image">
          <WarningImage />
        </div>

        <div className="content">
          <H3>Sorry! We could not find your address.</H3>

          <p>
            Please select your address from the list below to receive your results.
          </p>

          <GooglePlaceAutocomplete onPlaceChanged={setPlace} />

          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </div>

      <div className="otter-img-3">
        <OtterHouseImage className="otter-icon-3" />
      </div>
    </div>
  );
}
