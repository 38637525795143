import React, { useMemo } from 'react';
import './analysis-header.scss';
import AnalysisHouse from '../../images/analysis_house.png';

export interface AnalysisHeaderProps {
    name: string;
    address: any;
}

function AnalysisHeader({ name, address }: AnalysisHeaderProps): JSX.Element {
  const title = useMemo(() => `${name}'s solar report`, []);

  return (
    <div className="analysis-header">
      <div className="analysis-header-container">
        <div className="icon">
          <img className="responsive-image" src={AnalysisHouse} alt="solar-house" />
        </div>
        <div className="report-container">
          <h1>
            {title}
          </h1>
          <div className="seperator" />
          <h3>Analysis for...</h3>
          <h3>
            {`${address.street_number} ${address.route}, ${address.administrative_area} ${address.zipCode}`}
            <div>
              {address.country}
            </div>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default AnalysisHeader;
