import React from 'react';
import { postRoofingFormData } from '../../../api/roofing-api';
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import AddressConfirmationContent from '../../../pages/address-confirmation';

export default function AddressConfirmation(): React.ReactElement {
  return (
    <div>
      <Header simple={true} />
      <AddressConfirmationContent postUserDataCallback={postRoofingFormData} />
      <Footer disclaimer={null} />
    </div>
  );
}
