import React from 'react';
import BaseReasons from '../../../components/reasons-section';
import MaintenanceImage from '../../../images/windows/gear-and-tools.png';
import ValueImage from '../../../images/windows/megaphone.png';
import MoneyImage from '../../../images/windows/piggy-bank.png';
import SafetyImage from '../../../images/windows/shield-badge.png';
import ReasonsImage from '../../../images/windows/windows-placement.png';

export default function Reasons(): JSX.Element {
  return (
    <BaseReasons
      title="Why should you replace or repair your windows?"
      image={<img src={ReasonsImage} alt="workers adjusting a newly installed window" />}
    >
      <BaseReasons.Item
        title="IMPROVE THE SAFETY ON YOUR HOME"
        image={<img src={SafetyImage} alt="shield badge with checkmark" />}
      >
        Older windows can be a security vulnerability to your home. A lot of times these older
        windows do not fit correctly, they can open easily, or have a hard time to close properly.
        All of these factors leave room for security risks.
      </BaseReasons.Item>

      <BaseReasons.Item
        title="AVOID FUTURE MAINTENANCE AND REPAIRS"
        image={<img src={MaintenanceImage} alt="wrench and screwdriver on top of a gear" />}
      >
        Older windows are more prone to issues caused by weather related elements. Leaking windows
        can lead to water damage, mold and mildew.
      </BaseReasons.Item>

      <BaseReasons.Item
        title="BOOST THE VALUE OF YOUR HOME"
        image={<img src={ValueImage} alt="megaphone" />}
      >
        According to the 2019 Cost vs. Value Report from Remodeling magazine, a vinyl window
        replacement has a return of 73.4% and a wood window replacement has a return of 70.8%.
      </BaseReasons.Item>

      <BaseReasons.Item
        title="SAVE MONEY ON YOUR HEATING AND COOLING COSTS"
        image={<img src={MoneyImage} alt="piggy bank" />}
      >
        The Department of Energy estimates that you can save $125 to $465 dollars a year just by
        replacing windows with new Energy Star windows.
      </BaseReasons.Item>
    </BaseReasons>
  );
}
