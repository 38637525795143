import { useEffect } from 'react';

export function send(data: any) {
  fetch('https://hooks.zapier.com/hooks/catch/2811046/3hta00c/silent', {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      is_gm_internal: data.email_address === 'gmtesting@gmail.com'
        || window.location.search.indexOf('gmtesting=1') !== -1,
    }),
  });
}

/**
 * Dispatches collected form data to Zapier immediately, instead of
 * @param data
 */
export default function useZapierBackupCollection(data: any) {
  useEffect(() => {
    // Do not attempt Zapier backup submission on localhost
    if (window.location.hostname === 'localhost')
      return;

    send(data);
  }, []);
}
