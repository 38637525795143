import React from 'react';
import { useStoreon } from 'storeon/react';
import { H2 } from '../../components/header-tag';
import useZapierBackupCollection from '../../versions/useZapierBackupCollection';
import './index.scss';

interface Props {
  image: React.ReactNode;
}

export default function ThankYouContent({ image }: Props): JSX.Element {
  return (
    <div className="form-card thank-you-container">
      <div className="image">
        {image}
      </div>

      <div className="content">
        <H2>Thank you!</H2>

        <p>
          One of our service providers will be reaching out to you shortly with your customized
          quote.
        </p>
      </div>
    </div>
  );
}

export function useThankYouBehavior(postDataCallback: (data: any) => any) {
  // noinspection TypescriptValidateTypes3
  const { userData } = useStoreon('userData');

  useZapierBackupCollection(userData);

  React.useEffect(() => {
    postDataCallback(userData);
  }, []);
}
