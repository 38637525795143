/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import './thumbtack-item-card.scss';

import Rating from '../rating/rating';
import Badge from '../badge/badge';
import { formatCurrency } from '../../utils/finance';
// More type details check: https://api.thumbtack.com/docs/demand_api.html#pros

type ProfilePill = 'popular' | 'licensed' | 'low_price';

type FeaturedReviewProps = {
  text?: string;
  maxLength: number,
  href: string
}

export type ThumbtackItemCardProps = {
    service_id: string;
    business_name: string;
    rating: number;
    num_reviews: number;
    years_in_business?: number;
    num_hires?: number;
    thumbtack_url: string;
    image_url: string;
    background_image_url: string;
    featured_review?: string;
    quote?: {
      starting_cost?: number;
      cost_unit?: string;
    };
    introduction: string;
    pills: ProfilePill[];
    location?: string;
    is_top_pro?: boolean;
    similar_jobs_done?: number;
    license_verified?: boolean;
    num_of_employees?: number;
    has_background_check?: boolean;
    business_location: string;
    request_flow_iframe_url: string;
    response_time_hours?: number;
    handleRequestFlowFormURL: (value: string) => void;
  };

function featuredReviewTruncate(props: FeaturedReviewProps) {
  const { text, maxLength, href } = props;
  return (
    text && (
    <>
      <span>
        {
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text
}
      </span>
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: 'none',
          color: '#0ea5e9',
        }}
      >
        {text.length > maxLength && ' See More'}
      </a>
    </>
    )
  );
}

function ThumbtackItemCard(props: ThumbtackItemCardProps): JSX.Element {
  const {
    service_id, thumbtack_url, request_flow_iframe_url, business_name,
    image_url, rating, num_reviews, pills, num_hires, featured_review, quote,
    handleRequestFlowFormURL,
  } = props;

  const truncatedFeaturedReview = featuredReviewTruncate({
    text: featured_review,
    maxLength: 200,
    href: thumbtack_url,
  });

  return (
    <div key={service_id} className="thumbtack-item-card">
      <div className="business-profile">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 8,
        }}
        >
          <img
            alt="logo-thumbtack"
            src="https://cdn.thumbtackstatic.com/fe-assets-web/media/logos/thumbtack_logomark_200x200.png"
            style={{
              width: 24, height: 24,
            }}
          />
          <span style={{
            fontSize: 14,
          }}
          >
            Powered by Thumbtack
          </span>
        </div>
        <div
          className="business-profile-info"
        >
          <img
            alt={business_name}
            src={image_url}
            style={{
              width: 120,
              height: 120,
              borderRadius: 8,
            }}
          />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
          >
            <span className="business-name">
              {business_name}
            </span>
            <Rating rating={rating} numReview={num_reviews} showValue />
            {/* Pills */}
            <div style={{
              display: 'flex',
              gap: 8,
            }}
            >
              {pills && pills.map((pill) => (
                <Badge key={pill} text={pill} />
              ))}
            </div>
            {num_hires && (
              <span className="total-hires">
                {num_hires}
                {' '}
                hires on Thumbtack
              </span>
            )}
            <span className="featured-review">{truncatedFeaturedReview}</span>
          </div>
        </div>
      </div>
      <div className="cost-and-action">
        {
          quote ? (
            <div>
              <div style={{
                whiteSpace: 'nowrap',
                fontWeight: 600,
                fontSize: 18,
              }}
              >
                {quote.starting_cost && `${formatCurrency(quote.starting_cost)}`}
              </div>
              <div style={{
                whiteSpace: 'nowrap',
                fontWeight: 300,
                fontSize: 14,
              }}
              >
                {quote.cost_unit}
              </div>
            </div>
          ) : <span>{' '}</span>
        }
        <button
          type="button"
          className="view-profile-btn"
          onClick={() => {
            handleRequestFlowFormURL(request_flow_iframe_url);
          }}
        >
          View Profile
        </button>
      </div>
    </div>
  );
}

export default ThumbtackItemCard;
