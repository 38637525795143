import React from 'react';
import { Link } from 'react-router-dom';
import Intent from '../intent';
import './get-started-button.scss';

interface Props {
  intent?: Intent;
  className?: string;
}

export default function GetStartedButton({
  className,
  intent = Intent.Primary,
}: Props): JSX.Element {
  return (
    <Link
      className={`get-started-button btn-primary ${intent} ${className ?? ''}`}
      to={{
        pathname: '/get-started',
        search: window.location.search,
      }}
    >
      Get Started
    </Link>
  );
}
