import React from 'react';
import BaseFrequentQuestions from '../../../components/frequent-questions/frequent-questions';
import QuestionItem from '../../../components/frequent-questions/question-item';

export default function FrequentQuestions(): JSX.Element {
  return (
    <BaseFrequentQuestions
      title={(
        <p>
          Here are some of the most common questions we hear from people who are considering solar
          panels. Our Solar Report was designed to give you the answers you need based on your
          unique situation.
        </p>
      )}
    >
      <QuestionItem title="How much are solar panels?">
        <p>
          The price for solar power ranges depending on how many panels are necessary for your roof,
          but luckily, the price of solar panels has decreased over the years. When considering
          solar panels for your home, you need to consider the price of the panels themselves and
          the installation price. Our report uses Google Project Sunroof and Google Earth to tell
          you the estimated costs for your roof, based on your roof shade, location, and more.
        </p>
      </QuestionItem>

      <QuestionItem title="Is my house good for solar?">
        <p>
          You may be wondering if your house qualifies for solar energy and if energy independence
          is an option for you. The amount of direct daily sunlight your roof gets, your usable roof
          space and the amount of electricity you use all impact whether or not solar makes sense
          for your situation. To see if your roof gets enough sun, fill out our quick 2-minute
          questionnaire and we’ll calculate this information for you.
        </p>
      </QuestionItem>

      <QuestionItem title="What incentives are available in my area?">
        <p>
          There are a variety of state and federal incentives available to homeowners who are
          looking to invest in solar panels. Our report will show you what incentives are available
          in your area as well as how these incentives could reduce your overall investment.
        </p>
      </QuestionItem>

      <QuestionItem title="Why should I care about energy independence?">
        <p>
          Moving towards energy independence is becoming a growing focus for homeowners across the
          country. Not only does producing and storing your own electricity reduce your dependence
          on the grid and utility companies, decrease the US’s reliance on fossil fuels and secure
          your power during outages, but it can also offer considerable savings on your electric
          bill. To see just how much you could save, get your free Solar Report today.
        </p>
      </QuestionItem>
    </BaseFrequentQuestions>
  );
}
