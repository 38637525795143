import React from 'react';
import Colorize, { Color } from '../../../components/color';
import { H1, H3 } from '../../../components/header-tag';
import {
  RoofTypeAsphaltShingleImage,
  RoofTypeCedarShakeImage,
  RoofTypeMetalImage,
  RoofTypeNaturalSlateImage,
  RoofTypeTarImage,
  RoofTypeTileImage,
} from '../images';
import './roof-types.scss';

interface CardProps {
  title: React.ReactNode;
  image: React.ReactNode;
  children: React.ReactNode;
}

function Card({ title, image, children }: CardProps): React.ReactElement {
  return (
    <div className="roofing-type-card">
      <H3>
        <Colorize color={Color.Secondary}>{title}</Colorize>
      </H3>

      <div className="image">
        {image}
      </div>

      <div className="content">
        {children}
      </div>
    </div>
  );
}

export default function RoofTypes(): React.ReactElement {
  return (
    <div id="types-of-roofing">
      <H1>
        TYPES OF
        <Colorize color={Color.Secondary}> ROOFING</Colorize>
      </H1>

      <div className="types-container">
        <Card title="METAL" image={<RoofTypeMetalImage />}>
          Metal roofs are popular in areas with heavy snowfall or areas with danger of wild fires as
          metal roofing are fire-resistant.
        </Card>

        <Card title="TAR" image={<RoofTypeTarImage />}>
          Tar roofing consists of multiple layers of a wide variety of waterproof materials. Tar
          roofs are more cost friendly.
        </Card>

        <Card title="TILE" image={<RoofTypeTileImage />}>
          Tile roofing is very durable and weather resistant. Tile roofs are also known to last a
          very long time. However, tile roofing can be heavy and expensive.
        </Card>
      </div>

      <div className="types-container">
        <Card title="CEDAR SHAKE" image={<RoofTypeCedarShakeImage />}>
          Cedar shake, made from natural wood, are very popular due to its look and durability, but
          it is also expensive and may require frequent maintenance.
        </Card>

        <Card title="NATURAL SLATE" image={<RoofTypeNaturalSlateImage />}>
          Slate is very durable and the most effective in wet climates and is known to last a long
          time. However, slate can be more expensive.
        </Card>

        <Card title="ASPHALT SHINGLE" image={<RoofTypeAsphaltShingleImage />}>
          Asphalt shingles are the most popular roofing type in the US. This is due to their
          effectiveness in a wide range of environmental conditions.
        </Card>
      </div>
    </div>
  );
}
