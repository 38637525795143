/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable operator-linebreak */

import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { Place } from './google-places-autocomplete.types';
import './google-places-autocomplete.scss';

declare const google: any;

interface GooglePlaceAutocompleteProps {
    onPlaceChanged: (place: Place) => void;
    placeholder?: string;
}

function GooglePlaceAutocomplete({
  onPlaceChanged, placeholder,
}: GooglePlaceAutocompleteProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const [, setAutoComplete] = useState<any>(null);

  const fillInAddress = useCallback((_autoComplete: any) => {
    const place = _autoComplete.getPlace();

    onPlaceChanged({
      location: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
      zipCode: place.address_components.find((e: any) => e.types.includes('postal_code'))?.long_name,
      street_number: place.address_components.find((e: any) => e.types.includes('street_number'))?.long_name,
      locality: place.address_components.find((e: any) => e.types.includes('locality'))?.long_name,
      country: place.address_components.find((e: any) => e.types.includes('country'))?.long_name,
      administrative_area: place.address_components.find((e: any) => e.types.includes('administrative_area_level_1'))?.long_name,
      route: place.address_components.find((e: any) => e.types.includes('route'))?.long_name,

    });
  }, [onPlaceChanged]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (typeof google === 'undefined') return;
    const _autoComplete = new google.maps.places.Autocomplete(inputRef.current, { types: ['geocode'], fields: ['address_components', 'geometry'], componentRestrictions: { country: 'us' } });
    _autoComplete.setFields(['address_component']);
    _autoComplete.addListener('place_changed', () => fillInAddress(_autoComplete));
    setAutoComplete(_autoComplete);
  }, []);

  return (
    <div className="google-places-autocomplete">
      {
        typeof google === 'undefined' ? (<div style={{ textAlign: 'center', color: 'red' }}>Please turn off the ad blocker so we can continue the report generation</div>) :
          (<input ref={inputRef} placeholder={placeholder} type="text" />)
      }
    </div>
  );
}

GooglePlaceAutocomplete.defaultProps = {
  placeholder: 'Street Address',
};

export default GooglePlaceAutocomplete;
