import React from 'react';
import {
  FlowerImage,
  MegaphoneImage,
  RoofAndToolboxImage,
  RoofInstallImage,
} from '../../../components/images';
import Base from '../../../components/reasons-section';

export default function Reasons(): React.ReactElement {
  return (
    <Base
      title="Why should you replace or repair your roof?"
      image={<RoofInstallImage />}
    >
      <Base.Item
        title="AVOID FUTURE MAINTENANCE AND REPAIRS"
        image={<RoofAndToolboxImage />}
      >
        Your location, weather, and environment can impact the performance of your roof. If the roof
        has damage, it is time to replace it to improve the durability of your home and an extra
        layer of protection.
      </Base.Item>

      <Base.Item
        title="SOME TYPES OF ROOFS ARE GOOD FOR THE ENVIRONMENT"
        image={<FlowerImage />}
      >
        According to the EPA, cool roofs can help homeowners conserve energy and also reduce air
        pollution and greenhouse gas emissions by mitigating the heat radiated into the atmosphere.
      </Base.Item>

      <Base.Item
        title="BOOST THE VALUE OF YOUR HOME"
        image={<MegaphoneImage />}
      >
        In a 2019 survey from the National Association of REALTORS and the National Association of
        the Remodeling Industry, 33% of realtors found that a new roof helped them close a sale on a
        home.
      </Base.Item>
    </Base>
  );
}
