import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './thumbtack-items-form.scss';

import ThumbtackItemCard, { ThumbtackItemCardProps } from '../thumbtack-item-card/thumbtack-item-card';
import { getPartnersThumbtack } from '../../api/thumbtack-api';

// More type details check: https://api.thumbtack.com/docs/demand_api.html#pros

type ThumbtackItemsFormProps = {
  zipCode: string;
  search?: string;
}

function ThumbtackItemsForm(props: ThumbtackItemsFormProps): JSX.Element {
  const { zipCode, search = 'roofing' } = props;
  const [requestFlowFormURL, setRequestFlowFormURL] = useState<string>();

  const [items, setItems] = useState<ThumbtackItemCardProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zipCodeState, setZipCodeState] = useState<string>(zipCode);

  const getThumbtackData = async () => {
    setIsLoading(true);
    const response = await getPartnersThumbtack(search, zipCodeState);
    if (response?.result?.results) {
      setItems(response.result?.results);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      getThumbtackData();
    }, 1000);

    return () => clearTimeout(delayTimer);
  }, [zipCodeState]);

  return (
    <div>
      {
        !requestFlowFormURL
          ? (
            <div
              className="thumbtack-items-form"
            >
              <div>
                <span style={{
                  marginBottom: 12,
                }}
                >
                  {'Zip code: '}
                </span>
                <input
                  className="search-input"
                  placeholder="Enter zip code..."
                  onChange={(e) => {
                    setZipCodeState(e.target.value);
                  }}
                  value={zipCodeState}
                />
              </div>
              {
        isLoading
          ? (
            <Skeleton
              count={3}
              style={{
                width: '100%',
                height: '100px',
                marginBottom: 12,
              }}
            />
          )
          : (
        // Empty?
            <>
              <>
                {
              items.length === 0 && <span>No result found.</span>
            }
              </>
              <>
                {items.length > 0 && items.map((item, index) => (
                  <React.Fragment key={item.service_id}>
                    <ThumbtackItemCard
                      {...item}
                      handleRequestFlowFormURL={(url) => {
                        setRequestFlowFormURL(url);
                      }}
                    />
                    {index < items.length - 1 && <div className="divider" />}
                  </React.Fragment>
                ))}
              </>
            </>
          )
      }
            </div>
          )
          : (
            <iframe
              className="request-flow-form"
              title="request-flow-form"
              src={requestFlowFormURL}
              sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox"
              width="100%"
              height="100%"
            />
          )
      }
    </div>
  );
}

export default ThumbtackItemsForm;
