export const showPrice = (price: string) => {
  if (price === '100') {
    return '$0-100';
  }
  if (price === '200') {
    return '$101-200';
  }
  if (price === '300') {
    return '$201-300';
  }
  return '$301+';
};

export const namesOnNext = (
  firstName: string|undefined,
  lastName: string|undefined,
  setError: any,
  callback: any,
) => {
  if (firstName && firstName.length < 2) {
    setError('First Name must be at least 2 characters!');
  } else if (lastName && lastName.length < 2) {
    setError('Last Name must be at least 2 characters!');
  } else {
    setError('');
    callback();
  }
};
