import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { FiArrowUpLeft } from 'react-icons/fi';
import { ImSpinner2 } from 'react-icons/im';
import 'react-circular-progressbar/dist/styles.css';
import OtterIcon from '../../images/otter characters - 02 (4).png';
import './form-cardV2.scss';

interface FormCardV2Props {
    step: number;
    allSteps: number;
    index: number;
    onNext: () => void;
    onBack: () => void;
    canProgress: boolean;
    lastCard?: boolean;
    title?: string | undefined;
    isSplashScreen?: boolean | false;
    loading?: boolean;
    progressBar?: boolean;
    subtitle?: string | undefined;
    nextButton?: boolean;
    capitalize?: boolean;
    nextButtonContent?: React.ReactNode;
  }

function FormCardV2(props: React.PropsWithChildren<FormCardV2Props>) {
  const {
    title, children, step, allSteps, index, onNext, onBack, canProgress, lastCard, loading,
    progressBar, subtitle, nextButton, capitalize = true, nextButtonContent, isSplashScreen,
  } = props;

  let formattedTitle = title;

  function Capitalize(str: any) {
    return str.length ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : str;
  }

  if (capitalize) {
    formattedTitle = Capitalize(title);
  }

  return (
    <div key={index} className={`form-cardV2 ${index === step ? 'active' : 'hidden'}`}>
      <div className="form-header">
        <div className="form-title">
          <div className="progress">
            {progressBar && (
              <CircularProgressbar
                styles={buildStyles({
                  pathColor: 'rgba(0,106,243,1)',
                  textColor: 'rgba(0,106,243,1)',
                  trailColor: 'rgba(0,106,243,0.50)',
                  backgroundColor: '#3e98c7',
                })}
                value={((step) / allSteps) * 100}
              />
            )}
          </div>
          <div className="cap-title">
            {formattedTitle}
          </div>
          <div className="sub-title">
            {subtitle}
          </div>
        </div>
      </div>
      <div className="form-body">
        {children}
      </div>
      {lastCard ? (
        <div>
          {loading ? <div className="spinner"><ImSpinner2 /></div> : (
            <div className="button">
              <button type="button" onClick={onNext} className="btn-primary">
                {nextButtonContent ?? 'Get Your Solar Report'}
              </button>
            </div>
          )}
        </div>

      ) : (
        <div className={`buttons  ${!canProgress ? 'disabled' : ''}`}>
          {nextButton && (
            loading ? <div className="spinner"><ImSpinner2 /></div> : (
              <div className="button">
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => {
                    if (canProgress) onNext();
                  }}
                >
                  {nextButtonContent ?? 'Next'}
                </button>
              </div>
            )

          )}
          {index !== 0 && (
          <button type="button" className="btn-secondary" onClick={onBack}>
            <FiArrowUpLeft />
            Back
          </button>
          )}
        </div>
      )}
      {
        !isSplashScreen && (
        <div className="otter-img-3">
          <img className="otter-icon-3" src={OtterIcon} alt="house with solar panel" />
        </div>
        )
      }

    </div>
  );
}

FormCardV2.defaultProps = {
  title: undefined,
  lastCard: false,
  loading: false,
  progressBar: true,
  subtitle: undefined,
  nextButton: true,
};

export default FormCardV2;
