import axios from 'axios';
import { GeneralResponseModel } from '../model/ResponseModel';

const API_HOST = process.env.NODE_ENV === 'development' ? `http://${window.location.hostname}:3333/api` : `https://${window.location.hostname}/api`;

export const validateZipCodeThumbtack = async (zipCode: string):
Promise<GeneralResponseModel<boolean>> => {
  const body = {
    zip_code: zipCode,
  };

  try {
    const response = await axios.post<GeneralResponseModel<boolean>>(
      `${API_HOST}/validate/zip_code`,
      body,
      {
        headers: {
          'x-api-key': 'fe91a833-521c-4da6-8134-46598d068ce8',
          Accept: 'application/json',
        },
      },
    );
    if (response.status === 200) {
      return response.data;
    }
    console.error(`Unexpected status code: ${response.status}`);
    throw response.status;
  } catch (error) {
    console.error('Error fetching eligible zip codes:', error);
    throw error;
  }
};
