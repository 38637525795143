import React from 'react';
import { useNavigate } from 'react-router-dom';
import OtterImage from '../../images/otter charectors - 02-05.png';

import './personalized-report.scss';

interface TextProps {
  personalizedSolarReportDescription?: string;
}

export default function PersonalizedReport({ personalizedSolarReportDescription = '' }: TextProps) {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();

  return (
    <div className="personalized-report">
      <div className="personalized-report-wrapper">
        <div className="personalized-report-container">
          <h2>
            SOLAR REPORT
          </h2>
          <div className="seperator" />
          <h3>PERSONALIZED SOLAR REPORT</h3>
          <h4>
            {personalizedSolarReportDescription || 'Take our 4 minute questionnaire and receive a free, fully customized, solar report'}
          </h4>
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              navigate({ pathname: '/get-started', search: params });
            }}
          >
            Get Started
          </button>
        </div>
      </div>
      <div className="img-container">
        <img className="otter-icon" src={OtterImage} alt="otter solar house" />
      </div>
    </div>
  );
}
